import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Badge, Button } from 'reactstrap'
import { Table, Empty } from 'antd'

import EditUser from './partials/EditUser'
import {deleteUser} from './services'
import { handleError } from '../../../helpers'
import TableActions from '../../../components/TableActions'
import UserPermissions from './partials/UserPermissions'

export default function UsersList({ users, reloadUsers }) {
   const currentUser = useSelector(state => state.auth.user_id)
   const [editUser, setEditUser] = useState(null)
   const [editPermissions, setEditPermissions] = useState(null)

   const columns = [
      {
         title: 'Nombre',
         width: 190,
         dataIndex: 'name',
         sorter: (a, b) => a.name.length - b.name.length,
      },
      {
         title: 'Correo Electrónico',
         width: 250,
         dataIndex: 'email',
         sorter: (a, b) => a.name.length - b.name.length,
      },
      {
         title: 'Documento',
         width: 115,
         dataIndex: 'document',
         sorter: (a, b) => a.name.length - b.name.length,
         render: document => document ? document : <small><em>Sin documento</em></small>
      },
      {
         title: 'Teléfono',
         width: 115,
         dataIndex: 'mobile',
         sorter: (a, b) => a.name.length - b.name.length,
         render: mobile => mobile ? mobile : <small><em>Sin teléfono</em></small>
      },
      {
         title: 'Rol',
         width: 115,
         dataIndex: 'roles',
         render: text => <Badge>{text[0].name}</Badge>
      },
      {
         title: 'Permisos',
         width: 80,
         render: (text, record) => {
            let role = record.roles ? record.roles[0].name : ''
            if( role !== 'Super Admin' ) {
               return <Button color="primary" size="sm" outline onClick={()=>setEditPermissions(record)}>Permisos</Button>
            } else if(currentUser !== record.id ) {
               return <em className="small">No puede editar su usuario</em>
            } else { 
               return <em className="small">Todos los permisos</em> 
            }
         }
      },
      {
         title: 'Acciones',
         width: 180,
         render: (txt, record) => (
            <React.Fragment>
               {currentUser !== record.id ? (
                  <TableActions
                     handleEdit={()=>setEditUser(record)}
                     handleDelete={() => handleDelete(record.id)}
                  />
               ) : <em className="small">No puede editar su usuario</em>}
            </React.Fragment>
         )
      }
   ]

   const handleDelete = id => {
      deleteUser(id)
         .then(()=>reloadUsers())
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>Usuarios</CardTitle>
               <p className="text-muted mb-4">Listado completo de usuarios</p>
               <div className="table-responsive">
                  <Table 
                     columns={columns}
                     dataSource={users}
                     loading={!users && true}
                     showSorterTooltip={false}
                     rowKey={record => record.id}
                     scroll={{ x: 'fixed', scrollToFirstRowOnChange: true }}
                     locale={{ emptyText: <Empty description="No se encontraron usuarios" className="mt-3" /> }}
                  />
               </div>
            </CardBody>
         </Card>
         <EditUser user={editUser} setShow={setEditUser} reload={()=>reloadUsers()} />
         <UserPermissions user={editPermissions} setShow={setEditPermissions} />
      </React.Fragment>
   )
}