import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import { showNewModal } from '../actions'
import { createActivity } from '../services'
import { handleError } from '../../../helpers'
import SearchInput from '../../../components/SearchEntity'

export default function NewActivity({ reload }) {
   const show = useSelector(state => state.activities_props.showNewModal)
   const [loading, setLoading] = useState(false)
   const [resource, setResource] = useState('contacts')
   const [resourceId, setResourceId] = useState(null)
   const user_id = useSelector(state => state.auth.user_id)
   const { handleSubmit, register, errors, watch} = useForm()
   const dispatch = useDispatch()

   const onSubmit = values => {
      setLoading(true)
      values.user_id = user_id
      createActivity(values)
         .then(() => {
            dispatch(showNewModal(false))
            message.success('Actividad creada exitosamente.')
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Modal isOpen={show} toggle={()=>dispatch(showNewModal(false))}>
         <ModalHeader toggle={() => dispatch(showNewModal(false))}>Crear Actividad Nueva</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label className="block">Tipo de Actividad</Form.Label>
                  <Form.Check inline label="Llamada" type="radio" name="type" value="call" ref={register({ required: true })} defaultChecked />
                  <Form.Check inline label="Cita" type="radio" name="type" value="meeting" ref={register({ required: true })} />
                  <Form.Check inline label="Tarea" type="radio" name="type" value="task" ref={register({ required: true })} />
                  <Form.Check inline label="Recordatorio" type="radio" name="type" value="reminder" ref={register({ required: true })} />
               </Form.Group>
               <div className="mt-2">
                  <Form.Label>Asociar actividad con:</Form.Label>
               </div>
               <Row>
                  <Form.Group as={Col} xs="4">
                     <Form.Control
                        name="actionable_type"
                        as="select"
                        ref={register({required: true})}
                        style={{ borderRadius: '0px' }}
                        onChange={() => setResource(watch('actionable_type'))}
                     >
                        <option value="contacts">Contacto</option>
                        <option value="deals">Negocio</option>
                        <option value="companies">Empresa</option>
                     </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs="8" style={{ paddingLeft: '0px' }}>
                     <SearchInput setResourceId={setResourceId} resourceType={resource} style={{ width: '100%' }} />
                     <Form.Control 
                        type="hidden"
                        name="actionable_id"
                        ref={register({ required: true })}
                        value={resourceId ? resourceId : ''}
                     />
                     { errors.actionable_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
               </Row>
               <Form.Group>
                  <Form.Label>Referencia</Form.Label>
                  <Form.Control
                     name="reference"
                     ref={register({ required: true })}
                     placeholder="Escriba un titulo o referencia aquí"
                  />
                  { errors.reference && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Row>
                  <Col>
                     <Form.Group>
                        <Form.Label>Vence el</Form.Label>
                        <Form.Control
                           name="due_at"
                           ref={register({ required: true })}
                           type="datetime-local"
                           defaultValue={moment().format('YYYY-MM-DDTHH:mm')}
                        />
                        { errors.due_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group>
                        <Form.Label>Duración</Form.Label>
                        <InputGroup>
                           <Form.Control 
                              name="duration_in_minutes"
                              ref={register({required: true})}
                              type="number"
                              defaultValue={10}
                           />
                           <InputGroup.Append>
                              <InputGroup.Text>Minutos</InputGroup.Text>
                           </InputGroup.Append>
                        </InputGroup>
                        { errors.duration_in_minutes && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
               </Row>
               <Button color="primary" disabled={loading}>Crear Actividad Nueva</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}

/*
description - null
state - default active
*/