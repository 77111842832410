import React from 'react'
import {useDispatch} from 'react-redux'
import { Spinner, Table, Badge } from 'reactstrap'
import { Timeline, Empty, Divider } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import EditActivity from '../../containers/Activities/partials/EditActivity'
import { editResource } from '../../containers/Activities/actions'

export default function ActivitiesTimeline({ activities, reload }) {
   const dispatch = useDispatch()

   return (
      <React.Fragment>         
         {activities ? (
            <React.Fragment>
               <Timeline>
                  { activities.length > 0 ? (
                     <React.Fragment>
                        { activities.map(activity => (
                           <Timeline.Item key={activity.id} color="grey" className="activities-timeline">
                              <Table className="table table-centered table-nowrap mb-0">
                                 <tbody>
                                    <tr>
                                       <td style={{ width: "30px" }} className="px-0">
                                          <div className="avatar-xs">
                                             <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-16">
                                                { activity.type === 'call' && <i className="fa fa-phone"></i>}
                                                { activity.type === 'meeting' && <i className="fa fa-calendar-day"></i>}
                                                { activity.type === 'task' && <i className="fa fa-check-square"></i>}
                                                { activity.type === 'reminder' && <i className="fa fa-bell"></i>}
                                             </span>
                                          </div>
                                       </td>
                                       <td>
                                          <div className="mb-0">                                       
                                             { activity.state === 'draft' && <Badge color="soft-dark">Borrador</Badge> }
                                             { activity.state === 'active' && <Badge color="soft-info">Activa</Badge> }
                                             { activity.state === 'completed' && <Badge color="soft-success">Completada</Badge> }
                                             { activity.state === 'canceled' && <Badge color="soft-danger">Cancelada</Badge> }
                                             <span className="ml-2 text-link" onClick={() => dispatch(editResource(activity))}>{activity.reference}</span>
                                          </div>
                                          <div className="text-muted">
                                             Vence el <span className="text-dark">{moment(activity.due_at).format('ddd DD-MMMM-YYYY hh:mm A')}</span>{" "}
                                             <Divider type="vertical" />
                                             {activity.duration_in_minutes} min
                                             <Divider type="vertical" />
                                             Asignado a: <span className="text-dark">{activity.owner.name}</span>
                                          </div>                                    
                                       </td>
                                    </tr>
                                 </tbody>
                              </Table>
                           </Timeline.Item>
                        ))}
                     </React.Fragment>
                  ) : <Empty description="No tiene actividades" image={Empty.PRESENTED_IMAGE_SIMPLE} className="bg-light-white my-0 py-4" />}
               </Timeline>
               <EditActivity reload={reload} />
            </React.Fragment>
         ) : <Spinner size="sm" /> }
      </React.Fragment>
   )
}