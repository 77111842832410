import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { Divider } from 'antd'

import ListTopBar from './partials/ListTopBar'

export default function CallCampaigns() {
	return (
		<React.Fragment>
			<ListTopBar 
				title="Campañas de Llamadas" 
				ctaText="Crear Campaña de Llamadas"
				ctaLink="/campaigns/calls/new"
			/>
			<Row>
				<Col lg="12">
					<div className=" pb-5">
						<div className="table-responsive pb-5">
							<Table className="project-list-table table-nowrap table-centered table-borderless pb-5">
								<thead>
									<tr>
										<th scope="col">Nombre de la Campaña</th>
										<th scope="col">Asignadas</th>
										<th scope="col">Completadas</th>
										<th scope="col">Canceladas</th>
										<th scope="col">Estado</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="text-truncate font-size-14">
												<Link to={`/campaigns/calls/:id`} className="text-primary">WH-2020OCT21EMAIL01</Link>
											</h5>
											<p className="text-muted mb-0">Programado: 13 Noviembre 2020 11:00</p>
										</td>
										<td>
											<h6 className="mb-1">0 asignadas</h6>
											<span className="text-muted">120,303 destinatarios</span>
										</td>
										<td>
											<h6 className="mb-1">0 completadas</h6>
											<span className="text-muted">0% Tasa realización</span>
										</td>
										<td>
											<h6 className="mb-1">0 canceladas</h6>
											<span className="text-muted">0% Tasa cancelación</span>
										</td>
										<td><span className="badge badge-info">Programada</span></td>
										<td>											
											<UncontrolledDropdown>
												<Link to={`/campaigns/calls/:id`}>
													<Button color="dark" size="sm" className="mr-3" outline>Regístro</Button>
												</Link>
												<DropdownToggle href="#" className="card-drop" tag="i">
													<i className="mdi mdi-dots-horizontal font-size-18"></i>
												</DropdownToggle>
												<DropdownMenu right>
														<DropdownItem href="#">Enviar Prueba</DropdownItem>
														<Divider className="my-2" />
														<DropdownItem href="#">Editar Campaña</DropdownItem>
														<DropdownItem href="#">Eliminar</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
}