import React, {useState} from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Select } from 'antd'

import { createSegmentFilter } from '../services'
import { handleError } from '../../../helpers'

const { Option } = Select;

export default function NewConditional({ segmentId, sysFilters, reload }) {
	const [open, setOpen] = useState(false)
	const [key, setKey] = useState("")
	const [filter, setFilter] = useState("")
	const [value, setValue] = useState("")

	const onSubmit = () => {
		createSegmentFilter({segment_id: segmentId, filter, value})
			.then(() => {
				message.success('Condicional agregada existosamente')
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	const onClose = () => {
		setKey("")
		setFilter("")
		setValue("")
		setOpen(false)
	}

	var keys = [], uniqueKeys = [], l = sysFilters.length, i;
	for( i=0; i<l; i++) {
		if( keys[sysFilters[i].key]) continue;
		keys[sysFilters[i].key] = true;
		uniqueKeys.push(sysFilters[i].key);
	}

	return (
		<React.Fragment>
			<Button color="info" outline onClick={()=>setOpen(true)}>
				Agregar otra condicional
			</Button>
			<Modal isOpen={open} toggle={onClose} >
				<ModalHeader toggle={onClose}>Agregar Condicional</ModalHeader>
				<ModalBody>
					<Form.Group>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder="::Elige un filtro::"
							optionFilterProp="children"
							onChange={value => {
								setKey(value)
								setFilter("")
								setValue("")
							}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{ uniqueKeys.map((ukey,i) => {
								let sysFilter = sysFilters.filter(sf => sf.key === ukey)[0]
								return <Option key={i} value={ukey}>{sysFilter.label}</Option>
							})}	
						</Select>
					</Form.Group>
					<Form.Group>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder="::Elige un operador::"
							optionFilterProp="children"
							onChange={value => {
								setFilter(value)
								setValue("")
							}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							disabled={!key ? true : false}
						>
							{ sysFilters.map(sysFilter => {
								if(sysFilter.key === key)
									return <Option key={sysFilter.id} value={sysFilter.name}>{sysFilter.operator}</Option>
								else return null
							})}
						</Select>
						{ !key && <Form.Text className="text-warning">Elige un filtro primero</Form.Text> }
					</Form.Group>					
					<Form.Group>
						{ filter && sysFilters.filter(sysFilter => sysFilter.name === filter)[0].type !== 'boolean' ? (
							<Form.Control 
								value={value}
								type={sysFilters.filter(sysFilter => sysFilter.name === filter)[0].type}
								onChange={e => setValue(e.target.value)}
								placeholder="Elige un valor"
							/>
						) : (
							<React.Fragment>
								{ filter && (
									<React.Fragment>
										<Form.Check type="radio" name="value" value="si" defaultChecked={value} onChange={e => setValue(e.target.value)} label="Si" />
										<Form.Check type="radio" name="value" value="no" defaultChecked={value} onChange={e => setValue(e.target.value)} label="No" />
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{ key && !filter && <Form.Text className="text-warning">Elige un operador</Form.Text> }
						{ filter && <Form.Text className="text-info">{ sysFilters.filter(sysFilter => sysFilter.name === filter)[0].hint }</Form.Text> }
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<Button color="warning" outline onClick={onClose}>Cancelar</Button>
					<Button color="primary" disabled={!value ? true : false} onClick={onSubmit}>Agregar Condicional</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}