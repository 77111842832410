import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'reactstrap'
import _ from 'lodash'

import StatCard from '../../components/StatCard'
import ProductsTable from './partials/ProductsTable'
import ProductsFilters from './partials/ProductsFilters'
import NewProduct from './partials/NewProduct'
import ListTopBar from './partials/ListTopBar'

import { getProducts } from './services'
import { getCategories } from '../Settings/Categories/services'
import { updatePagination } from './actions'
import { handleError, parseQueryParams } from '../../helpers'
import EditProduct from './partials/EditProduct'

export default function ProductsList() {
   const [products, setProducts] = useState(null)
   const [categories, setCategories] = useState(null)
   const stateProps = useSelector(state => state.products_props)
   const dispatch = useDispatch()      

   const handleLoadData = rsp => {
      let rspPagination = { current_page: rsp.meta.current_page, per_page: parseInt(rsp.meta.per_page), total: rsp.meta.total }

      !_.isEqual(stateProps.pagination, rspPagination) && 
         dispatch(updatePagination(rspPagination))

      setProducts(rsp.data)

      !categories && getCategories({})
            .then(response => setCategories(response.data.data))
            .catch(error => handleError(error))
   }

   !products && getProducts( parseQueryParams(stateProps) )
      .then(response => handleLoadData(response.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <ListTopBar 
            currentView={stateProps.listViewType}
            reload={() => setProducts(null)}
         />
         <Row>
            <Col lg="3" md="4" className="hide-md">
               <ProductsFilters 
                  categories={categories}
                  reload={() => setProducts(null)}
               />
               <StatCard
                  title="Productos encontrados"
                  text={stateProps.pagination.total}
                  iconClass="bx bx-buildings"
               />
            </Col>
            <Col lg="9" md="8">
               { stateProps.listViewType === 'table' && (
                  <ProductsTable
                     products={products}
                     stateProps={stateProps}
                     reload={() => setProducts(null)}
                     categories={categories}
                  />
               )}
            </Col>
         </Row>
         <NewProduct categories={categories} reload={() => setProducts(null)} />
         <EditProduct categories={categories} reload={() => setProducts(null)} />
      </React.Fragment>
   )
}