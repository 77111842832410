import React from 'react'
import { Button } from 'reactstrap'
import { Popconfirm, Divider, Tooltip } from 'antd'

export default function TableActions({ handleEdit, handleDelete, dark, handleActivate, handleDisable }) {
   return (
      <React.Fragment>
         <Tooltip title="Actualizar">
            <Button outline size="sm"
               color={!dark ? 'info' : ''} 
               onClick={handleEdit}
            >
               <i className="fa fa-edit"></i>
            </Button>
         </Tooltip>

         { handleActivate && (
            <React.Fragment>
               <Divider type="vertical" />
               <Popconfirm
                  title="¿Esta seguro de activar el registro?"
                  onConfirm={handleActivate}
                  okText="Activar"
                  cancelText="Cancelar"
               >
                  <Tooltip title="Activar">
                     <Button outline size="sm"
                        color={!dark ? 'info' : ''}
                     >
                        <i className="fa fa-toggle-on"></i>
                     </Button>
                  </Tooltip>
               </Popconfirm>
            </React.Fragment>
         )}

         { handleDisable && (
            <React.Fragment>
               <Divider type="vertical" />
               <Popconfirm
                  title="¿Esta seguro de desactivar el registro?"
                  onConfirm={handleDisable}
                  okText="Desactivar"
                  cancelText="Cancelar"
               >
                  <Tooltip title="Desactivar">
                     <Button outline size="sm"
                        color={!dark ? 'warning' : ''}
                     >
                        <i className="fa fa-toggle-off"></i>
                     </Button>
                  </Tooltip>
               </Popconfirm>
            </React.Fragment>
         )}

         { handleDelete && (
            <React.Fragment>
               <Divider type="vertical" />
               <Popconfirm
                  title="¿Esta seguro de eliminar?"
                  onConfirm={handleDelete}
                  okText="Eliminar"
                  cancelText="Cancelar"
               >
                  <Tooltip title="Eliminar">
                     <Button outline size="sm"
                        color={!dark ? 'danger' : ''}
                     >
                        <i className="fa fa-trash-alt"></i>
                     </Button>
                  </Tooltip>
               </Popconfirm>
            </React.Fragment>
         )}

      </React.Fragment>
   )
}