import { 
   UPDATE_PAGINATION, 
   SET_QUERY,
   SHOW_NEW_MODAL,
   SET_LIST_VIEW
} from "./actions"

const INITIAL_STATE = {
   pagination: {
      current_page: 1,
      per_page: 150,
   },
   query: {
      sort: '-created_at',
      include: 'contact,stage,products,productsCount',
      filters: {
         'state': 'active'
      },
      append: 'total_items,total_value'
   },
   listViewType: 'funnel',
   showNewModal: false
}

const companiesReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case UPDATE_PAGINATION:
         return { ...state, pagination: { 
            ...state.pagination, 
            ...action.payload 
         }}

      case SET_QUERY:
         return { ...state, query: {
            ...state.query,
            ...action.payload
         }}

      case SHOW_NEW_MODAL:
         return { ...state, showNewModal: action.payload }

      case SET_LIST_VIEW:
         let pagination = action.payload === 'table' ? { current_page: 1, per_page: 15 } : { current_page: 1, per_page: 150 }
         return { ...state, listViewType: action.payload, pagination }
         
      default:
         return { ...state }
   }
}

export default companiesReducer