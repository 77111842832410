import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { DatePicker, Select } from 'antd'
import moment from 'moment'

import locale from 'antd/es/date-picker/locale/es_ES'
import 'moment/locale/es'

const { RangePicker } = DatePicker
const { Option } = Select;

export default function ReportFilters({ 
	users,
	stores,
	fromDate,
	setFromDate,
	toDate,
	setToDate,
	selectedUsers,
	setSelectedUsers,
	selectedStores,
	setSelectedStores,
	handleSubmit 
}) {		

	function onChange(dates) {
		setFromDate(dates[0])
		setToDate(dates[1])
	}

	return (
		<Card>
			<CardBody>
				<h6>Rango de Fechas</h6>
				<RangePicker
					locale={locale}
					ranges={{
						'Hoy': [moment(), moment()],
						'Este mes': [moment().startOf('month'), moment().endOf('month')],
						'Este año': [moment().startOf('year'), moment().endOf('year')],
					}}
					value={[fromDate, toDate]}
					format={'DD/MMM/YYYY'}
					onChange={onChange}
				/>
				{ users ? (
					<React.Fragment>
						<h6 className="mt-4">Asesores</h6>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos los asesores"
							value={selectedUsers}
							onChange={value => setSelectedUsers(value)}
						>
							{ users.map(user => <Option key={user.id} value={user.id}>{user.name}</Option>) }
						</Select>
					</React.Fragment>
				) : ''}
				{ stores ? (
					<React.Fragment>
						<h6 className="mt-4">Tiendas</h6>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos las tiendas"
							value={selectedStores}
							onChange={value => setSelectedStores(value)}
						>
							{ stores.map(store => <Option key={store.id} value={store.id}>{store.name}</Option>) }
						</Select>
					</React.Fragment>
				) : ''}
				<div className="mt-4">
					<Button color="primary" onClick={handleSubmit}>
						General Reporte
					</Button>
				</div>
			</CardBody>
		</Card>
	)
}