import { 
   UPDATE_PAGINATION, 
   SET_QUERY,
   SHOW_NEW_MODAL
} from "./actions"

const INITIAL_STATE = {
   pagination: {
      current_page: 1,
      per_page: 15,
   },
   query: {
      sort: '-created_at',
      include: 'user,dealsCount,salesCount',
      filters: {},
      append: 'total_sales_value'
   },
   listViewType: 'table',
   showNewModal: false
}

const contactsReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case UPDATE_PAGINATION:
         return { ...state, pagination: { 
            ...state.pagination, 
            ...action.payload 
         }}

      case SET_QUERY:
         return { ...state, query: {
            ...state.query,
            ...action.payload
         }}

      case SHOW_NEW_MODAL:
         return { ...state, showNewModal: action.payload }
         
      default:
         return { ...state }
   }
}

export default contactsReducer