import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

import { updatePipeline } from '../services'
import { Form } from 'react-bootstrap'
import { handleError } from '../../../../helpers'

export default function EditPipeline({ pipeline, setShow, reload }) {
   const { handleSubmit, register, errors } = useForm()
   const [loading, setLoading] = useState(false)

   const onSubmit = values => {
      setLoading(true)
      updatePipeline(pipeline.id, values)
         .then(() => {
            reload()
            setShow(null)
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   if(!pipeline) return null

   return (
      <Modal isOpen={pipeline && true} toggle={() => setShow(null)}>
         <ModalHeader toggle={() => setShow(null)}>Actualizar Embudo de Venta</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     defaultValue={pipeline.name}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Button color="primary" disabled={loading}>Actualizar Embudo</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}