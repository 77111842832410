import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
// import { Divider } from 'antd'

export default function ListTopBar() {
   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">Listado de Segmentos</h4>
            <div className="page-title-right">               
               {/* <Link to="/segments/market">
                  <Button className="hide-sm topbar-btn" color="dark" outline>Segmentos Sugeridos</Button>
               </Link>
               <Divider type="vertical" /> */}
					<Link to="/segments/wizard">
						<Button color="primary" className="topbar-btn">
							Crear Segmento Personalizado
						</Button>
					</Link>
            </div>
         </div>
      </React.Fragment>
   )
}