import React, {useState} from 'react'
import { Spinner, Modal, ModalHeader, Row, Col, ModalBody } from 'reactstrap'
import moment from 'moment'

import { getProduct } from '../services'
import { handleError } from '../../../helpers'
import StatCard from '../../../components/StatCard'

export default function ProductStats({ show, close, productId }) {
	const [stats, setStats] = useState(null)

	!stats && productId && getProduct(productId, { 
		'fields[products]': 'id,name',
		append: 'total_active_deals,total_lost_deals,total_sales_value,total_sales_quantity,last_sale_date'
	}).then(res => setStats(res.data.data))
		.catch(error => handleError(error))

	const handleClose = () => {
		setStats(null)
		close()
	}

	return (
		<React.Fragment>
			<Modal toggle={handleClose} isOpen={show} size="lg">
				<ModalHeader toggle={handleClose}>Estadísticas { stats ? stats.name : 'del Producto'}</ModalHeader>
				<ModalBody>
					{stats ? (
						<React.Fragment>
							<Row>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-cash-register" 
										text={stats.total_sales_quantity} 
										title="Total Items Vendidos" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-money-bill-alt" 
										text={`$ ${stats.total_sales_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} 
										title="Total en Ventas" 
									/>
								</Col>
								<Col sm="12">
									<StatCard 
										iconClass="fa fa-calendar-alt" 
										text={stats.last_sale_date ? moment(stats.last_sale_date).format('Y-MM-DD HH:mm') : 'Sin Ventas'} 
										title="Fecha de Última Venta" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-toggle-on" 
										text={stats.total_active_deals} 
										title="Items en Negocios Activos"
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-toggle-off" 
										text={stats.total_lost_deals} 
										title="Items en Negocios Perdidos"
									/>
								</Col>
							</Row>
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}