import React, {useState} from 'react'
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumb';
import NewCategory from './NewCategory';
import CategoriesList from './CategoriesList';

import { getCategories } from './services'
import { handleError } from '../../../helpers';

export default function Categories() {
   const [categories, setCategories] = useState(null)

   !categories && getCategories({ include: 'productsCount' })
      .then(response => setCategories(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <Breadcrumbs title="Ajustes" breadcrumbItem="Categorías de Productos" />
         <Row>
            <Col md="4">
               <NewCategory reloadCategories={()=>setCategories(null)} />
            </Col>
            <Col>
               <CategoriesList categories={categories} reloadCategories={()=>setCategories(null)} />
            </Col>
         </Row>
      </React.Fragment>
   )
}