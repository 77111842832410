import React, {useState} from 'react'
import styled from 'styled-components'
import { CardTitle, ButtonGroup, Button } from 'reactstrap'
import { Switch } from 'antd'

import ActivitiesWizard from './partials/ActivitiesWizard'
import ActivitiesTimeline from './partials/ActivitiesTimeline'

import { getActivities } from '../containers/Activities/services'
import { handleError } from '../helpers'

export default function ResourceActivities({ resourceId, resourceType }) {
   const [filters, setFilters] = useState({ pendding: true, calls: true, meetings: true, tasks: true, reminders: true })
   const [activities, setActivities] = useState(null)

   const handleChange = newFilters => {
      setFilters({ ...filters, ...newFilters })
      setActivities(null)
   }

   const getParams = () => {
      let query = {
         'per_page': 100,
         'filter[actionable_type]': resourceType,
         'filter[actionable_id]': resourceId,
         'filter[type]': `${filters.calls ? 'call,' : ''}${filters.meetings ? 'meeting,' : ''}${filters.tasks ? 'task,' : ''}${filters.reminders ? 'reminder,' : ''}`,
         'filter[state]': filters.pendding ? 'active' : '',
      }
      if(!query['filter[type]']) query['filter[type]'] = 'none'

      return query
   }

   !activities && getActivities(getParams())
      .then(response => setActivities(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <ActivitiesWizard resourceId={resourceId} resourceType={resourceType} reload={()=>setActivities(null)} />
         <Container>
            <div className="float-right mb-4">
               <Switch size="small" checked={filters.pendding} onChange={() => handleChange({ pendding: !filters.pendding })} className="hide-sm" />{" "}
               <span className="hide-sm">Pendientes</span>
               <ButtonGroup size="sm" className="ml-3">
                  <Button color={filters.calls ? 'dark' : 'secondary'} outline={!filters.calls} onClick={() => handleChange({ calls: !filters.calls })}>Llamadas</Button>
                  <Button color={filters.meetings ? 'dark' : 'secondary'} outline={!filters.meetings} onClick={() => handleChange({ meetings: !filters.meetings })}>Citas</Button>
                  <Button color={filters.tasks ? 'dark' : 'secondary'} outline={!filters.tasks} onClick={() => handleChange({ tasks: !filters.tasks })}>Tareas</Button>
                  <Button color={filters.reminders ? 'dark' : 'secondary'} outline={!filters.reminders} onClick={() => handleChange({ reminders: !filters.reminders })}>Alertas</Button>
               </ButtonGroup>
            </div>
            <CardTitle className="mb-4 hide-sm">Actividades {filters.pendding && 'Pendientes'}</CardTitle>
            <ActivitiesTimeline activities={activities} reload={() => setActivities(null)} />
         </Container>
      </React.Fragment>
   )
}

const Container = styled.div`
   padding: 20px;
   margin-bottom: 20px;
   border-top: 2px solid #fff;
   border-bottom: 2px solid #fff;
   min-height: 180px;
`