import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'

import { storeDownloadToken } from '../containers/DownloadTokens/services'
import { getUri } from '../services/s4salesapi'
import { handleError, parseExportParams } from '../helpers'

export default function ExportList({ params, type }) {
    const currentUserId = useSelector(state => state.auth.user_id)
    const [loading, setLoading] = useState(false)

    const handleExport = () => {
        setLoading(true)

        let token = Date.now() + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)

        storeDownloadToken({ user_id: currentUserId, type, token })
            .then(() => {
                message.info('Generando reporte en nueva pestaña')
                window.open(`${getUri()}/exports/${type}/${token}${parseExportParams(params)}`)
            })
            .catch(error => handleError(error))
            .finally(() => setLoading(false))
    }

    return (
        <React.Fragment>
            <div className='text-right'>
                <Button 
                    color='info' 
                    outline 
                    onClick={handleExport} 
                    disabled={loading}
                >
                    { loading && <Spinner animation="border" size="sm" /> }{" "}
                    <FontAwesomeIcon icon={faDownload} className='mr-2' />
                    Exportar Listado
                </Button>
            </div>
        </React.Fragment>
    )
}