import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Table, Empty } from 'antd'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'

import { setQuery, updatePagination } from '../actions'
import TableActions from '../../../components/TableActions'
import Pagination from '../../../components/Pagination'
import { deleteDeal } from '../services'
import { handleError } from '../../../helpers'

function DealsTable({ deals, stateProps, reload, history }) {
   const dispatch = useDispatch()

   const handleDelete = id => {
      deleteDeal(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      dispatch(setQuery({ sort: newSort }))
      reload()
   };

   const columns = [
      {
         title: 'Referencia',
         dataIndex: 'reference',
         sorter: true,
         render: (text, record) => (
            <Link to={`/deals/${record.id}`}>{text}</Link>
         ),
         width: 180,
         fixed: 'left',
      },
      {
         title: 'Etapa',
         dataIndex: 'stage',
         width: 120,
         render: text => text.name
      },
      {
         title: 'Contacto',
         dataIndex: 'contact',
         width: 180,
         render: text => text.fullname
      },
      {
         title: 'Total de Items',
         dataIndex: 'total_items',
         width: 140,
         render: text => `${text} unidades`
      },
      {
         title: 'Valor Total',
         dataIndex: 'total_value',
         width: 140,
         render: text => `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      },
      {
         title: 'Fecha de Venta',
         dataIndex: 'state_changed_at',
         sorter: true,
         width: 170,
         render: text => moment(text).format('Y-MMM-DD h:mm a')
      },
      {
         title: 'Acciones',
         width: 120,
         render: (txt, record) => (
            <TableActions 
               handleEdit={()=> history.push(`/deals/${record.id}`)}
               handleDelete={() => handleDelete(record.id)}
            />
         )
      }
   ]

   return (
      <Card>
         <CardBody className="p-3">
            <div className="table-responsive">
               <Table 
                  columns={columns}
                  dataSource={deals}
                  loading={!deals && true}
                  showSorterTooltip={false}                  
                  rowKey={record => record.id}
                  pagination={false}
                  onChange={handleTableChange}
                  scroll={{ x: 'scroll', scrollToFirstRowOnChange: true }}
                  locale={{ emptyText: <Empty description="No se encontraron negocios" className="mt-4" /> }}
               />
               <Pagination stateProps={stateProps} reload={reload} updatePagination={updatePagination} />
            </div>
         </CardBody>
      </Card>
   )
}

export default withRouter(DealsTable)