import React, {useState} from 'react'
import { Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import Breadcrumbs from '../../../components/Breadcrumb'
import NewCustomField from './NewCustomField'
import CustomFieldList from './CustomFieldsList'

import {getCustomFields} from './services'
import { handleError } from '../../../helpers'
 
export default function CustomFields(props) {
   const modelDefault = props.match.params.model ? props.match.params.model : 'contacts'
   
   const [model, setModel] = useState(modelDefault)
   const [customFields, setCustomFields] = useState(null)

   !customFields && getCustomFields({ 'filter[model]': model })
      .then(response => setCustomFields(response.data.data))
      .catch(error => handleError(error))

   const reloadData = () => setCustomFields(null)

   return (
      <React.Fragment>
         <Breadcrumbs title="Ajustes" breadcrumbItem="Campos Personalizados" />
         <Row>
            <Col md="4">
               <FormContainer>
                  <Form.Control                        
                     as="select"
                     onChange={e => { setModel(e.target.value); reloadData(); } }
                     value={model}
                  >
                     <option value="contacts">Módulo de Contactos</option>
                     <option value="companies">Módulo de Empresas</option>
                     <option value="deals">Módulo de Negocios</option>
                  </Form.Control>
               </FormContainer>
               <NewCustomField model={model} reload={reloadData} />
            </Col>
            <Col>
               <CustomFieldList reload={reloadData} customFields={customFields} />
            </Col>
         </Row>
      </React.Fragment>
   )    
}

const FormContainer = styled.div`
   background-color: #DADAEA;
   padding: 20px;
   margin-bottom: 20px;
`