import React, {useState} from 'react'
import { Spinner, Row, Col } from 'reactstrap'

import ListTopBar from './partials/ListTopBar'
import EmptyCard from '../../components/EmptyCard'
import { getSegments } from './services'
import { handleError } from '../../helpers'
import SegmentCard from './partials/SegmentCard'
import EditSegmentModal from './partials/EditSegmentModal'

export default function SegmentsList() {
	const [segments, setSegments] = useState(null)
	const [editSegment, setEditSegment] = useState(null)

	!segments && getSegments()
		.then(res => setSegments(res.data.data))
		.catch(error => handleError(error))

	return (
		<React.Fragment>
			<ListTopBar />
			{ segments ? (
				<React.Fragment>
					{ segments.length > 0 ? (
						<Row>							
							{ segments.map(segment => (
								<Col md="4" key={segment.id}>
									<SegmentCard 
										segment={segment} 
										reload={()=>setSegments(null)}
									/>
								</Col>
							))}
						</Row>
					) : <EmptyCard 
						description="No tiene segmentos activos" 
						buttonLink="/segments/wizard" 
						buttonText="Crear Segmento Personalizado" 
					/>}
				</React.Fragment>
			) : <Spinner />}
			<EditSegmentModal 
				visible={editSegment ? true : false} 
				onClose={reload => {
					setEditSegment(null)
					if(reload) setSegments(null)
				}}
				segment={editSegment}
			/>
		</React.Fragment>
	)
}