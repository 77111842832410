import React from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Divider } from 'antd'
import { InputGroup, Form } from 'react-bootstrap'
import { isEmpty } from 'lodash'

import { setQuery, showNewModal } from '../actions'

export default function ListTopBar({ currentView, reload }) {
   const { handleSubmit, register } = useForm()
   const filters = useSelector(state => state.companies_props.query.filters)
   const dispatch = useDispatch()

   const onSubmit = values => {
      if(values.name === '') delete values.name
      dispatch(setQuery({ filters: values }))
      reload()
   }

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               Listado de Empresas{" "}
               <span className="text-warning">{ !isEmpty(filters) && ' - Aplicando Filtros' }</span>
            </h4>
            <div className="page-title-right">
               <Button color="" className={`font-size-14 hide-sm ${currentView === 'table' && 'text-primary'}`}>
                  <i className="fa fa-table"></i> Tabla
               </Button>
               <Divider type="vertical" className="hide-sm" />
               <Form className="d-inline-block hide-sm" onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup className="mb-0">
                     <Form.Control
                        name="name"
                        placeholder="Busqueda rápida..."
                        ref={register({})}
                     />
                     <InputGroup.Append>
                        <Button color="primary">
                           <i className="fa fa-search"></i>
                        </Button>
                     </InputGroup.Append>
                  </InputGroup>
               </Form>
               <Divider type="vertical" />
               <Button
                  color="primary"
                  style={{ padding: '8px 20px', fontSize: '14px' }}
                  onClick={() => dispatch(showNewModal(true))}
               >
                  Crear Empresa
               </Button>
            </div>
         </div>
      </React.Fragment>
   )
}