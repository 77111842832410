import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { Divider } from 'antd'

export default function PipelineBar({ currentPipeline, setCurrentPipeline, pipelines, setBoard }) {
   return (
      <div>
         <Form className="d-inline-block hide-sm">
            <Form.Group>
               <Form.Control
                  as="select"
                  value={currentPipeline}
                  onChange={e => {
                     setCurrentPipeline(e.target.value)
                  }}
               >
                  { pipelines.map((pipe, index) => (
                     <option key={pipe.id} value={index}>{pipe.name}</option>
                  )) }
               </Form.Control>
            </Form.Group>
         </Form>
         <Divider type="vertical" className="hide-sm" />
         <Link to="/settings/pipelines">
            <Button className="hide-sm topbar-btn" color="dark" outline>Administrar Embudos y Etapas</Button>
         </Link>
      </div>
   )
}