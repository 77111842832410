import React, { useState, useEffect } from 'react'
import { Table, Empty, Divider } from 'antd'
import { Form } from 'react-bootstrap'
import { Spinner, Card, CardBody, CardTitle } from 'reactstrap'

export default function ProductSales({ products, salesCount, salesTotal }) {
	const [term, setTerm] = useState('')
	const [productsFiltered, setProductsFiltered] = useState([])	

	useEffect(()=>{
		let newProducts = products ? products.filter(product => product.name.toLowerCase().includes(term.toLowerCase())) : []
		setProductsFiltered(newProducts)
	}, [term, products])

	if(!products) return <Spinner size="sm" />

	const getTotalCount = () => {
		let total = 0
		productsFiltered.map(pd => total = total + parseInt(pd.quantityTotal))
		return total
	}

	const getTotalSales = () => {
		let total = 0
		productsFiltered.map(pd => total = total + parseInt(pd.valueTotal))
		return `$${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
	}

	const columns = [
		{
			title: 'Producto',
			dataIndex: 'name',
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			title: 'Ventas',
			dataIndex: 'quantityTotal',
			sorter: (a, b) => a.quantityTotal - b.quantityTotal,
			render: total => (
				<React.Fragment>
					{total} <span className="text-muted small">
						{total ? round(total/salesCount*100) : 0}%
					</span>
				</React.Fragment>
			)
		},
		{
			title: 'Total',
			dataIndex: 'valueTotal',
			sorter: (a, b) => a.valueTotal - b.valueTotal,
			render: total => (
				<React.Fragment>
					{`$${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} <span className="text-muted small">
						{total ? round(total/salesTotal*100) : 0}%
					</span>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Productos Vendidos</CardTitle>
					<Divider className="mt-2 mb-3" />
					<div className=" mb-3">
						<Form.Control
							placeholder="Buscar producto por nombre"
							style={{ maxWidth: '350px' }}
							value={term}
							onChange={e => setTerm(e.target.value)}
						/>
					</div>
					<Table 
						columns={columns}
						rowKey={record => record.product_id}
						size="small"
						dataSource={productsFiltered}
						locale={{ emptyText: <Empty description="No se encontro productos" /> }}
						footer={() => (
							<React.Fragment>
								<small className="text-muted">Totales en tabla:</small>{" "}
								<span className="text-muted">Ventas: </span>{getTotalCount()}.
								<span className="text-muted ml-2">Total: </span>{getTotalSales()}
							</React.Fragment>
						)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const round = number => {
	return Math.round((number + Number.EPSILON) * 10) / 10
}