import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";

import ListTopBar from './partials/ListTopBar'

export default function AdsCampaigns() {
	return (
		<React.Fragment>
			<ListTopBar 
				title="Audiencias para Facebook Ads" 
				ctaText="Crear Audiencia Nueva"
				ctaLink="/campaigns/ads/new"
			/>
			<Row>
				<Col>
					<div className="pb-5">
						<div className="table-responsive pb-5">
							<Table className="project-list-table table-nowrap table-centered table-borderless pb-5">
								<thead>
									<tr>
										<th scope="col">Audiencia Personalizada</th>
										<th scope="col">Exportado</th>
										<th scope="col">Sin Exportar</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="text-truncate font-size-14"><Link to="#" className="text-primary">Clientes Frecuentes</Link></h5>
											<p className="text-muted mb-0">Última exportación: 13 Noviembre 2020 11:00</p>
										</td>
										<td>
											<h6 className="mb-1">102,100 exportados</h6>
											<span className="text-muted">120,303 contactos</span>
										</td>
										<td>
											<h6 className="mb-1">203 nuevos</h6>
										</td>
										<td>											
											<UncontrolledDropdown>
												<Button color="dark" size="sm" className="mr-3" outline>Exportar</Button>
												<DropdownToggle href="#" className="card-drop" tag="i">
													<i className="mdi mdi-dots-horizontal font-size-18"></i>
												</DropdownToggle>
												<DropdownMenu right>
														<DropdownItem href="#">Editar Campaña</DropdownItem>
														<DropdownItem href="#">Eliminar</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
}