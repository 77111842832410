import React from 'react'

export default function SegmentsMarket() {
	return (
		<React.Fragment>
			<div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">Segmentos Sugeridos</h4>
			</div>
		</React.Fragment>
	)
}