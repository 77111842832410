import React, { useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchCalendars } from '../../Settings/Calendars/services'


export default function BookingForm({ register, errors, setValue, onlyEditable }) {
    const [selectedCalendar, setSelectedCalendar] = useState([])

    return (
        <React.Fragment>
            <Form.Group>
                  <Form.Label>Calendario Asociado <span className="text-danger">*</span></Form.Label>
                  <ServerSideSelect
                        value={selectedCalendar}
                        placeholder="Buscado por nombre"
                        fetchOptions={searchCalendars}
                        className="full-width"
                        onChange={value => {
                            setSelectedCalendar(value)
                            setValue('calendar_id', value.value)
                        }}
                    />
                <Form.Control name="calendar_id" ref={register({ required: true })} style={{ display:'none' }} />
                { errors.calendar_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Fecha de Ingreso <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="entry_at_date"
                        type="date"
                        ref={register({ required: true })}
                    />
                </Form.Group>
                { errors.entry_at_date && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                <Form.Group as={Col}>
                    <Form.Label>Hora de Ingreso <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="entry_at_time"
                        type="time"
                        ref={register({ required: true })}
                    />
                </Form.Group>
                { errors.entry_at_time && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Fecha de Salida <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="exit_at_date"
                        type="date"
                        ref={register({ required: true })}
                    />
                </Form.Group>
                { errors.exit_at_date && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                <Form.Group as={Col}>
                    <Form.Label>Hora de Salida <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="exit_at_time"
                        type="time"
                        ref={register({ required: true })}
                    />
                </Form.Group>
                { errors.exit_at_time && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Horas pre-reserva <span className='text-danger'>*</span></Form.Label>
                    <InputGroup>
                        <Form.Control 
                            name="preentry_hours"
                            ref={register({required: true, max:100})}
                            type="number"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>horas</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    { errors.preentry_hours && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Horas pos-reserva <span className='text-danger'>*</span></Form.Label>
                    <InputGroup>
                        <Form.Control 
                            name="afterexit_hours"
                            ref={register({required: true, max:100})}
                            type="number"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>horas</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    { errors.afterexit_hours && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Observaciones <small>(opcional)</small></Form.Label>
                <Form.Control 
                    name="observations"
                    ref={register({ })}
                    placeholder="Escriba aquí..."
                    as="textarea"
                    style={{ height: '100px' }}
                />
            </Form.Group>
        </React.Fragment>
    )
}