import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Table, Empty, Descriptions } from 'antd'
import { Card, CardBody, Badge } from 'reactstrap'
import moment from 'moment'

import { setQuery, editResource, updatePagination } from '../actions'
import TableActions from '../../../components/TableActions'
import Pagination from '../../../components/Pagination'
import { deleteActivity } from '../services'
import { handleError } from '../../../helpers'

function ActivitiesTable({ activities, stateProps, reload, history }) {
   const dispatch = useDispatch()

   const handleDelete = id => {
      deleteActivity(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      dispatch(setQuery({ sort: newSort }))
      reload()
   };

   const columns = [
      {
         title: 'Tipo',
         dataIndex: 'type',
         fixed: 'left',
         width: 65,
         render: text => {
            switch (text) {
               case 'call': return <i className="fa fa-phone"></i>      
               case 'meeting': return <i className="fa fa-calendar-day"></i>
               case 'task': return <i className="fa fa-check-square"></i>
               case 'reminder': return <i className="fa fa-bell"></i>
               default: return null
            }
         }
      },
      {
         title: 'Referencia',
         dataIndex: 'reference',
         width: 210,
         fixed: 'left',
         render: (text, record) => (
            <Link to={`/${record.actionable_type}/${record.actionable_id}`}>{text}</Link>
         )
      },
      {
         title: 'Estado',
         dataIndex: 'state',
         width: 100,
         render: text => {
            switch (text) {
               case 'draft': return <Badge color="soft-dark">Borrador</Badge>         
               case 'active': return <Badge color="soft-info">Activa</Badge>         
               case 'completed': return <Badge color="soft-success">Completada</Badge>         
               case 'canceled': return <Badge color="soft-danger">Cancelada</Badge>         
               default: return null
            }
         }
      },
      {
         title: 'Vence el',
         dataIndex: 'due_at',
         sorter: true,
         width: 150,
         render: text => moment(text).format('Y-MM-DD HH:mm')
      },
      {
         title: 'Asesor Asignado',
         dataIndex: 'owner',
         width: 160,
         render: text => text ? text.name : 'Sin asesor'
      },
      {
         title: 'Asociada con',
         dataIndex: 'actionable',
         width: 160,
         render: (text, record) => {
            if(!text) return 'Sin referencia'

            let label = ''
            switch (record.actionable_type) {
               case 'contacts': label = text.fullname ? text.fullname : 'Contacto'; break;
               case 'deals': label = text.name ? text.name : 'Negocio'; break;
               case 'companies': label = text.name ? text.name : 'Empresa'; break;
               default: break;
            }
            return <Link to={`/${record.actionable_type}/${record.actionable_id}`}>{label}</Link>
         }
      },
      {
         title: 'Creada el',
         dataIndex: 'created_at',
         sorter: true,
         width: 160,         
         render: text => moment(text).format('Y-MM-DD HH:mm')
      },
      {
         title: 'Acciones',
         width: 120,
         render: (txt, record) => (
            <TableActions 
               handleEdit={()=> dispatch(editResource(record))}
               handleDelete={() => handleDelete(record.id)}
            />
         )
      }
   ]

   const activityDetails = activity => (
      <Descriptions column={2} size="small">
         <Descriptions.Item label="Último cambio de estado">{activity.state_changed_at ? moment(activity.state_changed_at).format('YYYY-MM-DD HH:mm') : <em>Sin info</em>}</Descriptions.Item>
         <Descriptions.Item label="Última modificación">{moment(activity.updated_at).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
         <Descriptions.Item label="Duración">{activity.duration_in_minutes ? activity.duration_in_minutes+' minutos' : <em>Sin Descripción</em>}</Descriptions.Item>
         <Descriptions.Item label="Descripción">{activity.description ? activity.description : <em>Sin Descripción</em>}</Descriptions.Item>
      </Descriptions>
   )

   return (
      <Card>
         <CardBody className="p-3">
            <div className="table-responsive">
               <Table 
                  columns={columns}
                  dataSource={activities}
                  loading={!activities && true}
                  showSorterTooltip={false}                  
                  rowKey={record => record.id}
                  pagination={false}
                  onChange={handleTableChange}
                  scroll={{ x: 'scroll', scrollToFirstRowOnChange: true }}
                  locale={{ emptyText: <Empty description="No se encontraron actividades" className="mt-4" /> }}
                  expandable={{
                     expandedRowRender: record => activityDetails(record),
                  }}
               />
               <Pagination stateProps={stateProps} reload={reload} updatePagination={updatePagination} />
            </div>
         </CardBody>
      </Card>
   )
}

export default withRouter(ActivitiesTable)