import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Table, Empty, Descriptions, Divider, Tooltip } from 'antd'
import { Card, CardBody, Badge, Button } from 'reactstrap'
import moment from 'moment'

import { setQuery, editResource, updatePagination } from '../actions'
import TableActions from '../../../components/TableActions'
import Pagination from '../../../components/Pagination'
import { deleteProduct } from '../services'
import { handleError } from '../../../helpers'
import ProductStats from './ProductStats'

export default function ProductsTable({ products, stateProps, reload, categories }) {
   const dispatch = useDispatch()
   const [showStatsOf, setShowStatsOf] = useState(null)

   const handleDelete = id => {
      deleteProduct(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      dispatch(setQuery({ sort: newSort }))
      reload()
   };

   const columns = [
      {
         title: 'Nombre',
         dataIndex: 'name',
         sorter: true,
         width: 170,
         fixed: 'left',
      },
      {
         title: 'SKU',
         dataIndex: 'sku',
         sorter: true,
         width: 110,
         render: text => text ? text : '-'
      },
      {
         title: 'Valor',
         dataIndex: 'default_value',
         sorter: true,
         width: 120,
         render: text => `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      },
      {
         title: 'Tipo',
         width: 110,
         render: (text, record) => (
            <React.Fragment>
               <Badge color="soft-info" className={record.is_featured !== true ? 'hide' : ''}>Es Destacado</Badge>
               <Badge color="soft-info" className={record.is_subscription !== true ? 'hide' : ''}>Es Recurrente</Badge>
               { !record.is_featured && !record.is_subscription && "-" }
            </React.Fragment>
         )
      },
      {
         title: 'Categoría',
         dataIndex: 'category_id',
         width: 150,
         render: text => {
            if(text && categories){
               let ct = categories.filter(category => category.id === text)
               return <span>{ct && ct[0].name}</span>
            } else {
               return <em className="small">Sin Categoría</em>
            }
         }
      },
      {
         title: 'Creado el',
         dataIndex: 'created_at',
         sorter: true,
         width: 160,
         render: text => moment(text).format('Y-MM-DD HH:mm')
      },
      {
         title: 'Acciones',
         width: 170,
         render: (txt, record) => (
            <React.Fragment>
               <TableActions 
                  handleEdit={()=> dispatch(editResource(record))}
                  handleDelete={() => handleDelete(record.id)}
               />
               <Divider type="vertical" />
               <Tooltip title="Estadísticas">
                  <Button outline size="sm" color="primary" onClick={() => setShowStatsOf(record.id)}>
                     <i className="fa fa-tachometer-alt"></i>
                  </Button>
               </Tooltip>
            </React.Fragment>
         )
      }
   ]

   const productDetails = product => (
      <Descriptions column={2} size="small">
         <Descriptions.Item label="Días de recurrencia">{product.recurrency_in_days ? product.recurrency_in_days + ' días' : <em className="small">Sin recurrencia</em>}</Descriptions.Item>
         <Descriptions.Item label="Última Actualización">{moment(product.updated_at).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
         <Descriptions.Item label="Descripción" span={2}>{product.description ? product.description : <em className="small">Sin descripción</em>}</Descriptions.Item>
      </Descriptions>
   )

   return (
      <React.Fragment>
         <Card>
            <CardBody className="p-3">
               <div className="table-responsive">
                  <Table 
                     columns={columns}
                     dataSource={products}
                     loading={!products && true}
                     showSorterTooltip={false}                  
                     rowKey={record => record.id}
                     pagination={false}
                     onChange={handleTableChange}
                     scroll={{ x: 'scroll', scrollToFirstRowOnChange: true }}
                     locale={{ emptyText: <Empty description="No se encontraron productos" className="mt-4" /> }}
                     expandable={{
                        expandedRowRender: record => productDetails(record),
                     }}
                  />
                  <Pagination stateProps={stateProps} reload={reload} updatePagination={updatePagination} />
               </div>
            </CardBody>
         </Card>
         <ProductStats 
            show={showStatsOf !== null ? true : false}
            close={() => setShowStatsOf(null)} 
            productId={showStatsOf}
         />
      </React.Fragment>
   )
}