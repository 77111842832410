import React from 'react'
import {Link} from 'react-router-dom'
import {Card, CardBody, Badge} from 'reactstrap'

export default function DealCard({ deal }) {
   return (
      <Card className="task-box mb-2">
         <CardBody className="borad-width px-3 pt-3 pb-2">
            <div className="float-right ml-2">
               <Badge color="secondary" className="badge-soft-secondary font-size-12" pill>
                  {deal.state === 'draft' && 'Sugerido'}
                  {deal.state === 'active' && 'Activo'}
                  {deal.state === 'won' && 'Ganado'}
                  {deal.state === 'lost' && 'Perdido'}
               </Badge>
            </div>
            <div>
               <h5 className="font-size-16">
                  <Link to={`/deals/${deal.id}`} className="text-dark">{deal.reference}</Link>
               </h5>
               <p className="text-muted mb-0">
                  <Link to={`/contacts/${deal.contact_id}`} className="text-dark">{deal.contact.fullname}</Link>
               </p>
            </div>

            <div className="text-right">
               <h5 className="font-size-15 mb-0">
                  {`$ ${deal.total_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
               </h5>
            </div>
         </CardBody>
      </Card>
   )
}