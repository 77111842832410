export const UPDATE_PAGINATION = 'products_props/UPDATE_PAGINATION'
export const SET_QUERY = 'products_props/SET_QUERY'
export const SHOW_NEW_MODAL = 'products_props/SHOW_NEW_MODAL'
export const EDIT_RESOURCE = 'products_props/EDIT_RESOURCE'

export const updatePagination = data => ({
   type: UPDATE_PAGINATION,
   payload: data
})

export const setQuery = data => ({
   type: SET_QUERY,
   payload: data
})

export const showNewModal = show => ({
   type: SHOW_NEW_MODAL,
   payload: show
})

export const editResource = data => ({
   type: EDIT_RESOURCE,
   payload: data
})