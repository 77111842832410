import React, {useState} from 'react'
import { InputGroup, Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

export default function Topbar({ term }) {
	const [newTerm, setNewTerm] = useState(term)

	const handleSearch = () => {
		if(newTerm) window.location.href = `/search/${encodeURI(newTerm.toLowerCase())}`
	}
	
	return (
		<React.Fragment>
			<div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
					<span className="text-muted">Resultados para: </span>
					{term}
            </h4>
            <div className="page-title-right">
					<InputGroup>
						<Form.Control
							placeholder="Busqueda rápida..."
							value={newTerm}
							onChange={e => setNewTerm(e.target.value)}
							onKeyDown={e => e.key === 'Enter' && handleSearch()}
						/>
						<InputGroup.Append>
							<Button color="primary" onClick={() => handleSearch()}>Buscar</Button>
						</InputGroup.Append>
					</InputGroup>
				</div>
         </div>
		</React.Fragment>
	)
}