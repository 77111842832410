import s4salesapi from '../../../services/s4salesapi'

export const getPipelines = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/pipelines', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createPipeline = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/pipelines', data) 
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updatePipeline = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/pipelines/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const deletePipeline = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/pipelines/${id}`) 
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

// Pipelines Stages

export const getStages = params => (
   new Promise((resolve, reject) => {
      s4salesapi()   
         .get('/pipeline_stages', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createStage = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/pipeline_stages', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateStage = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/pipeline_stages/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))   
   })
)

export const deleteStage = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/pipeline_stages/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)