import React, {useState} from 'react'
import { Select } from 'antd';

import {getContacts} from '../containers/Contacts/services'
import {getDeals} from '../containers/Deals/services'
import {getCompanies} from '../containers/Companies/services'
import {getUsers} from '../containers/Settings/Users/services'
import { handleError, parseQueryParams } from '../helpers'

export default function SearchEntity({ resourceType, style, setResourceId }) {
   const [data, setData] = useState([])
   const [value, setValue] = useState('')

   React.useEffect(() => {
      setData([])
      setValue('')
      setResourceId(null)
    }, [resourceType, setResourceId]);

   const search = term => {
      let request;
      switch (resourceType) {
         case 'contacts':
            let params = parseQueryParams({ page: 1, per_page: 10, query: { sort: 'fullname', filters: { fullname: term }, fields: { contacts: 'fullname,id,email' } } })
            request = getContacts(params)
            break;

         case 'deals':
            let dealsParams = parseQueryParams({ page: 1, per_page: 10, query: { sort: 'reference', filters: { reference: term }, fields: { deals: 'reference,id' } } })
            request = getDeals(dealsParams)
            break;

         case 'companies':
            let companiesParams = parseQueryParams({ page: 1, per_page: 10, query: { sort: 'name', filters: { name: term }, fields: { companies: 'name,id' } } })
            request = getCompanies(companiesParams)
            break;

         case 'users':
            let usersParams = parseQueryParams({ page: 1, per_page: 10, query: { sort: 'name', filters: { name: term } } })
            request = getUsers(usersParams)
            break;

         default: break;
      }
      if(request) request.then(response => setData(response.data.data)).catch(error => handleError(error))
      else setData([])
   }

   const options = data.map(d => (
      <Select.Option 
         key={d.id} 
         value={d.id}
      >
         { resourceType === 'contacts' && (
            <React.Fragment>
               { d.fullname ? d.fullname : d.email }
            </React.Fragment>
         )}
         { resourceType === 'deals' && d.reference }
         { resourceType === 'companies' && d.name }
         { resourceType === 'users' && d.name }
      </Select.Option>
   ));

   return (
      <Select
         showSearch
         value={value}
         style={style}
         defaultActiveFirstOption={false}
         placeholder="Buscar por nombre..."
         showArrow={false}
         filterOption={false}
         onSearch={value => value ? search(value) : setData([])}
         onChange={value => { 
            setValue(value)
            setResourceId(value)
         }}
         notFoundContent="Sin resultados"
      >
         { options }
      </Select>
   );
}