import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardFooter, Button, Badge } from 'reactstrap'
import { message, Popconfirm } from 'antd'

import { deleteSegment } from '../services'
import { handleError } from '../../../helpers'

export default function SegmentCard({ segment, reload }) {

	const onDelete = () => {
		deleteSegment(segment.id)
			.then(() => {
				message.success('Segmento eliminado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Card>
			<CardBody className="border-bottom-divider">
				{ segment.scope ? (						
					<Badge className="mb-2 float-right" color="secondary">{ segment.scope === 'clients' ? 'SOLO CLIENTES' : 'SOLO PROSPECTOS' }</Badge> 
				) : (
					<Badge className="mb-2 float-right" color="secondary">TODOS LOS CONTACTOS</Badge>
				)}
				<h5>{segment.name}</h5>
				<p className="mb-0">{segment.description}</p>
			</CardBody>
			<CardFooter className="bg-white">
				<div className="float-right">
					<Link to={`/segments/${segment.id}`}>
						<Button color="dark">Ver Segmento</Button>
					</Link>
				</div>
				<Popconfirm
					title="¿Desea eliminar el segmento?"
					cancelText="Cancelar"
					okText="Eliminar"
					onConfirm={onDelete}
				>
					<Button color="danger" outline size="sm">
						<i className="fa fa-trash" />
					</Button>
				</Popconfirm>
			</CardFooter>
		</Card>
	)
}