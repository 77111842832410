import React from 'react'
import { Table } from 'antd'

export default function EmailOpensTable(props) {

	const columns = [
		{
			title: 'Email'
		},
		{
			title: 'Aperturas'
		},
		{
			title: 'Primera apertura'
		}
	]

	return (
		<React.Fragment>
			<Table
				dataSource={[]}
				columns={columns}
				size="middle"
			/>
		</React.Fragment>
	)
}