import React, {useState} from 'react'
import { Row, Col } from 'reactstrap'

import LogFeed from '../../components/LogFeed'
import ResourceActivities from '../../components/ResourceActivities'
import ResourceTags from '../../components/ResourceTags'
import ProfileTopBar from './partials/ProfileTopBar'
import CompanyContacts from './partials/CompanyContacts'
import CompanyDetails from './partials/CompanyDetails'
import CompanyStats from './partials/CompanyStats'

import { getCompany, getCompanyTags, attachCompanyTag, detachCompanyTag } from './services'
import { handleError } from '../../helpers'

export default function CompanyProfile(props) {
   const [company, setCompany] = useState(null)
   const companyId = props.match.params.id

   !company && getCompany(companyId, { include: 'contacts,customValues,logs,logs.causer' })
      .then(response => setCompany(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <ProfileTopBar title={company ? company.name : ''} companyId={companyId} />
         <Row>
            <Col md="4">
               <CompanyDetails company={company} reload={() => setCompany(null)} />
            </Col>
            <Col md="8">               
               <ResourceActivities resourceType="companies" resourceId={companyId} />
               <Row>
                  <Col md="8">
                     <CompanyContacts 
                        companyId={companyId} 
                        contacts={company && company.contacts} 
                        reload={() => setCompany(null)} 
                     />
                     <LogFeed logs={company && company.logs} />
                  </Col>
                  <Col md="4">                     
                     <ResourceTags 
                        resourceId={companyId} 
                        getService={getCompanyTags} 
                        attachService={attachCompanyTag} 
                        detachService={detachCompanyTag} 
                     />
                     <CompanyStats company={company} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </React.Fragment>
   )
}