import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, Spinner } from 'reactstrap'
import { Divider, Table, Empty } from 'antd'
import { Form } from 'react-bootstrap'

export default function UsersBoard({ users, salesCount, salesTotal }) {
	const [term, setTerm] = useState('')
	const [usersFiltered, setUsersFiltered] = useState([])	

	useEffect(()=>{
		let newUsers = users ? users.filter(user => user.name.toLowerCase().includes(term.toLowerCase())) : []
		setUsersFiltered(newUsers)
	}, [term, users])

	if(!users) return <Spinner size="sm" />

	const getTotalCount = () => {
		let total = 0
		usersFiltered.map(pd => total = total + parseInt(pd.quantityTotal))
		return total
	}

	const getTotalSales = () => {
		let total = 0
		usersFiltered.map(pd => total = total + parseInt(pd.valueTotal))
		return `$${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
	}

	const getTotalProducts = () => {
		let total = 0
		usersFiltered.map(pd => total = total + parseInt(pd.productsTotal))
		return total
	}

	const columns = [
		{
			title: 'Asesor',
			dataIndex: 'name',
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			title: 'Ventas',
			dataIndex: 'quantityTotal',
			sorter: (a, b) => a.quantityTotal - b.quantityTotal,
			render: total => (
				<React.Fragment>
					{total} <span className="text-muted small">
						{total ? round(total/salesCount*100) : 0}%
					</span>
				</React.Fragment>
			)
		},
		{
			title: 'Total en Ventas',
			dataIndex: 'valueTotal',
			sorter: (a, b) => a.valueTotal - b.valueTotal,
			render: total => (
				<React.Fragment>
					{`$${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} <span className="text-muted small">
						{total ? round(total/salesTotal*100) : 0}%
					</span>
				</React.Fragment>
			)
		},
		{
			title: 'Productos',
			dataIndex: 'productsTotal',
			sorter: (a, b) => a.productsTotal - b.productsTotal,
			render: (total, record) => (
				<React.Fragment>
					{record.quantityTotal ? round(total/record.quantityTotal) : 0} por venta
				</React.Fragment>
			)
		},
		{
			title: 'Unidades',
			dataIndex: 'productsQuantityTotal',
			sorter: (a, b) => a.productsQuantityTotal - b.productsQuantityTotal,
			render: (total, record) => (
				<React.Fragment>
					{total} {record.quantityTotal ? <span className="text-muted small">{round(total/record.quantityTotal)} por venta</span> : ''}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Comparativo de Asesores</CardTitle>
					<Divider className="mt-2 mb-3" />
					<div className=" mb-3">
						<Form.Control
							placeholder="Buscar asesor por nombre"
							style={{ maxWidth: '350px' }}
							value={term}
							onChange={e => setTerm(e.target.value)}
						/>
					</div>
					<Table 
						columns={columns}
						rowKey={record => record.id}
						size="small"
						dataSource={usersFiltered}
						locale={{ emptyText: <Empty description="No se encontro asesores" /> }}
						expandable={{
							expandedRowRender: record => <p style={{ margin: 0 }}>
								<strong>Total de contactos asignados: </strong>{record.totalContacts}
							</p>
						}}
						footer={() => (
							<React.Fragment>
								<small className="text-muted">Totales en tabla:</small>{" "}
								<span className="text-muted">Ventas: </span>{getTotalCount()}.
								<span className="text-muted ml-2">Total: </span>{getTotalSales()}.
								<span className="text-muted ml-2">Unidades: </span>{getTotalProducts()}.
							</React.Fragment>
						)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const round = number => {
	return Math.round((number + Number.EPSILON) * 10) / 10
}