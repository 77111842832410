export const UPDATE_PAGINATION = 'contacts_props/UPDATE_PAGINATION'
export const SET_QUERY = 'contacts_props/SET_QUERY'
export const SHOW_NEW_MODAL = 'contacts_props/SHOW_NEW_MODAL'

export const updatePagination = data => ({
   type: UPDATE_PAGINATION,
   payload: data
})

export const setQuery = data => ({
   type: SET_QUERY,
   payload: data
})

export const showNewModal = show => ({
   type: SHOW_NEW_MODAL,
   payload: show
})