import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import SearchProduct from './SearchProduct'

import { attachDealProduct } from '../services'
import { handleError } from '../../../helpers'

export default function AttachProductModal({ visible, onClose, dealId, reload }) {
   const { handleSubmit, register, errors } = useForm()
   const [product, setProduct] = useState(null)
   const [qty, setQty] = useState(1)
   const [pvalue, setPvalue] = useState('')

   const onSubmit = values => {
      attachDealProduct(dealId, values)
         .then(res => {
            message.success(res.data.message)
            onClose()
            reload()
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <Modal isOpen={visible} toggle={() => onClose()}>
            <ModalHeader toggle={() => onClose()}>Administrar Producto</ModalHeader>
            <ModalBody>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Producto</Form.Label>
                     <SearchProduct 
                        setProduct={setProduct} 
                        setPvalue={setPvalue}
                        qty={qty}
                     />
                     <Form.Control
                        type="hidden"
                        name="product_id"
                        value={product ? product.id : ''}
                        ref={register({ required: true })}
                     />
                     { errors.product_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Row>
                     <Col sm="4">
                        <Form.Group>
                           <Form.Label>Cantidad</Form.Label>
                           <Form.Control
                              name="quantity"
                              type="number"
                              defaultValue={qty}
                              onChange={e => {
                                 setPvalue(pvalue ? (pvalue/qty) * e.target.value : 0)
                                 setQty(e.target.value)
                              }}
                              ref={register({ required: true })}
                           />
                           { errors.quantity && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                     </Col>
                     <Col sm="8">
                        <Form.Group>
                           <Form.Label>Valor Total</Form.Label>
                           <Form.Control
                              name="value"
                              type="number"
                              placeholder="Escribe el valor total"
                              value={pvalue}
                              onChange={e => setPvalue(e.target.value)}
                              ref={register({ required: true })}
                           />
                           { errors.value && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                     </Col>
                  </Row>
                  <Button color="primary" className="float-right">Agregar Producto</Button>
               </Form>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}