import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import CalendarsTable from './partials/CalendarsTable'
import NewCalendarForm from './partials/NewCalendarForm'
import Breadcrumbs from '../../../components/Breadcrumb'

import { getCalendars } from './services'
import { handleError } from '../../../helpers'

export default function CalendarsList() {
    const [calendars, setCalendars] = useState(null)

    useEffect(() => {
        !calendars && getCalendars({ 
            include: 'bookingsCount'
        })
            .then(res => setCalendars(res.data.data))
            .catch(error => handleError(error))
    }, [ calendars ])

    return (
        <React.Fragment>
            <Breadcrumbs 
                title="Ajustes" 
                breadcrumbItem="Calendarios Habilitados para Reservas"
            />
            <Row>
                <Col md="4">
                    <Card>
                        <CardBody>  
                            <NewCalendarForm reload={() => setCalendars(null)} />
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className='mb-0 fs-17'>Calendarios para Reservas</CardTitle>
                            <p className="text-muted mb-4">Listado completo de calendarios habilitados para reservas</p>
                            <CalendarsTable 
                                calendars={calendars}
                                reload={() => setCalendars(null)}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}