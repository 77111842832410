import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import EmailCampaigns from './EmailCampaigns'
import SmsCampaigns from './SmsCampaigns'
import AdsCampaigns from './AdsCampaigns'
import CallCampaigns from './CallCampaigns'
import CallProfile from './CallProfile'
import EmailProfile from './EmailProfile'
import SmsProfile from './SmsProfile'
import EmailCampaignWizard from './EmailCampaignWizard'
import SmsCampaignWizard from './SmsCampaignWizard'
import CallCampaignWizard from './CallCampaignWizard'
import AdsCampaignWizard from './AdsCampaignWizard'

export default function Campaigns() {
	return (
		<MainLayout>
			<Switch>
				<Redirect exact path="/campaigns" to="/campaigns/emails" />

				<Route exact path="/campaigns/emails" component={EmailCampaigns} />
				<Route exact path="/campaigns/emails/new" component={EmailCampaignWizard} />
				<Route exact path="/campaigns/emails/:id" component={EmailProfile} />
				<Route exact path="/campaigns/sms" component={SmsCampaigns} />
				<Route exact path="/campaigns/sms/new" component={SmsCampaignWizard} />
				<Route exact path="/campaigns/sms/:id" component={SmsProfile} />
				<Route exact path="/campaigns/ads" component={AdsCampaigns} />
				<Route exact path="/campaigns/ads/new" component={AdsCampaignWizard} />
				<Route exact path="/campaigns/calls" component={CallCampaigns} />
				<Route exact path="/campaigns/calls/new" component={CallCampaignWizard} />
				<Route exact path="/campaigns/calls/:id" component={CallProfile} />
				
				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}