import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Row, Col, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Divider, Steps, message } from 'antd'

import ConditionsWizard from './partials/ConditionsWizard'
import { createSegment } from './services'
import { handleError } from '../../helpers'

export default function SegmentWizard({segment_id}) {
	const { handleSubmit, register, errors } = useForm()
	const [segmentId, setSegmentId] = useState(segment_id)

	const onSubmit = values => {
		if(values.scope === '') delete values.scope
		values.scope_options = 'true'
		createSegment(values)
			.then(async res => {
				setSegmentId(res.data.data.id)
				message.success('Segmento creado exitosamente')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>					
					<CardTitle>Configurar Segmento</CardTitle>
					<Divider className="mt-2" />					
					<Row>
						<Col md="8" className="px-4">
							<div className="mb-4 p-2 px-5">
								<Steps size="small" current={!segmentId ? 0 : 1}>
									<Steps.Step title="Datos Generales" />
									<Steps.Step title="Condicionales" />
									<Steps.Step title="Finalizar" />
								</Steps>
							</div>
							{ !segmentId ? (
								<Form onSubmit={handleSubmit(onSubmit)}>
									<Row>
										<Form.Group as={Col}>
											<Form.Label>Nombre del Segmento</Form.Label>
											<Form.Control
												name="name"
												ref={register({ required: true })}
											/>
											{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
											<Form.Label className="mt-3">Contexto del Segmento</Form.Label>
											<Form.Control
												as="select"
												name="scope"
												ref={register({})}
											>
												<option value="">Todos los Contactos</option>
												<option value="clients">Todos los Clientes</option>
												<option value="prospects">Todos los Prospectos</option>
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>Descripción Corta</Form.Label>
											<Form.Control
												as="textarea"
												name="description"
												rows={5}
												ref={register({ required: true })}
											/>
											{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
										</Form.Group>
									</Row>									
									<div className="clearfix mt-4">
										<div className="float-right">
											<Button color="primary">Crear Segmento y Continuar</Button>
										</div>
										<Link to="/segments">
											<Button color="warning" outline>Cancelar</Button>
										</Link>
									</div>
								</Form>
							) : (
								<React.Fragment>
									<ConditionsWizard segmentId={segmentId} />
									<div className="clearfix mt-4">
										<div className="float-right">
											<Link to={`/segments/${segmentId}`}>
												<Button color="primary">Finalizar y Ver Segmento</Button>
											</Link>
										</div>
									</div>
								</React.Fragment>
							)}
						</Col>
						<Col className="border-left-divider py-2 px-4">							
							<h5>Paso a Paso</h5>
							<p>Para crear un segmento personalizado siga las siguientes recomendaciones:</p>
							<ul className="text-info font-size-13">
								<li className="mb-3">Eliga un nombre claro y descriptivo</li>
								<li className="mb-3">Para cada condición puede tener múltiples valores separandolos por coma (,).</li>
								<li>Cuando agregue varias condiciones significa que el segmento debe cumplir con todas ellas.</li>
							</ul>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}