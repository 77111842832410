import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Table, Empty } from 'antd'
import { Card, CardBody, Badge } from 'reactstrap'
import moment from 'moment'

import { setQuery, updatePagination } from '../actions'
import TableActions from '../../../components/TableActions'
import Pagination from '../../../components/Pagination'
import { deleteDeal } from '../services'
import { handleError } from '../../../helpers'

function DealsTable({ deals, stateProps, reload, history }) {
   const dispatch = useDispatch()

   const handleDelete = id => {
      deleteDeal(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      dispatch(setQuery({ sort: newSort }))
      reload()
   };

   const columns = [
      {
         title: 'Referencia',
         dataIndex: 'reference',
         sorter: true,
         render: (text, record) => (
            <Link to={`/deals/${record.id}`}>{text}</Link>
         ),
         width: 190,
         fixed: 'left',
      },
      {
         title: 'Estado',
         dataIndex: 'state',
         width: 100,
         render: text => {
            switch (text) {
               case 'draft': return <Badge color="soft-dark">Sugerido</Badge>         
               case 'active': return <Badge color="soft-info">Activo</Badge>         
               case 'expired': return <Badge color="soft-dark">Inactivo</Badge>         
               case 'won': return <Badge color="soft-success">Ganado</Badge>
               case 'lost': return <Badge color="soft-danger">Perdido</Badge>         
               default: return null
            }
         },
      },
      {
         title: 'Etapa',
         dataIndex: 'stage',
         width: 120,
         render: text => text.name
      },
      {
         title: 'Contacto',
         dataIndex: 'contact',
         width: 190,
         render: text => text.fullname
      },
      {
         title: 'Valor Total',
         dataIndex: 'total_value',
         width: 140,
         render: text => `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      },
      {
         title: 'Creada el',
         dataIndex: 'created_at',
         sorter: true,
         width: 160,
         render: text => moment(text).format('Y-MM-DD HH:mm')
      },
      {
         title: 'Acciones',
         width: 120,
         render: (txt, record) => (
            <TableActions 
               handleEdit={()=> history.push(`/deals/${record.id}`)}
               handleDelete={() => handleDelete(record.id)}
            />
         )
      }
   ]

   return (
      <Card>
         <CardBody className="p-3">
            <div className="table-responsive">
               <Table 
                  columns={columns}
                  dataSource={deals}
                  loading={!deals && true}
                  showSorterTooltip={false}                  
                  rowKey={record => record.id}
                  pagination={false}
                  onChange={handleTableChange}
                  scroll={{ x: 'scroll', scrollToFirstRowOnChange: true }}
                  locale={{ emptyText: <Empty description="No se encontraron negocios" className="mt-4" /> }}
               />
               <Pagination stateProps={stateProps} reload={reload} updatePagination={updatePagination} />
            </div>
         </CardBody>
      </Card>
   )
}

export default withRouter(DealsTable)