import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateStage } from '../services'
import { handleError } from '../../../../helpers'

export default function EditStage({ stage, setShow, reload }) {
   const { handleSubmit, register, errors } = useForm()   

   const onSubmit = values => {
      updateStage(stage.id, { ...stage, ...values })
         .then(() => {
            setShow(null)
            reload()
         })
         .catch(error => handleError(error))
   }

   if(!stage) return null;

   return (               
      <Modal isOpen={stage && true} toggle={() => setShow(null)}>
         <ModalHeader toggle={() => setShow(null)}>Actualizar Etapa</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Row>
                  <Col sm="4" className="pr-0">
                     <Form.Group>
                        <Form.Label>Orden <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                           name="order"
                           ref={register({ required: true })}
                           defaultValue={stage.order}
                        />
                        { errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group>
                        <Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                           name="name"
                           ref={register({ required: true })}
                           defaultValue={stage.name}
                        />
                        { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
               </Row>
               <Button color="primary">Actualizar Etapa</Button>
            </Form>
         </ModalBody>
      </Modal>      
   )
}