import React from 'react'
import { Badge } from 'reactstrap'
import { Descriptions, Empty, Table } from 'antd'
import moment from 'moment'

import Pagination2 from '../../../components/Pagination2'

import { Link } from 'react-router-dom'

export default function QuotesTable({ quotes, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Consecutivo',
			dataIndex: 'sku',
            render: (t, r) => (
                <Link to={`/quotes/${r.id}`}>#{t}</Link>
            )
		},
		{
			title: 'Cliente',
			dataIndex: 'deal',
			render: r => r.contact.fullname
		},
		{
			title: 'Referencia',
			dataIndex: 'custom_title',
            render: (t, r) => t ? t : `Propuesta Comercial #${r.sku}`
		},
		{
			title: 'Fecha de Vencimiento',
			dataIndex: 'expired_at',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Fecha de Creación',
			dataIndex: 'created_at',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Descriptions size='small'>
				<Descriptions.Item label="Consecutivo Interno">{r.id}</Descriptions.Item>
				<Descriptions.Item label="Fecha Publicación">{r.published_at ? r.published_at : <em>Sin registro</em>}</Descriptions.Item>
				<Descriptions.Item label="Asesor Asignado">{r.deal.contact.user.name}</Descriptions.Item>
			</Descriptions>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={quotes}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!quotes}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination2
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}