import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Row, Col, Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import moment from 'moment'
import { message } from 'antd';

import { createActivity } from '../../containers/Activities/services';
import { getUsers } from '../../containers/Settings/Users/services'
import { handleError } from '../../helpers'

export default function ActivityForm({ resourceType, resourceId, type, reload }){
   const user_id = useSelector(state => state.auth.user_id)
   const { handleSubmit, register, errors, reset } = useForm()
   const [showExtraFields, setShowExtraFields] = useState(false)
   const [loading, setLoading] = useState(false)
   const [users, setUsers] = useState(null)

   !users && showExtraFields && getUsers({})
      .then(response => setUsers(response.data.data))
      .catch(error => handleError(error))

   const onSubmit = values => {
      setLoading(true)
      values.actionable_type = resourceType
      values.actionable_id = resourceId
      values.type = type
      if(!values.user_id) values.user_id = user_id
      createActivity(values)
         .then(() => {
            message.success('Actividad creada exitosamente')
            reload()
            reset()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Form onSubmit={handleSubmit(onSubmit)}>         
         <Row>
            <Col md="5">
               <Form.Group>
                  <Form.Label>Referencia</Form.Label>
                  <Form.Control
                     name="reference"
                     placeholder="Escriba una referencia"
                     ref={register({ required: true })}
                  />
                  { errors.reference && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
            </Col>
            <Col md="4">
               <Form.Group>
                  <Form.Label>Vence el</Form.Label>
                  <Form.Control
                     name="due_at"
                     ref={register({ required: true })}
                     type="datetime-local"
                     defaultValue={moment().format('YYYY-MM-DDTHH:mm')}
                  />
                  { errors.due_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
            </Col>
            <Col md="3">
               <Form.Group>
                  <Form.Label>Duración</Form.Label>
                  <InputGroup>
                     <Form.Control 
                        name="duration_in_minutes"
                        ref={register({required: true})}
                        type="number"
                        defaultValue={10}
                     />
                     <InputGroup.Append>
                        <InputGroup.Text>Minutos</InputGroup.Text>
                     </InputGroup.Append>
                  </InputGroup>
                  { errors.duration_in_minutes && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
            </Col>
         </Row>
         { showExtraFields && (
            <Row>
               <Col md="5">
                  <Form.Group>
                     <Form.Label>Descripción</Form.Label>
                     <Form.Control
                        name="description"
                        as="textarea"
                        placeholder="Escribe detalles o comentarios"
                        ref={register({})}
                     />
                  </Form.Group>
               </Col>
               <Col md="3">
                  <Form.Group>
                     <Form.Label>Estado</Form.Label>
                     <Form.Control
                        as="select"
                        name="state"
                        ref={register({})}
                        defaultValue="active"
                     >
                        <option value="active">Activa</option>
                        <option value="draft">Sugerida</option>
                        <option value="completed">Completada</option>
                        <option value="canceled">Cancelada</option>
                     </Form.Control>
                  </Form.Group>
               </Col>
               {users && (               
                  <Col md="4">
                     <Form.Group>
                        <Form.Label>Usuario Asignado</Form.Label>
                        <Form.Control
                           name="user_id"                        
                           ref={register({})}
                           as="select"
                           defaultValue={user_id}
                        >
                           { users.map( user => <option key={user.id} value={user.id}>{user.name}</option>) }
                        </Form.Control>
                     </Form.Group>
                  </Col>
               )}
            </Row>
         )}
         <Row>
            <Col>
               <Button color="primary" disabled={loading}>
                  Crear{" "}
                  { type === 'call' && 'Llamada' }
                  { type === 'meeting' && 'Cita' }
                  { type === 'task' && 'Tarea' }
                  { type === 'reminder' && 'Recordatorio' }
               </Button>
            </Col>
            <Col className="text-right pt-2">
               <p className="text-info mb-0 text-link" onClick={()=>setShowExtraFields(!showExtraFields)}>
                  {showExtraFields ? 'Ocultar' : 'Ver'} Campos Adicionales
               </p>
            </Col>
         </Row>
      </Form>
   )
}