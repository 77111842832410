import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'

import SearchEntity from '../../../components/SearchEntity'

import { showNewModal } from '../actions'
import { createDeal } from '../services'
import { getPipelines } from '../../Settings/Pipelines/services'
import { handleError } from '../../../helpers'

function NewDealModal({ history, pipelines, contactId }) {
   const dispatch = useDispatch()
   const show = useSelector(state => state.deals_props.showNewModal)
   const [loading, setLoading] = useState(false)
   const [pipes, setPipes] = useState(pipelines)
   const [resourceId, setResourceId] = useState(contactId)
   const [pipelineStages, setPipelineStages] = useState(null)
   const [showProductsModal, setShowProductsModal] = useState(true)

   const { handleSubmit, register, errors } = useForm()

   const onSubmit = values => {
      setLoading(true)
      values.contact_id = resourceId
      createDeal(values)
         .then(response => {
            dispatch(showNewModal(false))
            history.push(`/deals/${response.data.data.id}?showProductsModal=${showProductsModal}`)
         })
         .catch(error => {
            handleError(error)
            setLoading(false)
         })
   }

   if(!pipelines && !pipes){
      getPipelines({ include: 'stages' })
         .then(response => setPipes(response.data.data))
         .catch(error => handleError(error))
   }

   if(!pipes) return null

   return (
      <Modal isOpen={show} toggle={()=>dispatch(showNewModal(false))}>
         <ModalHeader toggle={() => dispatch(showNewModal(false))}>Crear Negocio Nuevo</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               { !contactId && (
                  <Form.Group>
                     <Form.Label>Contacto Asociado <span className="text-danger">*</span></Form.Label>
                     <SearchEntity
                        resourceType="contacts"
                        style={{ width: '100%' }}
                        setResourceId={setResourceId}
                     />
                  </Form.Group>
               )}
               <Form.Group>
                  <Form.Label>Referencia <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="reference"
                     ref={register({ required: true })}
                     placeholder="Escriba aquí el nombre"
                  />
                  { errors.reference && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Row>
                  <Form.Group as={Col}>
                     <Form.Label>Embudo <span className="text-danger">*</span></Form.Label>
                     <Form.Control
                        as="select"
                        onChange={e => {
                           let selectedPipe = pipes.filter(p => p.id === parseInt(e.target.value))
                           setPipelineStages(selectedPipe[0].stages)
                        }}
                     >
                        <option value="">:: Eliga un embudo ::</option>
                        { pipes && pipes.map(pipe => <option key={pipe.id} value={pipe.id}>{pipe.name}</option>) }
                     </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                     <Form.Label>Etapa <span className="text-danger">*</span></Form.Label>
                     <Form.Control
                        name="stage_id"
                        as="select"
                        ref={register({ required: true })}
                        disabled={pipelineStages === null}
                     >
                        <option value="">:: Eliga una etapa ::</option>
                        { pipelineStages && pipelineStages.map(pStage => <option key={pStage.id} value={pStage.id}>{pStage.name}</option>)}
                     </Form.Control>
                     { !pipelineStages && <Form.Text className="text-warning">Seleccione un embudo con etapas</Form.Text> }
                     { errors.stage_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
               </Row>
               <Form.Group>
                  <Form.Check 
                     type="checkbox" 
                     checked={showProductsModal} 
                     onClick={() => setShowProductsModal(!showProductsModal)}
                     label="Agregar productos al crear"
                  /> 
               </Form.Group>
               <Button color="primary" disabled={loading}>Crear Negocio Nuevo</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}

export default withRouter(NewDealModal)