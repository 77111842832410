import React, {useState} from 'react'

import TagManager from './partials/TagManager'

import { handleError } from '../helpers'

export default function ResourceTags({ resourceId, getService, attachService, detachService }) {
   const [tags, setTags] = useState(null)   

   !tags && getService(resourceId)
      .then(response => setTags(response.data.data))
      .catch(error => handleError(error))

   const handleAttach = values => {
      attachService(resourceId, values)
         .then(response => setTags(response.data.data))
         .catch(error => handleError(error))      
   }

   const handleDetach = (id, name) => {
      detachService(resourceId, id, { tag: name })
         .then(response => setTags(response.data.data))
         .catch(error => handleError(error))   
   }

   return (
      <TagManager 
         tags={tags}
         handleAttach={handleAttach}
         handleDetach={handleDetach}
      />
   )
}