import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'

import { setQuery } from '../actions'

export default function DealsFilters({ reload }) {
   const { handleSubmit, register, reset } = useForm()
   const filters = useSelector(state => state.deals_props.query.filters)
   const dispatch = useDispatch()

   const setFilters = newFilters => {
      if(newFilters.total_value){
         newFilters = {
            ...newFilters,
            total_value: newFilters.total_value+':'+newFilters.total_value_operator
         }
      }
      delete newFilters.total_value_operator
      dispatch(setQuery({ filters: newFilters }))
      reload()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Búsqueda Avanzada</CardTitle>
            <p className="text-muted mb-4">Filtros Múltiples</p>
            <Form onSubmit={handleSubmit(values => setFilters(values))}>
               <Form.Group>
                  <Form.Control
                     name="reference"
                     placeholder="Filtrar por referencia"
                     defaultValue={filters && filters.reference}
                     ref={register({})}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     name="state"
                     as="select"
                     defaultValue={filters && filters.state}
                     ref={register({})}
                  >
                     <option value="">::Todos los estados::</option>
                     <option value="draft">Sugerido</option>
                     <option value="active">Activo</option>
                     <option value="expired">Expirado</option>
                     <option value="won">Ganado</option>
                     <option value="lost">Perdido</option>
                  </Form.Control>
               </Form.Group>
               <Form.Group>
                  <InputGroup>
                     <InputGroup.Prepend>
                        <Form.Control
                           as="select"
                           name="total_value_operator"
                           ref={register({})}
                        >
                           <option value=">">Mayor que</option>
                           <option value="<">Menor que</option>
                           <option value="=">Igual que</option>
                        </Form.Control>
                     </InputGroup.Prepend>
                     <Form.Control 
                        type="number"
                        name="total_value"
                        placeholder="Valor total"
                        ref={register({})}
                     />
                  </InputGroup>
               </Form.Group>
               <Button color="primary">Realizar Búsqueda</Button>
               <div className="mt-2">
                  <Button 
                     color="warning" 
                     outline 
                     size="sm" 
                     onClick={() => {
                        setFilters({})
                        reset()
                     }}
                  >
                     Borrar todos
                  </Button>
               </div>
            </Form>
         </CardBody>
      </Card>
   )
}