import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { InputGroup, InputGroupAddon, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import {createPipeline} from '../services'
import {handleError} from '../../../../helpers'

export default function NewPipeline({ reload }) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors, reset } = useForm()

   const onSubmit = values => {
      setLoading(true)
      createPipeline(values)
         .then(() => {
            reload()
            reset()
         })
         .catch(error => handleError(error))
         .finally(()=>setLoading(false))
   }

   return (
      <FormContainer>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>                     
               <Form.Control
                  name="name"
                  placeholder="Nombre del embudo nuevo"
                  ref={register({ required: true })}
               />
               <InputGroupAddon addonType="append">
                  <Button color="primary" disabled={loading}>Crear Embudo Nuevo</Button>
               </InputGroupAddon>
            </InputGroup>
            { errors.name && <Form.Text className="text-light">Debe ingresar un nombre.</Form.Text> }
         </Form>
      </FormContainer>
   )
}

const FormContainer = styled.div`
   background-color: #f6f7ff;
   padding: 20px;
   margin-top: 20px;
`