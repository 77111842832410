import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { Divider } from 'antd'

import ListTopBar from './partials/ListTopBar'

export default function EmailCampaigns() {
	return (
		<React.Fragment>
			<ListTopBar 
				title="Campañas de Correo Electrónico" 
				ctaText="Crear Campaña de Email"
				ctaLink="/campaigns/emails/new"
			/>
			<Row>
				<Col lg="12">
					<div className=" pb-5">
						<div className="table-responsive pb-5">
							<Table className="project-list-table table-nowrap table-centered table-borderless pb-5">
								<thead>
									<tr>
										<th scope="col">Nombre de la Campaña</th>
										<th scope="col">Enviado</th>
										<th scope="col">Aperturas</th>
										<th scope="col">Clicks</th>
										<th scope="col">Estado</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="text-truncate font-size-14">
												<Link to={`/campaigns/emails/1`} className="text-primary">WH-2020OCT21EMAIL01</Link>
											</h5>
											<p className="text-muted mb-0">Enviado el: 13 Noviembre 2020 11:00</p>
										</td>
										<td>
											<h6 className="mb-1">4,000 enviados</h6>
											<span className="text-muted">4,303 destinatarios</span>
										</td>
										<td>
											<h6 className="mb-1">1,000 abiertos</h6>
											<span className="text-muted">25% Tasa apertura</span>
										</td>
										<td>
											<h6 className="mb-1">100 clicks</h6>
											<span className="text-muted">10% Tasa clicks</span>
										</td>
										<td><span className="badge badge-primary">Enviada</span></td>
										<td>											
											<UncontrolledDropdown>
												<Link to={`/campaigns/emails/1`}>
													<Button color="dark" size="sm" className="mr-3" outline>Ver Campaña</Button>
												</Link>
												<DropdownToggle href="#" className="card-drop" tag="i">
													<i className="mdi mdi-dots-horizontal font-size-18"></i>
												</DropdownToggle>
												<DropdownMenu right >
														<DropdownItem href="#">Vista Previa</DropdownItem>
														<DropdownItem href="#">Enviar Prueba</DropdownItem>
														<Divider className="my-2" />
														<DropdownItem href="#" disabled>Editar Campaña</DropdownItem>
														<DropdownItem href="#">Eliminar</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
}