import React from "react"
import {useSelector} from "react-redux"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Switch, Route, Redirect } from "react-router-dom"

import "antd/dist/antd.css";
import "./assets/scss/theme.scss"
import "./assets/css/main.css"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Contacts from './containers/Contacts/'
import Companies from './containers/Companies/'
import Deals from './containers/Deals/'
import Products from './containers/Products/'
import Activities from './containers/Activities/'
import Settings from './containers/Settings/'
import Segments from "./containers/Segments/"
import Reports from "./containers/Reports"
import Campaigns from "./containers/Campaigns"
import EmailLists from "./containers/EmailLists"
import Templates from "./containers/Templates"
import Search from "./containers/Search"
import Quotes from "./containers/Quotes"
import Bookings from "./containers/Bookings"
import Public from "./containers/Public"

Bugsnag.start({
	apiKey: 'b6f948001a7f4f435d50b362a30b0659',
	plugins: [new BugsnagPluginReact()],
	appVersion: process.env.REACT_APP_VERSION
})

const ErrorBoundary = Bugsnag.getPlugin('react')
	.createErrorBoundary(React)	

export default function App() {	
	const auth = useSelector(state => state.auth)
	const token = useSelector(state => state.auth.token)

	token && Bugsnag.setUser(auth.user_id, auth.user_email, auth.user_name)
	
	return (
		<ErrorBoundary>
			<Switch>
				<Redirect exact path="/" to="/contacts" />

				<Route path="/p" component={Public} />
				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}				
				
				<Route path="/contacts" component={Contacts} />
				<Route path="/companies" component={Companies} />
				<Route path="/deals" component={Deals} />
				<Route path="/products" component={Products} />	
				<Route path="/activities" component={Activities} />
				<Route path="/segments" component={Segments} />
				<Route path="/settings" component={Settings} />
				<Route path="/reports" component={Reports} />
				<Route path="/campaigns" component={Campaigns} />
				<Route path="/email_lists" component={EmailLists} />
				<Route path="/templates" component={Templates} />
				<Route path="/search" component={Search} />
				<Route path="/quotes" component={Quotes} />
				<Route path="/bookings" component={Bookings} />

				<Route component={Error404} />
			</Switch>
		</ErrorBoundary>
	);	
}