export const UPDATE_PAGINATION = 'deals_props/UPDATE_PAGINATION'
export const SET_QUERY = 'deals_props/SET_QUERY'
export const SHOW_NEW_MODAL = 'deals_props/SHOW_NEW_MODAL'
export const SET_LIST_VIEW = 'deal_props/SET_LIST_VIEW'

export const updatePagination = data => ({
   type: UPDATE_PAGINATION,
   payload: data
})

export const setQuery = data => ({
   type: SET_QUERY,
   payload: data
})

export const showNewModal = show => ({
   type: SHOW_NEW_MODAL,
   payload: show
})

export const setListView = view => ({
   type: SET_LIST_VIEW,
   payload: view
})