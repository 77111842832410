import React, {useState, useEffect} from 'react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'

import ReportFilters from './partials/ReportFilters'
import StatCard from '../../components/StatCard'
import ProductSales from './partials/ProductsSales'
import ReportTopBar from './partials/ReportTopBar'
import { getUsers } from '../Settings/Users/services'
import { getStores } from '../Settings/Stores/services'
import { generalReport } from './services'
import { handleError } from '../../helpers'
import BudgetWidget from './partials/BudgetWidget'

export default function GeneralReport() {
	const [fromDate, setFromDate] = useState(moment().startOf('month'))
	const [toDate, setToDate] = useState(moment().endOf('month'))
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedStores, setSelectedStores] = useState([])
	const [showFilters, setShowFilters] = useState(true)
	const [users, setUsers] = useState(null)
	const [stores, setStores] = useState(null)
	const [report, setReport] = useState(null)
	const [currentFrom, setCurrentFrom] = useState(fromDate)
	const [currentTo, setCurrentTo] = useState(toDate)

	useEffect(() => {
		!users && getUsers()
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users])

	useEffect(() => {
		!stores && getStores()
			.then(res => setStores(res.data.data))
			.catch(error => handleError(error))
	}, [stores])

	useEffect(()=>{
		!report && generalReport({
			from: moment(fromDate).format('YYYY-MM-DD'),
			to: moment(toDate).format('YYYY-MM-DD'),
			users_ids: JSON.stringify(selectedUsers),
			stores_ids: JSON.stringify(selectedStores),
		})
			.then(res => setReport(res.data))
			.catch(error => handleError(error))
	}, [report, fromDate, toDate, selectedUsers, selectedStores])

	const handleSubmit = () => {
		setCurrentFrom(fromDate)
		setCurrentTo(toDate)
		setReport(null)
	}

	return (
		<React.Fragment>
			<ReportTopBar 
				title="Reporte General"
				from={currentFrom}
				to={currentTo}
				showFilters={showFilters}
				setShowFilters={setShowFilters}
				reload={() => setReport(null)}
			/>
			<Row>
				{showFilters && (
					<Col md="3" sm="4">
						<ReportFilters 
							users={users}
							stores={stores}
							fromDate={fromDate}
							setFromDate={setFromDate}
							toDate={toDate}
							setToDate={setToDate}
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
							selectedStores={selectedStores}
							setSelectedStores={setSelectedStores}
							handleSubmit={handleSubmit}
						/>
					</Col>
				)}					
				<Col>
					<Row>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Nuevos Contactos" 
								iconClass="fa fa-user-plus"
								text={report && report.contacts_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Correos Nuevos" 
								iconClass="fa fa-envelope"
								text={report && (
									<React.Fragment>
										{report.emails_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
										{ report.contacts_count ? (
											<span className="small text-muted">
												{round(report.emails_count/report.contacts_count)*100}%
											</span>
										) : ''}
									</React.Fragment>
								)} 
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Clientes" 
								iconClass="fa fa-users"
								text={report && report.clients_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Ventas/Cliente" 
								iconClass="fa fa-user-times"
								text={report && report.clients_count > 0 ? round(report.sales_count/report.clients_count) : '0'}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Negocios Abiertos" 
								iconClass="fa fa-briefcase" 
								text={report && report.deals_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Ventas Cerradas" 
								iconClass="fa fa-dollar-sign" 
								text={report && report.sales_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Valor de Ventas" 
								iconClass="fa fa-money-bill" 
								text={report && '$'+report.sales_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm="8">
							<ProductSales
								products={report && report.products}
								salesCount={report && report.sales_count}
								salesTotal={report && report.sales_total}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Ticket Promedio" 
								iconClass="fa fa-money-bill-alt" 
								text={report && report.sales_count > 0 ? '$'+round(report.sales_total/report.sales_count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$0'}
							/>
							<StatCard 
								loading={report ? false : true} 
								title="Unidades Vendidas" 
								iconClass="fa fa-boxes"
								text={report && (
									<React.Fragment>
										{report.sales_products_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										<span className="text-muted small font-size-12 ml-2">
											{report.sales_count > 0 ? round(report.sales_products_count/report.sales_count) : '0'} por venta
										</span>
									</React.Fragment>
								)}
							/>
							<StatCard 
								loading={report ? false : true} 
								title="Productos Únicos Vendidos" 
								iconClass="fa fa-box-open" 
								text={report && (
									<React.Fragment>
										{report.sales_products_references_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										<span className="text-muted small font-size-12 ml-2">
											{report.sales_count > 0 ? round(report.sales_products_references_count/report.sales_count) : '0'} por venta
										</span>
									</React.Fragment>
								)}
							/>
							<BudgetWidget 
								salesTotal={report && report.sales_total}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const round = number => {
	return Math.round((number + Number.EPSILON) * 100) / 100
}