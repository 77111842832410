import React, {useState} from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Table} from 'antd'

import EditCustomField from './EditCustomField'
import { handleError } from '../../../helpers'

import {deleteCustomField} from './services'
import TableActions from '../../../components/TableActions'

export default function CustomFieldList({ customFields, reload }) {
   const [editCustomField, setEditCustomField] = useState(null)

   const handleDelete = id => {
      deleteCustomField(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         width: 50
      },
      {
         title: 'Nombre',
         dataIndex: 'label',
      },
      {
         title: 'Slug',
         dataIndex: 'slug'
      },
      {
         title: 'Descripción',
         dataIndex: 'description',
         ellipsis: true,
      },
      {
         title: 'Tipo',
         dataIndex: 'type',
         render: text => {
            switch (text) {
               case 'integer': return 'Númerico'      
               case 'string': return 'Texto'
               case 'date': return 'Fecha'
               case 'json': return 'Opción Multiple'
               default: return 'Sin Específicar'
            }
         }
      },
      {
         title: 'Acciones',
         render: (text, record) => (
            <TableActions
               handleEdit={() => setEditCustomField(record)}
               handleDelete={() => handleDelete(record.id)}
            />
         )
      }
   ]

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>Listado de Campos Personalizados</CardTitle>
               <p className="text-muted mb-4">Administre los campos personalizados para el módulo seleccionado.</p>            
               <Table 
                  columns={columns}
                  dataSource={customFields}
                  loading={!customFields && true}
                  showSorterTooltip={false}
                  rowKey={record => record.id}
                  size="middle"
               />
            </CardBody>
         </Card>
         <EditCustomField customField={editCustomField} setShow={setEditCustomField} reload={()=>reload()} />
      </React.Fragment>
   )
}

