import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumb';
import NewStoreCard from './partials/NewStoreCard';
import StoresTable from './partials/StoresTable';
import { getStores } from './services';
import { handleError } from '../../../helpers';

export default function Stores() {
	const [stores, setStores] = useState(null)

	useEffect(()=>{
		!stores && getStores()
			.then(res => setStores(res.data.data))
			.catch(error => handleError(error))
	}, [stores])

	return (
		<React.Fragment>
         <Breadcrumbs title="Ajustes" breadcrumbItem="Administración de Sedes" />
         <Row>
            <Col md="4">
					<NewStoreCard reload={()=>setStores(null)} />
            </Col>
            <Col md="8">
					<StoresTable stores={stores} reload={()=>setStores(null)} />
            </Col>
         </Row>
      </React.Fragment>
	)
}