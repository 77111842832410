import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Badge } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Divider, Popconfirm, message } from 'antd'
import moment from 'moment'

import AttachProductModal from '../../Deals/partials/AttachProductModal'

import { deleteQuote, updateQuote } from '../services'
import { getClientUri } from '../../../services/s4salesapi'
import { handleError } from '../../../helpers'

export default function QuoteProfileTopBar({ quote, reload }) {
    const history = useHistory()
    const [showProductsModal, setShowProductsModal] = useState(false)

    const handleUpdate = values => {
        updateQuote(quote.id, values)
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error))
    }

    const handleDelete = () => {
        deleteQuote(quote.id)
            .then(res => {
                message.success(res.data.message)
                history.push(`/deals/${quote.deal_id}`)
            })
            .catch(error => handleError(error))
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${getClientUri()}/p/cotizaciones/${quote.id}`)
        message.success('Link clientes copiado exitosamente')
    }

    if(!quote) 
        return <Spinner animation='border' size='sm' className='mb-3' />

    return (
        <React.Fragment>
            <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">
                    <Link to={`/deals/${quote.deal_id}`}>
                        <FontAwesomeIcon icon={faArrowLeft} className='mr-3' />
                    </Link>
                    <span className="hide-sm">Cotización #{quote.sku}</span>
                    <br />
                    { quote.status && (
                        <div className='ml-4'>
                            { quote.status.key === 'draft' && <Badge color="warning">Cotización Sin Publicar</Badge> }
                            { quote.status.key === 'expired' && <Badge color="dark">Cotización Vencida</Badge> }
                            { quote.status.key === 'publish' && <Badge color="success">Cotización Publicada</Badge> }
                            { quote.status.key === 'voided' && <Badge color="danger">Cotización Anulada</Badge> }
                        </div>
                    )}
                </h4>
                <div className="page-title-right">
                    <Popconfirm
                        title="¿Está acción no puede revertirse?"
                        onConfirm={()=> handleDelete()}
                        okText="Eliminar"
                        cancelText="Cancelar"
                        placement="bottomRight"
                    >
                        <Button color="danger" outline>
                            <i className="fa fa-trash mr-2"></i>
                            Eliminar Cotización
                        </Button>
                    </Popconfirm>
                    <Divider type='vertical' />
                    { quote.status.key !== 'voided' && (
                        <Popconfirm
                            title="¿Está seguro de anular?"
                            onConfirm={()=> handleUpdate({ voided_at: moment().format('YYYY-MM-DD HH:mm:ss') })}
                            okText="Anular"
                            cancelText="Cancelar"
                            placement="bottomRight"
                            >
                            <Button color="danger" outline>
                                <i className="fa fa-times mr-2"></i>
                                Anular Cotización
                            </Button>
                        </Popconfirm>
                    )}
                    { quote.status.key === 'draft' && (
                        <React.Fragment>
                            <Divider type='vertical' />
                            <Popconfirm
                                title="Publicar Cotización"
                                onConfirm={()=> handleUpdate({ published_at: moment().format('YYYY-MM-DD HH:mm:ss') })}
                                okText="Publicar"
                                cancelText="Cancelar"
                                placement="bottomRight"
                                >
                                <Button color="success">
                                    Publicar Cotización
                                </Button>
                            </Popconfirm>
                        </React.Fragment>
                    )}
                    { quote.status.key === 'publish' && (
                        <Button color='info' onClick={handleCopyLink} className='ml-2' outline>
                            Copiar Link Clientes
                        </Button>
                    )}
                    <Button color="info" outline className='ml-2' onClick={() => setShowProductsModal(true)}>
                        Agregar o Editar Producto
                    </Button>
                    { showProductsModal && (
                        <AttachProductModal
                            visible
                            onClose={() => setShowProductsModal(false)}
                            dealId={quote.deal_id}
                            reload={reload} 
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}