import React from 'react'
import { Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { Table } from 'antd'

export default function ListSubscribers() {

	const columns = [
		{
			title: 'Email'
		},
		{
			title: 'Etiquetas'
		},
		{
			title: 'Suscrito el'
		},
		{
			title: 'Acciones'
		}
	]

	return (
		<React.Fragment>
			<div className="mb-3">
				<Button color="primary">Agregar Suscriptor</Button>
				<div className="float-right">
					<Form className="d-inline-block hide-sm" onSubmit>
                  <InputGroup className="mb-0">
                     <Form.Control
                        name="reference"
                        placeholder="Buscar suscriptor..."
                     />
                     <InputGroup.Append>
                        <Button color="primary">
                           <i className="fa fa-search"></i>
                        </Button>
                     </InputGroup.Append>
                  </InputGroup>
               </Form>
				</div>
			</div>
			<Table
				dataSource={[]}
				columns={columns}
			/>
		</React.Fragment>
	)
}