import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Select } from 'antd';

import { updateCustomField } from './services'
import { handleError } from '../../../helpers'

export default function EditCustomField({ customField, setShow, reload }) {   
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors, setValue} = useForm()

   const onSubmit = values => {
      setLoading(true)
      updateCustomField(customField.id, { ...customField, ...values })
         .then(() => {
            setShow(null)
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }


   if(!customField) return null

   return (      
      <Modal isOpen={customField && true} toggle={()=>setShow(null)}>
         <ModalHeader toggle={()=>setShow(null)}>Editar Campo Personalizado</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre del Campo <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="label"
                     ref={register({ required: true })}
                     defaultValue={customField.label}
                  />
                  { errors.label && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Descripción <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="description"
                     ref={register({ required: true })}
                     as="textarea"
                     defaultValue={customField.description}
                  />
                  { errors.description && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               { customField.type === 'json' && (
                  <Form.Group>
                     <Form.Label>Opciones</Form.Label>
                     <input type="hidden" name="options" defaultValue={JSON.stringify(customField.options)} ref={register({ required: true })} />
                     <Select 
                        mode="tags" 
                        style={{ width: '100%' }} 
                        placeholder="Escriba las opciones" 
                        onChange={values => setValue('options', JSON.stringify(values))}
                        defaultValue={customField.options}
                     />
                     { errors.options && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
                  </Form.Group>
               )}
               <Button color="primary" disabled={loading}>Actualizar Campo Personalizado</Button>
            </Form>
         </ModalBody>
      </Modal>      
   )
}