import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'

import BookingsTable from './partials/BookingsTable'
import BookingsCalendar from './partials/BookingsCalendar'
import BookingProfileModal from './partials/BookingProfileModal'
import ListTopBar from '../../components/ListTopBar'

import { handleError, parsePagination } from '../../helpers'
import { getBookings } from './services'
import ExportList from '../../components/ExportList'

export default function BookingsList() {
	const [bookings, setBookings] = useState(null)
	const [filterType, setFilterType] = useState('calendar.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [viewType, setViewType] = useState('calendar')
	const [bookingModal, setBookingModal] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 100, total: 0})
	const [calendarRange, setCalendarRange] = useState([ 
		moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
		moment().endOf('month').add(7, 'days').format('YYYY-MM-DD')
	])

	const params = {
		[`filter[${filterType}]`]: filterValue,
		[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
		include: 'deal.contact.user,calendar.product',		
		sort: '-start_at'
	}

	useEffect(() => {
		!bookings && getBookings({ 
			...pagination, 
			...params,
			'filter[bookingsBetween]': viewType === 'calendar' ? calendarRange.join(',') : '',
		})
			.then(res => {
				setBookings(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ bookings, pagination, params, viewType, calendarRange ])

	const onRangeChange = range => {
		setCalendarRange([
			moment(range.start ? range.start : range[0]).format('YYYY-MM-DD'),
			moment(range.end ? range.end : range.pop()).format('YYYY-MM-DD')
		])
		setBookings(null)
	}

	const onViewTypeChange = type => {
		if(type === 'calendar'){
			setPagination({ ...pagination, per_page: 100 })
			setBookings(null)
		} else {
			setPagination({ ...pagination, per_page: 15 })
			setBookings(null)
		}

		setViewType(type)
	}

	return (
		<React.Fragment>
			<ListTopBar 
				title="Reservaciones Registradas"
				subtitle="Listado de reservas registradas en el CRM"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setBookings(null)}
				viewType={viewType}
				setViewType={onViewTypeChange}
				options={[
					{ label: 'Buscar por Calendario', value: 'calendar.name' },
					{ label: 'Inicio (YYYY-MM-DD)', value: 'start_at' },
					{ label: 'Entrada (YYYY-MM-DD)', value: 'entry_at' },
					{ label: 'Salida (YYYY-MM-DD)', value: 'exit_at' },
					{ label: 'Finalización (YYYY-MM-DD)', value: 'end_at' },
				]}
				scopes={[
					{ label: 'Reservas Pendientes', value: 'pendding' },
					{ label: 'Reservas Aprobadas', value: 'approved' },
					{ label: 'Reservas Finalizadas', value: 'completed' },
					{ label: 'Reservas Vencidas', value: 'expired' },
					{ label: 'Reservas Anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody className='pb-0'>
					{ viewType === 'table' ? (
						<BookingsTable 
							bookings={bookings}
							reload={() => setBookings(null)}
							pagination={pagination}
							setPagination={setPagination}
							setBookingModal={setBookingModal}
						/>
					) : (
						<BookingsCalendar 
							bookings={bookings}
							onRangeChange={onRangeChange}
							reload={() => setBookings(null)}
							setBookingModal={setBookingModal}
						/>
					)}
				</CardBody>
			</Card>
			<ExportList 
				type="bookings" 
				params={params}
			/>
			{ bookingModal && (
				<BookingProfileModal 
					visible
					onClose={() => setBookingModal(null)}
					bookingId={bookingModal}
					reload={() => setBookings(null)}
				/>
			)}
		</React.Fragment>
	)
}