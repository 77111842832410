import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Popconfirm, message } from 'antd'

import { deleteCompany } from '../services'
import { handleError } from '../../../helpers'

const ProfileTopBar = ({ title, companyId, history }) => {   
   const handleDelete = () => {
      deleteCompany(companyId)
         .then(() => {
            message.success('Empresa eliminada exitosamente.')
            history.push('/companies')
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               <span className="hide-sm">Perfil de Empresa</span>{" "}
               {title && ` - ${title}`}
            </h4>
            <div className="page-title-right">
               <Popconfirm
                  title="¿Esta seguro de eliminar?"
                  onConfirm={()=> handleDelete()}
                  okText="Eliminar"
                  cancelText="Cancelar"
                  placement="bottomRight"
               >
                  <Button color="danger" outline>
                     <i className="fa fa-trash mr-2"></i>
                     Eliminar
                  </Button>
               </Popconfirm>
            </div>
         </div>
      </React.Fragment>
   )
}

export default withRouter(ProfileTopBar)