import s4salesapi from '../../../services/s4salesapi'

export const getCategories = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/categories', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getCategory = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/categories/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createCategory = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/categories', data)
            .then(response => resolve(response))
            .catch(error => reject(error))            
   })
)

export const updateCategory = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/categories/${id}`, data) 
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteCategory = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/categories/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)