import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { setQuery } from '../actions'

export default function ActivitiesFilters({ reload }) {
   const filters = useSelector(state => state.activities_props.query.filters)
   const dispatch = useDispatch()
   const initialFilters = { reference: '', type: '', state: 'active'}
   const [form, setForm] = useState({ ...initialFilters, ...filters })

   const setFilters = newFilters => {
      dispatch(setQuery({ filters: newFilters }))
      reload()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Búsqueda Avanzada</CardTitle>
            <p className="text-muted mb-4">Filtros Múltiples</p>
            <Form>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por referencia"
                     value={form.reference}
                     onChange={e => setForm({ ...form, reference: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Tipo de Actividad</Form.Label>
                  <Form.Check inline label="Llamada" type="radio"
                     checked={form.type === 'call'} onChange={() => setForm({ ...form, type: 'call' })} />
                  <Form.Check inline label="Cita" type="radio"
                     checked={form.type === 'meeting'} onChange={() => setForm({ ...form, type: 'meeting' })} />
                  <Form.Check inline label="Tarea" type="radio"
                     checked={form.type === 'task'} onChange={() => setForm({ ...form, type: 'task' })} />
                  <Form.Check inline label="Recordatorio" type="radio"
                     checked={form.type === 'reminder'} onChange={() => setForm({ ...form, type: 'reminder' })} />
                  <Form.Check inline label="Todos" type="radio"
                     checked={form.type === ''} onChange={() => setForm({ ...form, type: '' })} />
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Estado</Form.Label>
                  <Form.Control
                     as="select"
                     value={form.state}
                     onChange={e => setForm({ ...form, state: e.target.value })}
                  >
                     <option value="">:: Ver Todos ::</option>
                     <option value="draft">Sugerida</option>
                     <option value="active">Activa</option>
                     <option value="completed">Completada</option>
                     <option value="canceled">Cancelada</option>
                  </Form.Control>
               </Form.Group>
               <Button color="primary" onClick={() => setFilters(form)}>Realizar Búsqueda</Button>
               <div className="mt-2">
                  <Button 
                     color="warning" 
                     outline 
                     size="sm" 
                     onClick={() => {
                        setFilters({ state: 'active' })
                        setForm(initialFilters)
                     }}
                  >
                     Borrar todos
                  </Button>
               </div>
            </Form>
         </CardBody>
      </Card>
   )
}