import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateUser } from '../services'
import { handleError } from '../../../../helpers'

export default function EditUser({ user, setShow, reload }) {
   const [loading, setLoading] = useState(false)   
   const { handleSubmit, register, errors } = useForm()
   
   const onSubmit = values => {
      setLoading(true)
      updateUser(user.id, values)
         .then(() => {
            reload()
            setShow(null)
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   if(!user) return null

   return (      
      <Modal isOpen={user && true} toggle={() => setShow(null)}>
         <ModalHeader toggle={()=>setShow(null)}>Editar Usuario</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     defaultValue={user.name}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campom es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="email"
                     placeholder="Escribe aquí un correo único"
                     ref={register({ required: true })}
                     defaultValue={user.email}
                  />
                  { errors.email && <Form.Text className="text-warning">Ingrese un correo válido y único.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Documento <small className="text-muted">(Opcional)</small></Form.Label>
                  <Form.Control
                     name="document"
                     placeholder="Escribe aquí un documento único"
                     ref={register({ })}
                     defaultValue={user.document}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label>Teléfono de Contacto <small className="text-muted">(Opcional)</small></Form.Label>
                  <Form.Control
                     name="mobile"
                     placeholder="Escribe aquí un número de contacto"
                     ref={register({ })}
                     defaultValue={user.mobile}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Rol del Usuario <span className="text-danger">*</span></Form.Label>
                  <Form.Check inline label="Super Admin" value="Super Admin" type="radio" name="role" ref={register({required:true})} defaultChecked={user.roles[0].name === 'Super Admin'} />
                  <Form.Check inline label="Admin" value="Admin" type="radio" name="role" ref={register({required:true})} defaultChecked={user.roles[0].name === 'Admin'} />
                  <Form.Check inline label="Asesor" value="Agent" type="radio" name="role" ref={register({required:true})} defaultChecked={user.roles[0].name === 'Agent'} />
                  { errors.role && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Button color="primary" disabled={loading}>Actualizar Usuario</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}