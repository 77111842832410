import React, {useState} from 'react'
import { Card, CardTitle, CardBody, Button, Table, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap'
import SearchEntity from '../../../components/SearchEntity'
import { message } from 'antd'

export default function ContactOwner({ contact, onSave }) {
   const [show, setShow] = useState(false)
   const [newUserId, setNewUserId] = useState(null)

   const handleSubmit = () => {      
      if(newUserId){
         onSave('user_id', newUserId)
         setShow(false)
      } else {
         message.warning('Debe elegir una asesor primero.')
      }
   }

   return (
      <React.Fragment>
         <Card>
            <CardBody className="pb-2">
               <CardTitle className="mb-2">Asesor Asignado</CardTitle>
               { contact ? (
                  <React.Fragment>
                     { contact.user ? (
                        <Table className="table table-centered table-nowrap mb-0">
                           <tbody>
                              <tr>
                                 <td style={{ width: "30px" }} className="px-0">
                                    <div className="avatar-xs">
                                       <span className="avatar-title rounded-circle bg-soft-info text-info font-size-16">
                                          <i className="fa fa-user"></i>
                                       </span>
                                    </div>
                                 </td>
                                 <td>
                                    <h5 className="font-size-14 m-0">
                                       <span className="text-dark">{contact && contact.user.name}</span>
                                    </h5>
                                 </td>
                                 <td className="text-right">
                                    <Button color="warning" outline size="sm" onClick={() => setShow(true)}>Cambiar</Button>
                                 </td>
                              </tr>
                           </tbody>
                        </Table>
                     ) : (
                        <div className="text-center">
                           <p className="mt-3"><em>Sin asesor asignado</em></p>                           
                        </div>
                     )}
                  </React.Fragment>
               ) : <Spinner size="sm" />}
            </CardBody>
         </Card>
         <Modal isOpen={show} toggle={()=>setShow(false)}>
            <ModalHeader toggle={()=>setShow(false)}>Cambiar Asesor Propietario</ModalHeader>
            <ModalBody>
               <SearchEntity 
                  resourceType="users"
                  setResourceId={setNewUserId}
                  style={{width: '100%'}}
               />
               <Button color="primary" className="mt-3" onClick={handleSubmit}>Asociar Asesor</Button>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}