import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import Editable from "react-bootstrap-editable"
import { CardTitle, Button, Spinner } from 'reactstrap'
import { Descriptions, message } from 'antd'

import { getCustomFields } from '../containers/Settings/CustomFields/services'
import { handleError } from '../helpers'

const { Item } = Descriptions

export default function CustomValuesDetails({ resource, bordered, resourceType, createService, updateService, reload }) {
   const [customFields, setCustomFields] = useState(null)

   !customFields && getCustomFields({ 'filter[model]': resourceType })
      .then(response => setCustomFields(response.data.data))
      .catch(error => handleError(error))

   if(!customFields || !resource) return <Spinner size="sm" />

   const getCustomValue = ({slug, type}) => {
      let customValue = resource.custom_values.filter(cv => cv.slug === slug)

      if(!customValue[0] || !customValue[0].value) return ''
      else return customValue[0].value.toString()
   }

   const saveCustomValue = ({ id, slug, type }, value) => {
      let data = { custom_field_id: id, [`${type}_value`]: value }
      let customValue = resource.custom_values.filter(cv => cv.slug === slug)      
      
      if(customValue && customValue[0]){
         updateService(resource.id, customValue[0].id, data)
            .then(() => message.success('Campo actualizado exitosamente.'))
            .catch(error => handleError(error))
      } else {
         createService(resource.id, data)
            .then(() => {
               message.success('Campo guardado exitosamente.')
               reload()
            })
            .catch(error => handleError(error)) 
      }
   }

   return (
      <React.Fragment>
         <CardTitle className="mb-2">Campos Personalizados</CardTitle>
         {customFields.length > 0 ? (
            <Descriptions size="small" column={1} bordered={bordered}>
               { customFields.map(customField => (                  
                  <Item label={customField.label} key={customField.id}>
                     {customField.type !== 'json' ? (
                        <Editable 
                           editText={getCustomValue(customField) ? '' : '-'}
                           isValueClickable={getCustomValue(customField) ? true : false}
                           initialValue={getCustomValue(customField)}
                           onSubmit={val => saveCustomValue(customField, val)}
                        />
                     ):(                  
                        <Editable
                           editText={getCustomValue(customField) ? '' : '-'}
                           isValueClickable={getCustomValue(customField) ? true : false}
                           type="select"
                           initialValue={getCustomValue(customField)}
                           onValidated={val => saveCustomValue(customField, val)}
                           options={[ ':: Vacío ::', ...customField.options ]}
                           validate={val => (val === '' || val === ':: Vacío ::') ? "Campo vacio." : ''}
                        />
                     )}
                  </Item>
               ))}
            </Descriptions>
         ) : <div className="text-center mt-3"><em>Sin Campos personalizados</em></div>}
         <div className="mt-3 text-right">
            <Link to={`/settings/custom_fields/${resourceType}`}>
               <Button color="primary" outline size="sm">Editar Campos Personalizados</Button>
            </Link>
         </div>
      </React.Fragment>
   )
}