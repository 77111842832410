import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import {createCategory} from './services'
import {handleError} from '../../../helpers'

export default function NewCategory({reloadCategories}) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors, reset } = useForm()
   
   const onSubmit = values => {
      setLoading(true)
      createCategory(values)
         .then(() => {
            reloadCategories()
            reset()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Card>
         <CardBody>            
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre de la Categoría <span className="text-danger">*</span></Form.Label>
                  <Form.Control 
                     name="name"
                     placeholder="Escribe aquí el nombre"
                     ref={register({ required: true })}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Button color="primary" type="submit" disabled={loading}>Crear Categoría</Button> 
            </Form>
         </CardBody>
      </Card>
   )
}