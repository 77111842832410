import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import moment from 'moment';

import ReportTopBar from './partials/ReportTopBar';
import ReportFilters from './partials/ReportFilters'
import { usersReport } from './services';
import { handleError } from '../../helpers'
import UsersBoard from './partials/UsersBoard';
import StatCard from '../../components/StatCard';

export default function UsersReport() {
	const [fromDate, setFromDate] = useState(moment().startOf('month'))
	const [toDate, setToDate] = useState(moment().endOf('month'))
	const [currentFrom, setCurrentFrom] = useState(fromDate)
	const [currentTo, setCurrentTo] = useState(toDate)
	const [showFilters, setShowFilters] = useState(true)
	const [report, setReport] = useState(null)

	useEffect(()=>{
		!report && usersReport({
			from: moment(fromDate).format('YYYY-MM-DD'),
			to: moment(toDate).format('YYYY-MM-DD'),
		})
			.then(res => setReport(res.data))
			.catch(error => handleError(error))
	}, [report, fromDate, toDate])

	const handleSubmit = () => {
		setCurrentFrom(fromDate)
		setCurrentTo(toDate)
		setReport(null)
	}

	return (
		<React.Fragment>
			<ReportTopBar 
				title="Reporte de Asesores"
				from={currentFrom}
				to={currentTo}
				showFilters={showFilters}
				setShowFilters={setShowFilters}
				reload={() => setReport(null)}
			/>
			<Row>
				{showFilters && (
					<Col md="3" sm="4">
						<ReportFilters 
							fromDate={fromDate}
							setFromDate={setFromDate}
							toDate={toDate}
							setToDate={setToDate}
							handleSubmit={handleSubmit}
						/>
					</Col>
				)}
				<Col>
					<Row>
						<Col md="9">
							<UsersBoard 
								users={report && report.users}
								salesCount={report && report.sales_count}
								salesTotal={report && report.sales_total}
							/>
						</Col>
						<Col>
							<StatCard 
								loading={report ? false : true} 
								title="Total de Asesores"
								iconClass="fa fa-users"
								text={report && report.users_count}
							/>
							<StatCard 
								loading={report ? false : true} 
								title="Ventas Cerradas" 
								iconClass="fa fa-dollar-sign" 
								text={report && report.sales_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
							<StatCard 
								loading={report ? false : true} 
								title="Valor de Ventas" 
								iconClass="fa fa-money-bill" 
								text={report && '$'+report.sales_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	)
}