import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import QuotesTable from './partials/QuotesTable'
import ListTopBar from '../../components/ListTopBar'
import ExportList from '../../components/ExportList'

import { handleError, parsePagination } from '../../helpers'
import { getQuotes } from './services'

export default function QuotesList() {
	const [quotes, setQuotes] = useState(null)
	const [filterType, setFilterType] = useState('sku')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	const params = {
		[`filter[${filterType}]`]: filterValue,
		[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
		include: 'deal.contact.user',
		sort: '-sku'
	}

	useEffect(() => {
		!quotes && getQuotes({ ...pagination, ...params })
			.then(res => {
				setQuotes(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ quotes, pagination, params ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Cotizaciones Realizadas"
				subtitle="Listado de cotizaciones realizadas desde CRM"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setQuotes(null)}
				options={[
					{ label: 'Buscar por consecutivo', value: 'sku' },
				]}
				scopes={[
					{ label: 'Cotizaciones en Borrador', value: 'draft' },
					{ label: 'Cotizaciones Publicadas', value: 'published' },
					{ label: 'Cotizaciones Vencidas', value: 'expired' },
					{ label: 'Cotizaciones Anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody className='pb-0'>
					<QuotesTable 
						quotes={quotes}
						reload={() => setQuotes(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			<ExportList type="quotes" params={params} />
		</React.Fragment>
	)
}