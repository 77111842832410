import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'

import { editResource } from '../actions'
import { updateProduct } from '../services'
import { handleError } from '../../../helpers'

export default function EditProduct({ categories, reload }) {
   const dispatch = useDispatch()
   const [loading, setLoading] = useState(false)
   const {handleSubmit, register, errors} = useForm()
   const product = useSelector(state => state.products_props.editResource)

   const onSubmit = values => {
      setLoading(true)
      updateProduct(product.id, values)
         .then(response => {
            dispatch(editResource(null))
            message.success('Producto Actualizado Exitosamente')
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Modal isOpen={product && true} toggle={()=>dispatch(editResource(null))}>
         <ModalHeader toggle={() => dispatch(editResource(null))}>Actualizar Producto</ModalHeader>
         <ModalBody>
            { product && (            
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Nombre del Producto <span className="text-danger">*</span></Form.Label>
                     <Form.Control
                        name="name"
                        ref={register({ required: true })}
                        defaultValue={product.name}
                     />
                     { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Row>                  
                     <Form.Group as={Col}>
                        <Form.Label>SKU <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                           name="sku"
                           ref={register({ required: true })}
                           defaultValue={product.sku}
                        />
                        { errors.sku && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                     <Form.Group as={Col}>
                        <Form.Label>Valor por defecto <span className="text-danger">*</span></Form.Label>
                        <InputGroup>
                           <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                           </InputGroup.Prepend>
                           <Form.Control
                              name="default_value"
                              type="number"
                              ref={register({ required: true })}
                              defaultValue={product.default_value}
                           />
                        </InputGroup>                   
                        { errors.default_value && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Row>
                  <Form.Group>
                     <Form.Label>Categoría</Form.Label>
                     <Form.Control
                        name="category_id"
                        as="select"
                        ref={register({})}
                        defaultValue={product.category_id}
                     >
                        <option value="">:: Sin categoría ::</option>
                        { categories && categories.map(category => (
                           <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                     </Form.Control>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Descripción</Form.Label>
                     <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Escribe una descripción aquí"
                        defaultValue={product.description}
                        ref={register({})}
                     />
                  </Form.Group>
                  <Row className="mt-2">
                     <Form.Group as={Col}>
                        <Form.Check
                           name="is_featured"
                           label="Producto Destacado"
                           defaultChecked={product.is_featured}
                           ref={register({})}
                        />
                     </Form.Group>
                     <Form.Group as={Col}>
                        <Form.Check
                           name="is_subscription"
                           label="Producto Recurrente"
                           defaultChecked={product.is_subscription}
                           ref={register({})}
                        />
                     </Form.Group>
                  </Row>
                  <Row className="mt-2">
                     <Form.Group as={Col}>
                        <Form.Check
                           name="is_taxable"
                           label="IVA Aplicable"
                           defaultChecked={product.is_taxable}
                           ref={register({})}
                        />
                     </Form.Group>
                  </Row>
                  <Form.Group>
                     <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                           <InputGroup.Text>Recurrente cada</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                           name="recurrency_in_days"
                           type="number"
                           ref={register({})}
                           defaultValue={product.recurrency_in_days ? product.recurrency_in_days : 0}
                        />
                        <InputGroup.Append>
                           <InputGroup.Text>días</InputGroup.Text>
                        </InputGroup.Append>
                     </InputGroup>
                  </Form.Group>
                  <div className="mt-3">
                     <Button color="primary" disabled={loading}>Actualizar Producto</Button>
                  </div>
               </Form>
            )}
         </ModalBody>
      </Modal>
   )
}