import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'

import { showNewModal } from '../actions'
import { createProduct } from '../services'
import { handleError } from '../../../helpers'

export default function NewProduct({ categories, reload }) {
   const dispatch = useDispatch()
   const [loading, setLoading] = useState(false)
   const [extraFields, setExtraFields] = useState(false)
   const {handleSubmit, register, errors, watch} = useForm()
   const show = useSelector(state => state.products_props.showNewModal)

   const onSubmit = values => {
      setLoading(true)
      values.category_id === '' && delete values.category_id
      values.description === '' && delete values.description
      values.is_featured === false && delete values.is_featured
      values.is_subscription === false && delete values.is_subscription
      createProduct(values)
         .then(response => {
            dispatch(showNewModal(false))
            message.success('Producto Creado Exitosamente')
            setLoading(false)
            reload()
         })
         .catch(error => { 
            handleError(error)
            setLoading(false)
         })
   }

   return (
      <Modal isOpen={show} toggle={()=>dispatch(showNewModal(false))}>
         <ModalHeader toggle={() => dispatch(showNewModal(false))}>Crear Producto Nuevo</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre del Producto <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     placeholder="Escriba aquí el nombre"
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Row>                  
                  <Form.Group as={Col}>
                     <Form.Label>SKU <span className="text-danger">*</span></Form.Label>
                     <Form.Control
                        name="sku"
                        ref={register({ required: true })}
                        placeholder="Escriba un SKU único"
                     />
                     { errors.sku && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group as={Col}>
                     <Form.Label>Valor unitario por defecto <span className="text-danger">*</span></Form.Label>
                     <InputGroup>
                        <InputGroup.Prepend>
                           <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                           name="default_value"
                           type="number"
                           ref={register({ required: true })}
                           placeholder="Escriba un precio"
                        />
                     </InputGroup>                   
                     { errors.default_value && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
               </Row>               
               { extraFields && (
                  <React.Fragment>
                     <Form.Group>
                        <Form.Label>Categoría</Form.Label>
                        <Form.Control
                           name="category_id"
                           as="select"
                           ref={register({})}
                        >
                           <option value="">:: Sin categoría ::</option>
                           { categories && categories.map(category => (
                              <option key={category.id} value={category.id}>{category.name}</option>
                           ))}
                        </Form.Control>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                           as="textarea"
                           name="description"
                           placeholder="Escribe una descripción aquí"
                           ref={register({})}
                        />
                     </Form.Group>
                     <Row className="mt-2">
                        <Form.Group as={Col}>
                           <Form.Check
                              name="is_featured"
                              label="Producto Destacado"
                              ref={register({})}
                           />
                        </Form.Group>
                        <Form.Group as={Col}>
                           <Form.Check
                              name="is_subscription"
                              label="Producto Recurrente"
                              ref={register({})}
                           />
                        </Form.Group>
                     </Row>
                     <Row className="mt-2">
                        <Form.Group as={Col}>
                           <Form.Check
                              name="is_taxable"
                              label="IVA Aplicable"
                              defaultChecked
                              ref={register({})}
                           />
                        </Form.Group>
                     </Row>
                     { watch('is_subscription') && (
                        <Form.Group>
                           <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                 <InputGroup.Text>Recurrente cada</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                 type="number"
                                 name="recurrency_in_days"
                                 ref={register({ required: true })}                              
                              />
                              <InputGroup.Append>
                                 <InputGroup.Text>días</InputGroup.Text>
                              </InputGroup.Append>
                           </InputGroup>                           
                           { errors.recurrency_in_days && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                     )}                     
                  </React.Fragment>
               )}
               <div className="mt-3">
                  <p className="text-link pt-2 float-right" onClick={()=>setExtraFields(!extraFields)}>{extraFields ? 'Ocultar' : 'Mostrar'} Campos Adicionales</p>
                  <Button color="primary" disabled={loading}>Crear Producto Nuevo</Button>
               </div>
            </Form>
         </ModalBody>
      </Modal>
   )
}