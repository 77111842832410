import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import ProfileTopBar from './partials/ProfileTopBar'
import StatCard from '../../components/StatCard'
import EmailsLogs from './partials/EmailsLogs'

export default function EmailProfile() {
	return (
		<React.Fragment>
			<ProfileTopBar 
				backLink="/campaigns/emails" 
				title="Campaña de Email: WH-2020OCT21EMAIL01" 
			/>
			<Row>
				<Col md="3">
					<Card>
						<CardBody>
							<CardTitle>Detalles Generales</CardTitle>
							<Divider className="mt-2 mb-3" />
							<p className="text-muted mb-0 small">Nombre de Campaña</p>
							<h6>WH-2020OCT21EMAIL01</h6>
							<p className="text-muted mb-0 small">Fecha de Ejecución</p>
							<h6>2020 Oct 10 21:30</h6>
							<p className="text-muted mb-0 small">Fecha de Creación</p>
							<h6>2020 Oct 1 21:30</h6>
							<p className="text-muted mb-0 small">Asunto del Correo</p>
							<h6>Seguimiento prospectos nuevos</h6>
							<p className="text-muted mb-0 small">Enviado por</p>
							<h6>noreply@whitmanstore.com</h6>
							<p className="text-muted mb-0 small">Estado de Campaña</p>
							<h6>Finalizada</h6>
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Row>
						<Col md="3">
							<StatCard title="Destinatarios" text="1,020" iconClass="fa fa-users" />
						</Col>
						<Col md="3">
							<StatCard title="Entregados" text="730" iconClass="fa fa-envelope" />
						</Col>
						<Col md="3">
							<StatCard title="Aperturas" text="220" iconClass="fa fa-envelope-open" />
						</Col>
						<Col md="3">
							<StatCard title="Clicks" text="113" iconClass="fa fa-mouse-pointer" />
						</Col>
						<Col md="3">
							<StatCard title="Rebote" text="113" iconClass="fa fa-times" />
						</Col>
						<Col md="3">
							<StatCard title="Desuscritos" text="113" iconClass="fa fa-user-slash" />
						</Col>
						<Col md="3">
							<StatCard title="Tasa de apertura" text="10%" iconClass="fa fa-envelope-open-text" />
						</Col>
						<Col md="3">
							<StatCard title="Tasa de clicks" text="13%" iconClass="fa fa-mouse" />
						</Col>
					</Row>
					<Card>
						<CardBody>
							<EmailsLogs />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}