import React from 'react';
import { Card, CardBody, Media, Spinner } from "reactstrap";

export default function StatCard({ title, text, iconClass, loading }) {
   return (
      <Card className="mini-stats-wid">
         <CardBody>
            <Media>
               <Media body>
                  <p className="text-muted font-weight-medium mb-2">{title}</p>
                  <h4 className="mb-0">
                     {loading ? <Spinner size="sm" /> : text}
                  </h4>
               </Media>
               {iconClass && (
                  <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                     <span className="avatar-title">
                        <i className={iconClass + " font-size-24"}></i>
                     </span>
                  </div>
               )}
            </Media>
         </CardBody>
      </Card>
   )
}