import React from 'react'
import { Card, Button, CardTitle, CardSubtitle, CardBody } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'

export default function ListTopBar({ 
	filterType, 
	setFilterType, 
	filterValue, 
	setFilterValue, 
	filterScope,
	setFilterScope,
	reload,
	title,
	subtitle,
	options,
	scopes,
	scopeLabel,
	ctaText,
	handleCtaClick,
	className,
	prefix,
	onExport,
	secundaryCtaText,
	handleSecundaryCtaClick,
	viewType,
	setViewType,
}) {
	return (
		<React.Fragment>
			<Card className={`list-top-bar bg-light no-box-shadow mb-3 ${className}`}>
				<CardBody className='p-0 bg-light'>
					{ options && (
						<div className="float-right">
							<InputGroup>
								{ prefix && (
									<InputGroup.Text className='pr-2'>
										{prefix}
									</InputGroup.Text>
								)}
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									{options.map((option,i) => 
										<option key={i} value={option.value}>{option.label}</option>
									)}
								</Form.Control>
								<Form.Control 
									placeholder="Escriba aquí" 
									value={filterValue}
									onChange={e => setFilterValue(e.target.value)}
								/>
								<Button color={ctaText ? 'dark' : 'primary'} onClick={reload}>Buscar</Button>
								{ secundaryCtaText && (
									<Button className="ml-2" color="primary" onClick={handleSecundaryCtaClick}>{secundaryCtaText}</Button>
								)}
								{ ctaText && (
									<Button className="ml-2" color="primary" onClick={handleCtaClick}>{ctaText}</Button>
								)}
								{ onExport && (
									<Button className="ml-2" color="primary" onClick={onExport}>Exportar</Button>
								)}
							</InputGroup>
							{ scopes && (
								<InputGroup className='mt-1'>
									<Form.Control 
										disabled 
										value={scopeLabel ? scopeLabel : 'Estado'}
										style={{ maxWidth: scopeLabel ? '100px' : '80px' }} 
										className="bg-light"
									/>
									<Form.Control
										as="select"
										value={filterScope}
										onChange={e => {
											setFilterScope(e.target.value)
											reload()
										}}
									>
										<option value="">:: Ver todos {scopeLabel ? 'las opciones' : 'los estados'} ::</option>
										{ scopes.map(scope => (
											<option key={scope.value} value={scope.value}>{scope.label}</option>
										)) }
									</Form.Control>
								</InputGroup>
							)}
						</div>
					)}
					<div>
						{ title && <CardTitle><h4 className='mb-0 font-size-20'>{title}</h4></CardTitle> }
						{ subtitle && <CardSubtitle>{subtitle}</CardSubtitle> }
						{ viewType && (
							<React.Fragment>
								<span className='text-muted'>Visualizar en: </span>
								<Button 
									color="" 
									className={`font-size-14 ${viewType === 'table' && 'text-primary'}`}
									onClick={() => setViewType('table')}
								>
									<i className="fa fa-table"></i> Tabla
								</Button>
								<Button 
									color="" 
									className={`font-size-14 ${viewType === 'calendar' && 'text-primary'}`}
									onClick={() => setViewType('calendar')}
								>
									<i className="fa fa-calendar"></i> Calendario
								</Button>
							</React.Fragment>
						)}
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}