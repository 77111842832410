import React from 'react'
import { Steps, message } from 'antd'
import { Card, CardBody, Spinner } from 'reactstrap';
import styled from 'styled-components'

import { updateDeal } from '../services';
import { handleError } from '../../../helpers';

const { Step } = Steps;

export default function DealStages({ deal, reload }) {

   const handleChange = stageId => {
      updateDeal(deal.id, { stage_id: stageId })
         .then(() => {
            message.success('Etapa de Negocio Actualizada')
            reload()
         })
         .catch(error => {
            handleError(error)
            reload()
         })
   }

   const getCurrent = () => {
      let current = 0;
      for (let i = 0; i < deal.stage.pipeline.stages.length; i++) {
         const stage = deal.stage.pipeline.stages[i];
         if(stage.id === deal.stage.id) current = i
      }
      return current
   }

   return (
      <Card className={`site-navigation-steps ${(deal && deal.state !== 'active') && 'hide'}`}>
         <CardBody className="py-0">
            <Container>
               { deal ? (
                  <Steps
                     type="navigation"
                     size="small"
                     current={getCurrent()}
                     onChange={()=>{}}
                  >
                     {deal.stage.pipeline.stages.map(st => (
                        <Step 
                           key={st.id}
                           status={deal.stage.id === st.id ? 'process' : 'wait'} 
                           title={st.name} 
                           icon={<span></span>}
                           onClick={() => handleChange(st.id)}
                        />
                     ))}
                  </Steps>
               ) : (
                  <div className="text-center">
                     <Spinner size="sm" style={{position: 'relative', top: '12px'}} />
                  </div>
               )}               
            </Container>
         </CardBody>
      </Card>
   )
}

const Container = styled.div`
   min-height: 44px;
`