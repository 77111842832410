import React, { useState } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'
import { Form } from "react-bootstrap"

export default function BudgetWidget({ salesTotal }) {
	const [budget, setBudget] = useState(0)

	if(!salesTotal) return null

	return (
		<Card>
			<CardBody>
				<CardTitle>Cumplimiento de Presupuesto</CardTitle>
				<Divider className="mt-2 mb-3" />
				<Form.Group>
					<Form.Label>Escribe el presupuesto</Form.Label>
					<Form.Control 
						type="number"
						value={budget}
						onChange={e => setBudget(e.target.value)}
						placeholder='Escribe aquí...'
					/>
					<Form.Text className="text-muted small">Ingrese el valor de ventas esperado</Form.Text>
				</Form.Group>				
				<div className="bg-light p-2">
					<h6 className="">
						<span className="text-muted small">Vendido: </span>
						{`$${salesTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
					</h6>
					<h6 className="mb-0">
						<span className="text-muted small">Presupuesto: </span>
						{budget ? `$${budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '$0'}
					</h6>
					<Divider className="my-2" />
					<h6>
						<span className="text-muted small">Cumplido: </span>
						{ budget ? (
							<strong>{round(salesTotal/parseInt(budget)*100)}%</strong>
						) : <small>Ingrese su presupuesto</small>}
					</h6>
					<h6 className="mb-0">
						<span className="text-muted small">Por cumplir: </span>
						{ budget ? (
							<strong>{round(((salesTotal/parseInt(budget))-1)*-100)}%</strong>
						) : <small>Ingrese su presupuesto</small>}
					</h6>
				</div>
			</CardBody>
		</Card>
	)
}

const round = number => {
	return Math.round((number + Number.EPSILON) * 10) / 10
}