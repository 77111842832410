import React from 'react'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { withRouter } from 'react-router-dom';

const localizer = momentLocalizer(moment)

function ActivitiesCalendar({ activities, stateProps, reload, history }) {
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					{ activities ? (
						<Calendar
							localizer={localizer}
							events={formatEvents(activities)}
							startAccessor="start"
							endAccessor="end"
							style={{ height: 500 }}
							messages={calendarMessages}
							views={['month', 'week', 'day']}
							onSelectEvent={event => history.push(`/${event.actionable_type}/${event.actionable_id}`)}
						/>
					) : <Spinner /> }
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const calendarMessages = {
	"today": "Hoy", 
	"previous": "Anterior", 
	"next": "Siguiente",
	"month": "Mes",
	"week": "Semana",
	"year": "Año",
	"day": "Día",
}

const formatEvents = activities => {
	const events = activities.map(activity => {
		return {
			title: activity.reference + ' - ' + activity.owner.name,
			start: moment(activity.due_at).toDate(),
			end: moment(activity.due_at).add(activity.duration_in_minutes, "minutes").toDate(),
			actionable_type: activity.actionable_type,
			actionable_id: activity.actionable_id
		}
	})

	return events
}

export default withRouter(ActivitiesCalendar)