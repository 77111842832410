import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Table, Empty, Divider, Tooltip } from 'antd'
import { Card, CardBody, Button } from 'reactstrap'
import moment from 'moment'

import { setQuery, updatePagination } from '../actions'
import TableActions from '../../../components/TableActions'
import Pagination from '../../../components/Pagination'
import { deleteContact } from '../services'
import { handleError } from '../../../helpers'
import ContactStats from './ContactStats'

function ContactsTable({ contacts, stateProps, reload, history }) {
   const dispatch = useDispatch()
   const [showStatsOf, setShowStatsOf] = useState(null)

   const handleDelete = id => {
      deleteContact(id)
         .then(() => reload())
         .catch(error => handleError(error))
   }

   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      dispatch(setQuery({ sort: newSort }))
      reload()
   };

   const columns = [
      {
         title: 'Nombre',
         dataIndex: 'fullname',
         sorter: true,
         render: (text, record) => (
            <Link to={`/contacts/${record.id}`}>{text ? text : <em className="small">Sin Nombre</em>}</Link>
         ),
         width: 190,
         fixed: 'left',
      },
      {
         title: 'Email',
         dataIndex: 'email',
         width: 250,
         render: text => text ? text : '-'
      },
      {
         title: 'Celular',
         dataIndex: 'mobile',
         width: 115,
         render: text => text ? text : '-'
      },
      {
         title: 'Documento',
         dataIndex: 'document',
         width: 120,
         render: text => text ? text : '-'
      },
      {
         title: 'Negocios',
         dataIndex: 'deals_count',
         width: 120,
         render: text => text+' negocios'
      },
      {
         title: 'No. de Ventas',
         dataIndex: 'sales_count',
         width: 120,
         sorter: true,
         render: text => text+' ventas'
      },
      {
         title: 'Total Comprado',
         dataIndex: 'total_sales_value',
         width: 120,
         sorter: true,
         render: text => `$${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      },
      {
         title: 'Creado el',
         dataIndex: 'created_at',
         sorter: true,
         width: 160,
         render: text => moment(text).format('Y-MM-DD HH:mm')
      },
      {
         title: 'Asesor',
         dataIndex: 'user',
         width: 150,
         render: text => text.name
      },
      {
         title: 'Acciones',
         width: 170,
         render: (txt, record) => (
            <React.Fragment>
               <TableActions 
                  handleEdit={()=> history.push(`/contacts/${record.id}`)}
                  handleDelete={() => handleDelete(record.id)}
               />
               <Divider type="vertical" />
               <Tooltip title="Estadísticas">
                  <Button outline size="sm" color="primary" onClick={() => setShowStatsOf(record.id)}>
                     <i className="fa fa-tachometer-alt"></i>
                  </Button>
               </Tooltip>
            </React.Fragment>
         )
      }
   ]

   return (
      <React.Fragment>
         <Card>
            <CardBody className="p-3">
               <div className="table-responsive">
                  <Table 
                     columns={columns}
                     dataSource={contacts}
                     loading={!contacts && true}
                     showSorterTooltip={false}
                     rowKey={record => record.id}
                     pagination={false}
                     onChange={handleTableChange}
                     scroll={{ x: 'fixed', scrollToFirstRowOnChange: true }}
                     locale={{ emptyText: <Empty description="No se encontraron contactos" className="mt-4" /> }}
                  />
                  <Pagination stateProps={stateProps} reload={reload} updatePagination={updatePagination} />
               </div>
            </CardBody>
         </Card>
         <ContactStats 
            show={showStatsOf !== null ? true : false}
            close={() => setShowStatsOf(null)} 
            contactId={showStatsOf}
         />
      </React.Fragment>
   )
}

export default withRouter(ContactsTable)