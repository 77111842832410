import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import { Spinner } from 'reactstrap'

import MainLayout from '../../layouts/MainLayout'

import {userLogout} from './actions'
import {logout} from './services'

export default function Logout() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)

    token && logout()
        .finally(()=>dispatch(userLogout()))

    if(!token) return <Redirect to="/auth/login" />

    return (
        <MainLayout>
            <div className="text-center">
                <Spinner />
            </div>
        </MainLayout>
    )
}