import React, {useState, useEffect} from 'react'
import { Spinner, Card, CardBody, Row, Col, Badge, Button } from 'reactstrap'

import { getSegment, getSegmentContacts } from './services'
import { handleError } from '../../helpers'
import SegmentTable from './partials/SegmentTable'
import StatCard from '../../components/StatCard'
import ConditionsWizard from './partials/ConditionsWizard'
import EditSegmentModal from './partials/EditSegmentModal'

export default function SegmentProfile(props) {	
	const id = props.match.params.id	
	const [segment, setSegment] = useState(null)
	const [contacts, setContacts] = useState(null)
	const [pagination, setPagination] = useState({ page: 1 })
	const [editSegment, setEditSegment] = useState(null)
	const [sortBy, setSortBy] = useState('-created_at')
	const [apidomain, setApidomain] = useState('')

	useEffect(()=>{
		var url = window.location.host.split('.')
		let domain = (url[1].includes('localhost')) ? url[0]+'.s4api_core.test' : url[0]+'.coreapi.smart4sales.com'
		setApidomain(domain)
	}, [])

	!segment && getSegment(id)
		.then(res => setSegment(res.data.data))
		.catch(error => handleError(error))

	if(!segment) return <Spinner />

	!contacts && getSegmentContacts(segment.query+`&page=${pagination.page}&append=total_sales_value,total_sales_count,first_sale_date,last_sale_date&sort=${sortBy}`)
		.then(res => {
			setPagination({ 
				page: res.data.meta.current_page, 
				total: res.data.meta.total,
				per_page: res.data.meta.per_page
			})
			setContacts(res.data.data)
		})
		.catch(error => handleError(error))

	return (
		<React.Fragment>
			{ contacts ? (
				<React.Fragment>
					<Row className="mt-2">
						<Col md="3">
							{ segment.scope ? (
								<Badge className="mb-2" color="dark">{ segment.scope === 'clients' ? 'SOLO CLIENTES' : 'SOLO PROSPECTOS' }</Badge> 
							) : (
								<Badge className="mb-2" color="dark">TODOS LOS CONTACTOS</Badge>
							)}
							<h4 className="mb-1 font-size-18">
								Segmento {segment.name}{" "}
								<i className="fa fa-edit font-size-12 text-primary"
									onClick={()=>setEditSegment(segment)} />
							</h4>
							<p>{segment.description}</p>
                     <StatCard
                        title="Contactos encontrados"
                        text={pagination.total}
                        iconClass="bx bx-user"
                     />
                  </Col>
						<Col>							
							<Card>
								<CardBody>
									<h6 className="text-muted mb-3">Mostrando todos los contactos que cumplen con:</h6>
									<ConditionsWizard 
										segmentId={segment.id} 
										reload={()=>{setSegment(null); setContacts(null)}} 
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<SegmentTable 
						contacts={contacts} 
						pagination={pagination} 
						setPagination={setPagination}
						setSortBy={setSortBy}
						reload={()=>setContacts(null)}
					/>
					<div className="text-right mb-3">
						<a href={`https://${apidomain}/exports/segments/${id}${segment.query}`} target="_blank" rel="noopener noreferrer">
							<Button color="info" outline>Exportar Segmento</Button>
						</a>
					</div>
				</React.Fragment>
			) : <Spinner />}
			<EditSegmentModal 
				visible={editSegment ? true : false} 
				onClose={reload => {
					setEditSegment(null)
					if(reload) setSegment(null)
				}}
				segment={editSegment}
			/>
		</React.Fragment>
	)
}