import s4salesapi from '../../services/s4salesapi'

export const getQuotes = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/quotes', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getQuote = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/quotes/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeQuote = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/quotes', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateQuote = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/quotes/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteQuote = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/quotes/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getPublicQuote = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/public/quotes/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)