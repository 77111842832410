import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from "reactstrap";
import { Divider } from 'antd'

export default function ProfileTopBar() {
	return (
		<React.Fragment>
			<div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
					<Link to="/email_lists">
						<i className="fa fa-arrow-left" />
					</Link>
					<Divider type="vertical" />
					Lista: Whitman Newsletter
            </h4>
            <div className="page-title-right">
					<Button color="danger" outline>
						<i className="fa fa-trash" />
					</Button>
				</div>
			</div>
		</React.Fragment>
	)
}