import React from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Divider, Switch } from 'antd'
import { InputGroup, Form } from 'react-bootstrap'
import { isEqual } from 'lodash'

import { setQuery, showNewModal, setListView } from '../actions'

export default function ListTopBar({ currentView, reload, setShowFilters, salesTopbar }) {
   const { handleSubmit, register } = useForm()
   const filters = useSelector(state => state.deals_props.query.filters)
   const dispatch = useDispatch()

   const onSubmit = values => {
      if(values.reference === '') delete values.reference
      dispatch(setQuery({ filters: values }))
      reload()
   }

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               {salesTopbar ? 'Listado de Ventas' : 'Listado de Negocios'}
               <span className="text-warning">{ !isEqual(filters, { state:'active' }) && ' - Aplicando Filtros' }</span>
            </h4>            
            <div className="page-title-right">               
               <Button
                  color="" 
                  className={`font-size-14 hide-sm ${currentView === 'table' && 'text-primary'}`} 
                  onClick={() => {
                     dispatch(setListView('table'))
                     reload()
                  }}
               >
                  <i className="fa fa-table"></i> Tabla
               </Button>
               { !salesTopbar && (
                  <Button
                     color="" 
                     className={`font-size-14 hide-sm ${currentView === 'funnel' && 'text-primary'}`} 
                     onClick={() => {
                        dispatch(setListView('funnel'))
                        reload()
                     }}
                  >
                     <i className="fa fa-funnel-dollar"></i> Embudos
                  </Button>
               )}
               <Divider type="vertical" className="hide-sm" />
               <Form className="d-inline-block hide-sm" onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup className="mb-0">
                     <Form.Control
                        name="reference"
                        placeholder="Busqueda rápida..."
                        ref={register({})}
                     />
                     <InputGroup.Append>
                        <Button color="primary">
                           <i className="fa fa-search"></i>
                        </Button>
                     </InputGroup.Append>
                  </InputGroup>
               </Form>
               { !salesTopbar && (
                  <React.Fragment>
                     <Divider type="vertical" className="hide-sm" />
                     <Button
                        color="primary"
                        style={{ padding: '8px 20px', fontSize: '14px' }}
                        onClick={() => dispatch(showNewModal(true))}
                     >
                        Crear Negocio
                     </Button>
                  </React.Fragment>
               )}
               { setShowFilters && (
                  <React.Fragment>
                     <Divider type="vertical" />
                     <Switch onChange={checked => setShowFilters(checked)} /> Ver Filtros
                  </React.Fragment>
               )}
            </div>
         </div>
      </React.Fragment>
   )
}