import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { showNewModal } from '../actions'
import { createCompany } from '../services'
import { handleError } from '../../../helpers'

function NewCompany({ history }) {
   const show = useSelector(state => state.companies_props.showNewModal)
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors } = useForm()
   const dispatch = useDispatch()

   const onSubmit = values => {
      setLoading(true)
      values.document === '' && delete values.document
      createCompany(values)
         .then(response => {
            dispatch(showNewModal(false))
            history.push(`/companies/${response.data.data.id}`)
         })
         .catch(error => { 
            handleError(error)
            setLoading(false)
         })
   }

   return (
      <Modal isOpen={show} toggle={()=>dispatch(showNewModal(false))}>
         <ModalHeader toggle={() => dispatch(showNewModal(false))}>Crear Empresa Nueva</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre de la Empresa <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     placeholder="Escriba aquí el nombre"
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Documento <small className="text-muted">(opcional)</small></Form.Label>
                  <Form.Control
                     name="document"
                     ref={register({})}
                     placeholder="Escriba aquí el documento"
                  />                  
               </Form.Group>
               <Button color="primary" disabled={loading}>Crear Empresa Nueva</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}

export default withRouter(NewCompany)