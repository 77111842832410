import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Button } from 'reactstrap'
import { Empty } from 'antd'

export default function EmptyCard({ description, buttonLink, buttonText }) {
   return (
      <Card className="mt-3">
         <CardBody className="text-center py-4">
            <Empty description={description} />
            <Link to={buttonLink}>
               <Button color="dark" className="mt-4">{buttonText}</Button>
            </Link>
         </CardBody>
      </Card>
   )
}