import React, {useState, useEffect} from 'react'
import { Button, Spinner } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message, Popconfirm } from 'antd'

import { deleteSegmentFilter, getSegmentFilters, getSysFilters } from '../services'
import { handleError } from '../../../helpers'
import NewConditional from './NewConditional'

export default function ConditionsWizard({ segmentId, reload }) {
	const [sysFilters, setSysFilters] = useState(null)
	const [filters, setFilters] = useState(null)

	useEffect(() => {
		!sysFilters && getSysFilters()
			.then(res => setSysFilters(res.data.data))
			.catch(error => handleError(error))	

		!filters && getSegmentFilters(segmentId)
			.then(res => setFilters(res.data.data))
			.catch(error => handleError(error))
	}, [segmentId, sysFilters, filters])

	if(!sysFilters || !filters) return <Spinner />	

	const onDelete = filterId => {
		deleteSegmentFilter(filterId)
			.then(() => {
				message.success('Filtro Eliminado')
				if(reload) reload()
				else setFilters(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ filters.map((filter, i) => {
				filter.props = sysFilters.filter(sys => sys.name === filter.filter)[0]
				return (
					<div className="flat-form-control mb-2" key={i}>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>
									{ filter.props.label }
								</InputGroup.Text>
							</InputGroup.Prepend>
							<InputGroup.Prepend>
								<InputGroup.Text>
									{ filter.props.operator }
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control 
								value={ filter.value }
								className="bg-light"
								disabled
							/>
							<InputGroup.Append>
								<Popconfirm
									title="Eliminar el filtro"
									cancelText="Cancelar"
									okText="Eliminar"
									onConfirm={()=>onDelete(filter.id)}
								>
									<Button color="danger" outline>
										<i className="fa fa-trash"></i> Eliminar
									</Button>
								</Popconfirm>
							</InputGroup.Append>
						</InputGroup>
					</div>
				)	
			})}
			{ filters.length === 0 && <Form.Text className="text-warning mb-2">
				<i className="fa fa-bell" /> No tiene condicionales asociadas a este segmento
			</Form.Text>}
			<NewConditional 
				segmentId={segmentId} 
				sysFilters={sysFilters} 
				reload={()=>{
					if(reload) reload()
					else setFilters(null)
				}} 
			/>
		</React.Fragment>
	)
}