import React, {useState} from 'react'
import Editable from "react-bootstrap-editable"
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Descriptions, message, Switch, Spin } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import CustomValuesDetails from '../../../components/CustomValuesDetails'
import DealProducts from './DealProducts'

import { updateDeal, createDealCustomValue, updateDealCustomValue } from '../services'
import { handleError } from '../../../helpers'
import { Link } from 'react-router-dom'

const { Item } = Descriptions

export default function DealDetails({ dealId, deal, reload }) {
   const [bordered, setBordered] = useState(false)

   const onSave = (prop, value) => {
      updateDeal(deal.id, { [prop]: value })
      .then(() => {
         message.success('Actualizado con éxito')
         reload()
      })
      .catch(error => {
         handleError(error)
         reload()
      })
   }

   return (      
   <React.Fragment>
      <Card>
         <CardBody>
            <CardTitle>Productos y Servicios</CardTitle>
            <DealProducts reload={reload} dealId={dealId} products={deal && deal.products} />
         </CardBody>
      </Card>
      <Card>
         <CardBody>
            <CardTitle className="mb-2">Datos del Negocio</CardTitle>
               <Spin spinning={!deal}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Referencia">
                        { deal && (
                           <Editable 
                              initialValue={deal.reference} 
                              onSubmit={val => onSave('reference', val)} 
                              editText={deal.reference ? '' : '-'}
                              isValueClickable={deal.reference ? true : false}
                           />
                        )}
                     </Item>                   
                     <Item label="Embudo">{deal && deal.stage.pipeline.name}</Item>
                     <Item label="Etapa">{deal && deal.stage.name}</Item>
                     <Item label="Total de Items">{deal && deal.total_items}</Item>
                     <Item label="Valor Total">{deal && `$ ${deal.total_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Item>
                     <Item label="Contacto">
                        <Link to={`/contacts/${deal && deal.contact.id}`}>
                           {deal && deal.contact.fullname}
                        </Link>
                     </Item>
                     <Item label="Correo del Contacto">{deal && deal.contact.email}</Item>
                     <Item label="Celular del Contacto">{(deal && deal.contact.mobile) ? deal.contact.mobile : '-'}</Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CustomValuesDetails 
                  resourceType="deals" 
                  resource={deal} 
                  bordered={bordered}
                  createService={createDealCustomValue}
                  updateService={updateDealCustomValue}
                  reload={reload}
               />
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CardTitle className="mb-2">Datos Adicionales</CardTitle>
               <Spin spinning={!deal}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Último Cambio de Estado">{deal && moment(deal.state_changed_at).format('LLL')}</Item>
                     <Item label="Fecha de Creación">{deal && moment(deal.created_at).format('LLL')}</Item>
                     <Item label="Última Actualización">{deal && moment(deal.updated_at).format('LLL')}</Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <div className="hide-md">
            <Switch size="small" checked={!bordered} onChange={() => setBordered(!bordered)} />{" "}
            Modo Compacto
         </div>
      </React.Fragment>
   )
}