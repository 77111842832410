import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'

import LogFeed from '../../components/LogFeed'
import ResourceActivities from '../../components/ResourceActivities'
import ResourceTags from '../../components/ResourceTags'
import ProfileTopBar from './partials/ProfileTopBar'
import ContactDetails from './partials/ContactDetails'
import ContactDeals from './partials/ContactDeals'
import NewDealModal from '../Deals/partials/NewDealModal'

import { getContact, getContactTags, attachContactTag, detachContactTag } from './services'
import { handleError } from '../../helpers'

export default function ContactProfile(props) {
   const [contact, setContact] = useState(null)
   const contactId = props.match.params.id

   !contact && getContact(contactId, { include: 'customValues,companies,deals,deals.stage,logs,logs.causer,user' })
      .then(response => setContact(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <ProfileTopBar title={contact && contact.fullname} contactId={contactId} />
         <Row>
            <Col md="4">
               <ContactDetails contact={contact} reload={() => setContact(null)} />
            </Col>
            <Col md="8">
               <ResourceActivities resourceType="contacts" resourceId={contactId} />
               <Row>
                  <Col md="8">
                     <ContactDeals deals={contact && contact.deals} />
                     <LogFeed logs={contact && contact.logs} />
                  </Col>
                  <Col md="4">
                     <ResourceTags
                        resourceId={contactId}
                        getService={getContactTags}
                        attachService={attachContactTag}
                        detachService={detachContactTag}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
         <NewDealModal contactId={contactId} />
      </React.Fragment>
   )
}