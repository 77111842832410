import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'

import { deletePipeline } from '../services'
import { handleError } from '../../../../helpers'
import TableActions from '../../../../components/TableActions'

export default function PipelineItem({ pipeline, pipelineSelected, setPipelineSelected, reload, setEditPipeline }) {

   const handleDelete = id => {
      deletePipeline(id)
         .then(() => {
            setPipelineSelected(null)
            reload()
         })
         .catch(error => handleError(error))
   }

   return (
      <Row>
         <Col xs="8" lg="9">
            <PipelineContainer                                    
               className={ pipelineSelected && pipeline.id === pipelineSelected && 'active' }
               onClick={() => setPipelineSelected(pipeline.id)}
            >
               {pipeline.name}{" "}
            </PipelineContainer>
         </Col>
         <Col className="pt-2">
            <TableActions 
               handleEdit={()=>setEditPipeline(pipeline)}
               handleDelete={pipeline.deals_count === 0 ? ()=>handleDelete(pipeline.id) : null}
               dark={true}
            />
         </Col>
      </Row>
   )
}

const PipelineContainer = styled.div`
   padding: 9px 25px;
   border: 2px solid #dadaea;
   background-color: #f6f7ff;
   font-size: 15px;
   border-radius: 10px;
   margin-bottom: 10px;
   &:hover {
      background-color: #dadaea;
      color: #7c79a6;
      cursor: pointer;
   }
   &.active {
      background-color: #04004d;
      color: #fff;
      border-color: #04004d;
   }
`