import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import TemplatesList from './TemplatesList'
import TemplateEditor from './TemplateEditor'

export default function Templates() {
	return (
		<React.Fragment>
			<MainLayout>
				<Switch>
					<Route exact path="/templates" component={TemplatesList} />
					<Route exact path="/templates/:id" component={TemplateEditor} />

					<Redirect to="/404" />
				</Switch>
			</MainLayout>
		</React.Fragment>
	)
}