import React from 'react'
import { Badge, Button } from 'reactstrap'
import { Descriptions, Empty, Table, Tooltip } from 'antd'
import moment from 'moment'

import Pagination2 from '../../../components/Pagination2'

export default function BookingsTable({ bookings, reload, pagination, setPagination, setBookingModal }) {
	
	const columns = [
		{
			title: 'Consecutivo',
			dataIndex: 'id',
			render: (t, r) => <span className='text-link' onClick={() => setBookingModal(r.id)}>{t}</span>
		},
		{
			title: 'Cliente',
			dataIndex: 'deal',
			render: r => r.contact.fullname
		},
		{
			title: 'Producto',
			dataIndex: 'calendar',
            render: t => t.product ? t.product.name : <em>Sin especificar</em>
		},
		{
			title: 'Reservado desde',
			dataIndex: 'start_at',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Reservados hasta',
			dataIndex: 'end_at',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Detalles">
						<Button outline size="sm" color="primary" onClick={() => setBookingModal(r.id)}>
							<i className="fa fa-search"></i>
						</Button>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Descriptions size='small'>
				<Descriptions.Item label="Consecutivo Interno">{r.id}</Descriptions.Item>
				<Descriptions.Item label="Reservado desde">{r.start_at}</Descriptions.Item>
				<Descriptions.Item label="Fecha y Hora de Ingreso">{r.entry_at}</Descriptions.Item>
				<Descriptions.Item label="Fecha y Hora Salida">{r.exit_at}</Descriptions.Item>
				<Descriptions.Item label="Reservado hasta">{r.end_at}</Descriptions.Item>
				<Descriptions.Item label="Asesor Asignado">{r.deal.contact.user.name}</Descriptions.Item>
			</Descriptions>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={bookings}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!bookings}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination2
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}