import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Empty } from 'antd'

import { getContacts } from '../../Contacts/services'
import { handleError } from '../../../helpers'
import ResultCard from './ResultCard'

export default function ContactsResults({ term }) {
	const [contacts, setContacts] = useState(null)

	useEffect(()=>{
		!contacts && getContacts({ "filter[fullname]": term })
			.then(res => setContacts(res.data.data))
			.catch(error => handleError(error))
	}, [contacts, term])

	if(!contacts) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ contacts.length > 0 ? (
				<React.Fragment>
					{ contacts.map(contact => (
						<ResultCard
							key={contact.id}
							title={contact.fullname}
							link={`/contacts/${contact.id}`}
						/>
					)) }
				</React.Fragment>
			) : <Empty description="No se encontraron contactos" />}
		</React.Fragment>
	)
}