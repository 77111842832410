import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import { editResource } from '../actions'
import { updateActivity } from '../services'
import { handleError } from '../../../helpers'
import { getUsers } from '../../Settings/Users/services'

export default function EditActivity({ reload }) {
   const activity = useSelector(state => state.activities_props.editResource)
   const [loading, setLoading] = useState(false)
   const [users, setUsers] = useState(null)
   const { handleSubmit, register, errors} = useForm()
   const dispatch = useDispatch()   

   const onSubmit = values => {
      setLoading(true)
      updateActivity(activity.id, { ...activity, ...values })
         .then(() => {
            dispatch(editResource(null))
            message.success('Actividad actualizada exitosamente.')
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   if(!activity) return null;

   !users && getUsers({})
      .then(response => setUsers(response.data.data))
      .catch(error => handleError(error))

   return (
      <Modal isOpen={editResource && true} toggle={()=>dispatch(editResource(null))}>
         <ModalHeader toggle={() => dispatch(editResource(null))}>Actualizar Actividad</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label className="block">Estado</Form.Label>
                  <Form.Check inline label="Sugerida" type="radio" name="state" value="draft" ref={register({ required: true })} defaultChecked={activity.state === 'draft'} />
                  <Form.Check inline label="Activa" type="radio" name="state" value="active" ref={register({ required: true })} defaultChecked={activity.state === 'active'} />
                  <Form.Check inline label="Completada" type="radio" name="state" value="completed" ref={register({ required: true })} defaultChecked={activity.state === 'completed'} />
                  <Form.Check inline label="Cancelada" type="radio" name="state" value="canceled" ref={register({ required: true })} defaultChecked={activity.state === 'canceled'} />
               </Form.Group>
               <Form.Group>
                  <Form.Label>Referencia</Form.Label>
                  <Form.Control
                     name="reference"
                     ref={register({ required: true })}
                     defaultValue={activity.reference}
                  />
                  { errors.reference && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Row>
                  <Col>
                     <Form.Group>
                        <Form.Label>Vence el</Form.Label>
                        <Form.Control
                           name="due_at"
                           ref={register({ required: true })}
                           type="datetime-local"
                           defaultValue={moment(activity.due_at).format('YYYY-MM-DDTHH:mm')}
                        />
                        { errors.due_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group>
                        <Form.Label>Duración</Form.Label>
                        <InputGroup>
                           <Form.Control 
                              name="duration_in_minutes"
                              ref={register({required: true})}
                              type="number"
                              defaultValue={activity.duration_in_minutes}
                           />
                           <InputGroup.Append>
                              <InputGroup.Text>Minutos</InputGroup.Text>
                           </InputGroup.Append>
                        </InputGroup>
                        { errors.duration_in_minutes && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                     </Form.Group>
                  </Col>
               </Row>
               <Form.Group>
                  <Form.Label>Descripción / Comentarios</Form.Label>
                  <Form.Control
                     name="description"
                     as="textarea"
                     ref={register({})}
                     placeholder="Escriba una descripción"
                     defaultValue={activity.description}
                  />
               </Form.Group>
               {users && (
                  <Form.Group>
                     <Form.Label>Usuario Asignado</Form.Label>
                     <Form.Control
                        name="user_id"                        
                        ref={register({})}
                        as="select"
                        defaultValue={activity.user_id}
                     >
                        { users.map( user => <option key={user.id} value={user.id}>{user.name}</option>) }
                     </Form.Control>
                  </Form.Group>
               )}
               <Button color="primary" disabled={loading}>Actualizar Actividad</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}

/*
description - null
state - default active
*/