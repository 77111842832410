import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { setQuery } from '../actions'

export default function ContactsFilters({ reload }) {
   const filters = useSelector(state => state.contacts_props.query.filters)
   const dispatch = useDispatch()
   const initialFilters = { fullname: '', email: '', mobile: '', document: '', tags_name: ''}
   const [form, setForm] = useState({ ...initialFilters, ...filters })

   const setFilters = values => {
      dispatch(setQuery({ filters: values }))
      reload()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Búsqueda Avanzada</CardTitle>
            <p className="text-muted mb-4">Filtros Múltiples</p>
            <Form>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por nombre"
                     value={form.fullname}
                     onChange={e => setForm({ ...form, fullname: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por correo"
                     value={form.email}
                     onChange={e => setForm({ ...form, email: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por celular"
                     value={form.mobile}
                     onChange={e => setForm({ ...form, mobile: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por documento"
                     value={form.document}
                     onChange={e => setForm({ ...form, document: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por Etiqueta"
                     value={form.tags_name}
                     onChange={e => setForm({ ...form, tags_name: e.target.value })}
                  />
               </Form.Group>
               <Button color="primary" onClick={() => setFilters(form)}>Realizar Búsqueda</Button>
               <div className="mt-2">
                  <Button 
                     color="warning" 
                     outline 
                     size="sm" 
                     onClick={() => {
                        setFilters({})
                        setForm(initialFilters)
                     }}
                  >
                     Borrar todos
                  </Button>
               </div>
            </Form>
         </CardBody>
      </Card>
   )
}