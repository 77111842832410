import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardTitle, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Empty } from 'antd'
import moment from 'moment'

import NewQuoteModal from '../../Quotes/partials/NewQuoteModal'

import { getQuotes } from '../../Quotes/services'
import { handleError } from '../../../helpers'

export default function DealQuote({ dealId }) {
    const [quotes, setQuotes] = useState(null)
    const [showNewQuoteModal, setShowNewQuoteModal] = useState(false)

    useEffect(() => {
        !quotes && getQuotes({ 'filter[deal_id]': dealId })
            .then(res => setQuotes(res.data.data))
            .catch(error => handleError(error))
    }, [ quotes, dealId ])

    return (
        <React.Fragment>
            <Card>
				<CardBody>
					<CardTitle>Cotización Asociada</CardTitle>
                    { quotes ? (
                        <React.Fragment>
                            { quotes.length > 0 ? (
                                <React.Fragment>
                                    { quotes.map(quote => (
                                        <Link key={quote.id} to={`/quotes/${quote.id}`}>
                                            <div className='bg-light p-2'>
                                                <div className='text-primary mb-0'>
                                                    <Badge className='mr-2' color={quote.status.color}>{quote.status.label}</Badge>
                                                    Cotización #{quote.sku}
                                                </div>
                                                <p className='mb-0'><small className='text-muted'>Creada el: {moment(quote.created_at).fromNow()}</small></p>
                                                <small className='text-muted'>Vence el: {moment(quote.expired_at).format('YYYY-MMM-DD')}</small>
                                            </div>
                                        </Link>
                                    )) }
                                </React.Fragment>
                            ) : (
                                <Empty 
                                    description="No tiene cotización asociada aún"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    className='mb-0'
                                >
                                    <Button 
                                        outline 
                                        color='primary' 
                                        size="sm" 
                                        onClick={() => setShowNewQuoteModal(true)}
                                    >
                                        Crear Cotización
                                    </Button>
                                </Empty>
                            )}
                        </React.Fragment>
                    ) : (
                        <Spinner size="sm" />
                    )}
                </CardBody>
            </Card>
            { showNewQuoteModal && (
                <NewQuoteModal 
                    visible
                    onClose={() => setShowNewQuoteModal(false)}
                    reload={() => setQuotes(null)}
                    dealId={dealId}
                />
            )}
        </React.Fragment>
    )
}