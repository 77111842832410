import React from 'react'
import Topbar from './partials/Topbar'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'
import ContactsResults from './partials/ContactsResults'
import DealsResults from './partials/DealsResults'
import CompaniesResults from './partials/CompaniesResults'

export default function QuickSearch(props) {
	const term = decodeURI(props.match.params.term)

	return (
		<React.Fragment>
			<Topbar term={term} />
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle>Contactos: <small className="text-muted">Primeros 10 resultados</small></CardTitle>
							<Divider className="mt-2 mb-3" />
							<ContactsResults term={term} />
						</CardBody>
					</Card>
				</Col>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle>Empresas: <small className="text-muted">Primeros 10 resultados</small></CardTitle>
							<Divider className="mt-2 mb-3" />
							<CompaniesResults term={term} />
						</CardBody>
					</Card>
				</Col>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle>Negocios: <small className="text-muted">Primeros 10 resultados</small></CardTitle>
							<Divider className="mt-2 mb-3" />
							<DealsResults term={term} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}