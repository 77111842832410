import React from 'react'
import { Button } from 'reactstrap'

export default function ListTopBar() {

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               Plantillas de Correo
            </h4>
            <div className="page-title-right">
               <Button
                  color="primary"
                  className="topbar-btn"
                  onClick={() => {}}
               >
                  Crear Plantilla Nueva
               </Button>
            </div>
         </div>
      </React.Fragment>
   )
}