import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import {createUser} from './services'
import {handleError} from '../../../helpers'

export default function NewUser({reloadUsers}) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors, reset, watch } = useForm()

   const onSubmit = values => {
      setLoading(true)
      createUser(values)
         .then(() => {
            reloadUsers()
            reset()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Card>
         <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     placeholder="Escribe aquí el nombre"
                     ref={register({ required: true })}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="email"
                     placeholder="Escribe aquí un correo único"
                     ref={register({ required: true })}
                  />
                  { errors.email && <Form.Text className="text-warning">Ingrese un correo válido y único.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Documento <small className="text-muted">(Opcional)</small></Form.Label>
                  <Form.Control
                     name="document"
                     placeholder="Escribe aquí un documento único"
                     ref={register({ })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label>Teléfono de Contacto <small className="text-muted">(Opcional)</small></Form.Label>
                  <Form.Control
                     name="mobile"
                     placeholder="Escribe aquí un número de contacto"
                     ref={register({ })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label>Contraseña <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     type="password"
                     name="password"
                     placeholder="Escribe una contraseña"
                     autoComplete="new-password"
                     ref={register({ required: true, minLength: 6 })}
                  />
                  { errors.password && <Form.Text className="text-warning">Ingresa una constraseña de al menos 6 dígitos.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Confirmar Contraseña <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     type="password"
                     name="password_confirmation"
                     placeholder="Confirme la contraseña de acceso"
                     ref={register({
                        required:true,
                        validate: value => value === watch('password')
                     })}
                  />
                  { errors.password_confirmation && <Form.Text className="text-warning">La contraseña no coincide.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Rol del Usuario <span className="text-danger">*</span></Form.Label>
                  <Form.Check inline label="Super Admin" value="Super Admin" type="radio" name="role" ref={register({required:true})} />
                  <Form.Check inline label="Admin" value="Admin" type="radio" name="role" ref={register({required:true})} />
                  <Form.Check inline label="Asesor" value="Agent" type="radio" name="role" ref={register({required:true})} />
                  { errors.role && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Button color="primary" type="submit" disabled={loading}>Crear Usuario</Button>
            </Form>
         </CardBody>
      </Card>
   )
}