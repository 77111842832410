import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function ProfileMenu() {
    const user = useSelector(state => state.auth)
    const [open, setOpen] = useState(false)    

    return (        
        <Dropdown isOpen={open} toggle={()=>setOpen(!open)} className="d-inline-block" >
            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">                
                <div className="rounded-circle header-profile-user d-xl-inline-block">
                    <span className="avatar-title rounded-circle bg-soft-primary font-size-18">
                        <i className="mdi mdi-account text-primary"></i>
                    </span>
                </div>
                <span className="d-none d-xl-inline-block ml-2 mr-1">{user.user_name}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu right>
                <Link to="/auth/logout" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                    <span>Cerrar Sesión</span>
                </Link>
            </DropdownMenu>
        </Dropdown>
    );
}


