import React, { useState, useEffect } from 'react'
import { Spinner, Row, Col } from 'reactstrap'


import ListTopBar from './partials/ListTopBar'
import EmptyCard from '../../components/EmptyCard'
import TemplateCard from './partials/TemplateCard'

export default function TemplatesList() {
	const [templates, setTemplates] = useState(null)	

	useEffect(()=>{
		!templates && setTemplates([
			{
				 "id": 1,
				 "name": "alias",
				 "html": "<html><head>...</html>\n",
				 "structured_html": null,
				 "created_at": "2020-08-06T12:11:26.000000Z",
				 "updated_at": "2020-08-06T12:11:26.000000Z"
			},
			{
				 "id": 2,
				 "name": "consectetur",
				 "html": "<html><head>...</html>\n",
				 "structured_html": null,
				 "created_at": "2020-08-06T12:11:26.000000Z",
				 "updated_at": "2020-08-06T12:11:26.000000Z"
			},
			{
				 "id": 3,
				 "name": "velit",
				 "html": "<html><head>...</html>\n",
				 "structured_html": null,
				 "created_at": "2020-08-06T12:11:26.000000Z",
				 "updated_at": "2020-08-06T12:11:26.000000Z"
			}
	  ])
	}, [templates])	

	return (
		<React.Fragment>
			<ListTopBar />
			{ templates ? (
				<React.Fragment>
					{ templates.length > 0 ? (
						<Row>
							{ templates.map( template => (
								<Col key={template.id} md="3">
									<TemplateCard template={template} />
								</Col>
							)) }
						</Row>
					) : (
						<EmptyCard 
							description="No tiene plantillas activas" 
							buttonLink="/templates/wizard"
							buttonText="Crear Plantilla Nueva" 
						/>
					)}
				</React.Fragment>
			) : <Spinner size="sm" /> }
		</React.Fragment>
	)
}