import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

import ProfileTopBar from './partials/ProfileTopBar'
import StatCard from '../../components/StatCard'
import { Divider } from 'antd'
import SmsTable from './partials/SmsTable'

export default function SmsProfile() {
	return (
		<React.Fragment>
			<ProfileTopBar 
				backLink="/campaigns/sms" 
				title="Campaña de SMS: WH-2020OCT21EMAIL01" 
			/>
			<Row>
				<Col md="3">
					<Card>
						<CardBody>
							<CardTitle>Detalles Generales</CardTitle>
							<Divider className="mt-2 mb-3" />
							<p className="text-muted mb-0 small">Nombre de Campaña</p>
							<h6>WH-2020OCT21EMAIL01</h6>
							<p className="text-muted mb-0 small">Fecha de Envío</p>
							<h6>2020 Oct 10 21:30</h6>
							<p className="text-muted mb-0 small">Fecha de Creación</p>
							<h6>2020 Oct 1 21:30</h6>
							<p className="text-muted mb-0 small">SMS</p>
							<h6>Lorem ipsum dole ruman herto dolorem ipsum.</h6>
						</CardBody>
					</Card>
					<StatCard title="SMS Enviados" text="1,020" iconClass="fa fa-phone" />
				</Col>
				<Col md="9">
					<Card>
						<CardBody>
							<CardTitle>Registro de SMS</CardTitle>
							<Divider className="mt-2 mb-3" />
							<SmsTable />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}