import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import QuoteProfileTopBar from './partials/QuoteProfileTopBar'
import QuoteDocument from './partials/QuoteDocument'

import { getQuote } from './services'
import { handleError } from '../../helpers'

export default function QuotePreview(props) {
    const quoteId = props.match.params.id
    const [quote, setQuote] = useState(null)

    useEffect(() => {
        !quote && getQuote(quoteId, {
            include: 'deal.contact.user,deal.products,deal.bookings'
        })
            .then(res => setQuote(res.data.data))
            .catch(error => handleError(error))
    }, [ quote, quoteId ])

    return (
        <React.Fragment>
            <QuoteProfileTopBar
                quote={quote}
                reload={() => setQuote(null)}
            />
            <div style={{ width: '220mm' }}>
                <Card>
                    <CardBody>
                        <QuoteDocument quote={quote} />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}