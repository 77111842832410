import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import ContactsList from './ContactsList'
import ContactProfile from './ContactProfile'

export default function Contacts() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/contacts" component={ContactsList} />
            <Route exact path="/contacts/:id" component={ContactProfile} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}