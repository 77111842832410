import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

import SalesTable from './partials/SalesTable'
import ListTopBar from './partials/ListTopBar'
import ExportList from '../../components/ExportList'

import { getDeals } from './services'
import { updatePagination } from './actions'
import { handleError, parseQueryParams, parseResponse } from '../../helpers'

export default function SalesList() {
   const [total, setTotal] = useState(0)
   const stateProps = useSelector(state => state.deals_props)
   const [deals, setDeals] = useState(null)

   const params = { 
      ...stateProps, 
      query: { 
         ...stateProps.query, 
         filters: { ...stateProps.query.filters, state: 'won' }
      }
   }

   useEffect(() => {
      !deals && getDeals(parseQueryParams(params))
         .then(res => {
            setDeals(res.data.data)
            setTotal(res.data.meta.total)
            parseResponse(res, stateProps, updatePagination)
         })
         .catch(error => handleError(error))
   }, [ deals, params, stateProps ])

   return (
      <React.Fragment>
         <ListTopBar 
            currentView={stateProps.listViewType} 
            reload={() => setDeals(null)}
            salesTopbar={true}
         />
         <SalesTable
            deals={deals}
            stateProps={stateProps}
            reload={() => setDeals(null)}
         />
         <ExportList type="deals" params={params} />
         <p className="text-center text-muted my-2">
            <em>Total de {total} registros encontrados</em>
         </p>
      </React.Fragment>
   )
}