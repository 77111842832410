import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import BookingForm from './BookingForm'

import { storeBooking } from '../services'
import { handleError } from '../../../helpers'

export default function NewBookingModal({ visible, onClose, reload, dealId }) {
    const [loading, setLoading] = useState(false)
	const { handleSubmit, register, errors, setValue } = useForm({
		defaultValues: {
            entry_at_date: moment().format('YYYY-MM-DD'),
            exit_at_date: moment().format('YYYY-MM-DD'),
			preentry_hours: 2,
			afterexit_hours: 1,
		}
	})

    const onSubmit = values => {
        setLoading(true)

		let entryAt = moment(`${values.entry_at_date} ${values.entry_at_time}`)
		let exitAt = moment(`${values.exit_at_date} ${values.exit_at_time}`)

        storeBooking({
			...values, 
			deal_id: dealId,
			entry_at: entryAt.format('YYYY-MM-DD HH:mm:ss'),
			start_at: entryAt.subtract(parseInt(values.preentry_hours), 'hours').format('YYYY-MM-DD HH:mm:ss'),
			exit_at: exitAt.format('YYYY-MM-DD HH:mm:ss'),
			end_at: exitAt.add(parseInt(values.afterexit_hours),'hours').subtract(1,'minute').format('YYYY-MM-DD HH:mm:ss'),
		})
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Crear Reservación Nueva</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BookingForm 
							register={register}
							errors={errors}
							setValue={setValue}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{loading && <Spinner animation='border' size="sm" className='mr-5' />}
							Crear Reservación
						</Button>
					</Form>
				</ModalBody>
			</Modal>
        </React.Fragment>
    )
}