import React from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, CardTitle, Spinner, Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { Empty, Tag } from 'antd'

export default function TagManager({ tags, handleDetach, handleAttach }) {
   const { handleSubmit, register, reset } = useForm()

   const onSubmit = values => {
      handleAttach(values)
      reset()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle className="mb-3">Etiquetas</CardTitle>
            { tags ? (
               <React.Fragment>
                  { tags.length > 0 ? (
                     <div className="mb-2">
                        { tags.map(tag => (
                           <Tag key={tag.id} closable onClose={() => handleDetach(tag.id, tag.name)}>
                              {tag.name}
                           </Tag>
                        ))}
                     </div>
                  ) : <Empty description="No tiene etiquetas" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
                  <Form onSubmit={handleSubmit(onSubmit)}>
                     <InputGroup>
                        <Form.Control
                           name="tag"
                           placeholder="Etiqueta nueva"
                           ref={register({ required: true })}
                        />
                        <InputGroup.Append>
                           <Button color="dark">Añadir</Button>
                        </InputGroup.Append>
                     </InputGroup>
                  </Form>
               </React.Fragment>
            ) : <Spinner size="sm" />}
         </CardBody>
      </Card>
   )
}