import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import ProductsList from './ProductsList'

export default function Products() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/products" component={ProductsList} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}