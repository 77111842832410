import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default function ResultCard({ title, link }) {
	return (
		<Link to={link}>
			<Container>
				<div className="float-right">
					<i className="fa fa-external-link-alt" />
				</div>
				{title}
			</Container>
		</Link>
	)
}

const Container = styled.div`
	padding: 10px 15px;
	border: 1px solid #f0f0f0;
	margin-bottom: 5px;
	&:hover {
		background-color: #f8f8f8;
	}
`