import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateCalendar } from '../services'
import { handleError } from '../../../../helpers'

export default function EditCalendar({ visible, onClose, calendar, reload }) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors } = useForm()
   
   const onSubmit = values => {
      setLoading(true)
      updateCalendar(calendar.id, values)
         .then(() => {
            reload()
            onClose()
         })
         .catch(error => handleError(error, setLoading))
   }

   return (      
      <Modal isOpen={visible} toggle={onClose}>
         <ModalHeader toggle={onClose}>Editar Calendario</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre del Calendario <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     defaultValue={calendar.name}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campom es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Descripción del Calendario <small>(opcional)</small></Form.Label>
                  <Form.Control
                     name="description"
                     ref={register({ required: false })}
                     defaultValue={calendar.description}
                     placeholder="Escribe aquí una descripción"
                     as="textarea"
                     style={{ height: '100px' }}
                  />
               </Form.Group>
               <Button color="primary" disabled={loading}>Actualizar Calendario</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}