import React, {useState} from 'react'
import { Row, Col } from 'reactstrap'

import Breadcrumb from '../../../components/Breadcrumb'
import StagesList from './StagesList'
import PipelineList from './PipelinesList'

export default function Pipelines() {   
   const [pipelineSelected, setPipelineSelected] = useState(null)
   const [stages, setStages] = useState(null)

   return (
      <React.Fragment>
         <Breadcrumb title="Ajustes" breadcrumbItem="Embudos de Negocio" />
         <Row>
            <Col md="5">
               <PipelineList pipelineSelected={pipelineSelected} setPipelineSelected={id => { setPipelineSelected(id); setStages(null); }} />
            </Col>
            <Col>
               <StagesList pipelineSelected={pipelineSelected} stages={stages} setStages={setStages} />
            </Col>
         </Row>
      </React.Fragment>
   )
}