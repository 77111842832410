import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'
import moment from 'moment'

import EditCalendarModal from './EditCalendarModal'
import TableActions from '../../../../components/TableActions'

import { deleteCalendar, updateCalendar } from '../services'
import { handleError } from '../../../../helpers'

export default function CalendarsTable({ calendars, reload }) {
    const [editCalendar, setEditCalendar] = useState(null)

    const columns = [
        {
           title: 'Nombre de Calendario',
           dataIndex: 'name',
           sorter: (a, b) => a.name.length - b.name.length,
        },
        {
           title: 'Reservas Asociados',
           dataIndex: 'bookings_count',
           sorter: true,
           render: text => `${text} reservas`
        },
		{
			title: 'Estado',
			dataIndex: 'status',
			render: (t,r) => (
				<React.Fragment>
					<Badge color={t.color}>{t.label}</Badge>
				</React.Fragment>
			)
		},
        {
            title: 'Acciones',
            render: (txt, record) => (
                <TableActions
                    handleEdit={()=>setEditCalendar(record)}
                    handleDelete={() => handleDelete(record.id)}
                    handleActivate={record.status.key === 'inactive' ? () => handleUpdate(record.id, { disabled_at: '' }) : null}
                    handleDisable={record.status.key === 'active' ? () => handleUpdate(record.id, { 
                        disabled_at: moment().format('YYYY-MM-DD HH:mm:ss')
                    }) : null}
                />
            )
        }
    ]

    const handleDelete = id => {
        deleteCalendar(id)
            .then(() => reload())
            .catch(error => handleError(error))
    }

    const handleUpdate = (id, values) => {
        updateCalendar(id, values)
            .then(() => reload())
            .catch(error => handleError(error))
    }

    const expandedRowRender = r => (
        <React.Fragment>
            <p className='mb-0'>
                <strong>Descripción: </strong>
                {r.description}
            </p>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Table 
                columns={columns}
                dataSource={calendars}
                loading={!calendars}
                showSorterTooltip={false}
                rowKey={record => record.id}
                locale={{ emptyText: <Empty description="No se encontraron calendarios" className="mt-3" /> }}
                expandable={{ expandedRowRender }}
                size="small"
            />
            { editCalendar && (
                <EditCalendarModal 
                    visible
                    onClose={() => setEditCalendar(null)}
                    calendar={editCalendar}
                    reload={reload}
                />
            )}
        </React.Fragment>
    )
}