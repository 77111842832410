import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import { updateCategory } from './services'
import { handleError } from '../../../helpers'
import { Form } from 'react-bootstrap'

export default function EditCategory({ category, setShow, reload }) {
   const [loading, setLoading] = useState(false)   
   const { handleSubmit, register, errors } = useForm()
   
   const onSubmit = values => {
      setLoading(true)
      updateCategory(category.id, values)
         .then(() => {
            reload()
            setShow(null)
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   if(!category) return null

   return (      
      <Modal isOpen={category && true} toggle={() => setShow(null)}>
         <ModalHeader toggle={()=>setShow(null)}>Editar Categoría</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre de la Categoría <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="name"
                     ref={register({ required: true })}
                     defaultValue={category.name}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campom es requerido.</Form.Text> }
               </Form.Group>
               <Button color="primary" disabled={loading}>Actualizar Categoría</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}