import React, {useState} from 'react'
import { Card, CardBody, CardTitle, Spinner } from 'reactstrap'
import { Empty } from 'antd'

import { getPipelines } from './services'
import { handleError } from '../../../helpers'

import NewPipeline from './partials/NewPipeline'
import PipelineItem from './partials/PipelineItem'
import EditPipeline from './partials/EditPipeline'

export default function PipelineList({ pipelineSelected, setPipelineSelected }) {
   const [pipelines, setPipelines] = useState(null)
   const [editPipeline, setEditPipeline] = useState(null)

   !pipelines && getPipelines({
      include: 'dealsCount'
   })
      .then(response => setPipelines(response.data.data))
      .catch(error => handleError(error))   

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>Embudos de Negocio</CardTitle>
               <p className="text-muted mb-4">Administra los embudos de negocio</p>
               { pipelines ? (
                  <React.Fragment>
                     { pipelines.length > 0 ? (
                        <React.Fragment>
                           { pipelines.map(pipeline => (
                              <PipelineItem
                                 key={pipeline.id}
                                 pipeline={pipeline} 
                                 pipelineSelected={pipelineSelected} 
                                 setPipelineSelected={setPipelineSelected}
                                 setEditPipeline={setEditPipeline}
                                 reload={()=>setPipelines(null)}  
                              />
                           )) }
                        </React.Fragment>
                     ) : <Empty description="No tiene embudos creados." /> }
                  </React.Fragment>
               ) : <Spinner size="sm" /> }
               <NewPipeline reload={()=>setPipelines(null)} />
            </CardBody>
         </Card>
         <EditPipeline pipeline={editPipeline} setShow={setEditPipeline} reload={()=>setPipelines(null)} />
      </React.Fragment>
   )
}