import React, { useRef } from 'react'
import EmailEditor from 'react-email-editor'
import { Button } from 'reactstrap'
import { Divider } from 'antd';
import { Link } from 'react-router-dom';


export default function TemplateEditor(props) {
	const emailEditorRef = useRef(null)
  
	 return (
		<div>
		  <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
					<Link to="/templates">
						<i className="fa fa-arrow-left" />
					</Link>
               <Divider type="vertical" />
					Plantilla de Correo
            </h4>
            <div className="page-title-right">
					<Button color="dark" outline>Enviar Correo de Prueba</Button>
					<Divider type="vertical" />
					<Button color="dark" outline>Exportar HTML</Button>
					<Divider type="vertical" />
               <Button
                  color="primary"
                  onClick={() => {}}
               >
                  Guardar Plantilla
               </Button>
            </div>
         </div>
		  
		  <EmailEditor
			 ref={emailEditorRef}
			 minHeight="80vh"
		  />
		</div>
	 );
}