import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import QuickSearch from './QuickSearch'

export default function Search() {
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/search/:term" component={QuickSearch} />

				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}