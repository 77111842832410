import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import Categories from './Categories'
import CustomFields from './CustomFields'
import Pipelines from './Pipelines'
import Users from './Users'
import Stores from './Stores'
import CalendarsList from './Calendars/CalendarsList'

export default function Settings() {
   return (
      <MainLayout>
         <Switch>
            <Redirect exact path="/settings" to="/settings/users" />

            <Route exact path="/settings/categories" component={Categories} />
            <Route exact path="/settings/custom_fields" component={CustomFields} />
            <Route exact path="/settings/custom_fields/:model" component={CustomFields} />
            <Route exact path="/settings/pipelines" component={Pipelines} />
            <Route exact path="/settings/users" component={Users} />
            <Route exact path="/settings/stores" component={Stores} />
            <Route exact path="/settings/calendars" component={CalendarsList} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}