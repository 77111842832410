import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import ActivitiesList from './ActivitiesList'

export default function Activities() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/activities" component={ActivitiesList} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}