import s4salesapi from '../../../services/s4salesapi'

export const getUsers = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/users', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getUser = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/users/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createUser = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/users', data)
            .then(response => resolve(response))
            .catch(error => reject(error))            
   })
)

export const updateUser = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/users/${id}`, data) 
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteUser = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/users/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const attachPermission = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/users/${id}/permissions`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const detachPermission = (id, name) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/users/${id}/permissions/${name}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)