import s4salesapi from '../../services/s4salesapi'

export const getContacts = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/contacts', { params })
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const getContact = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/contacts/${id}`, { params })
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const createContact = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/contacts', data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const updateContact = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/contacts/${id}`, data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const deleteContact = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/contacts/${id}`)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const createContactCustomValue = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/contacts/${id}/custom_values`, data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const updateContactCustomValue = (id, cId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/contacts/${id}/custom_values/${cId}`, data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const getContactTags = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/contacts/${id}/tags`)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const attachContactTag = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/contacts/${id}/tags`, data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)

export const detachContactTag = (id, tagId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/contacts/${id}/tags/${tagId}`, data)
         .then(response => resolve(response))
         .catch(error => reject(error))
   })
)