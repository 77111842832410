import React, {useState} from 'react'
import { Row, Col, Spinner } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumb';
import NewUser from './NewUser';
import UsersList from './UsersList';

import { getUsers } from './services'
import { handleError } from '../../../helpers';

export default function Users() {
   const [users, setUsers] = useState(null)

   !users && getUsers({ include: 'roles,permissions' })
      .then(response => setUsers(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <Breadcrumbs title="Ajustes" breadcrumbItem="Administración de Usuarios" />
         <Row>
            <Col md="4">
               <NewUser reloadUsers={()=>setUsers(null)} />
            </Col>
            <Col md="8">
               { users ? (
                  <UsersList 
                     users={users} 
                     reloadUsers={()=>setUsers(null)} 
                  />
               ) : (
                  <Spinner />
               )}
            </Col>
         </Row>
      </React.Fragment>
   )
}