import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import authReducer from '../containers/Authentication/reducer'
import companiesReducer from '../containers/Companies/reducer'
import productsReducer from '../containers/Products/reducer'
import contactsReducer from '../containers/Contacts/reducer'
import activitiesReducer from '../containers/Activities/reducer'
import dealsReducer from '../containers/Deals/reducer'

const authPersistConfig = {
  key: 'token',
  storage: storage
};
 
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  companies_props: companiesReducer,
  contacts_props: contactsReducer,
  products_props: productsReducer,
  activities_props: activitiesReducer,
  deals_props: dealsReducer,
});
 
export default rootReducer;