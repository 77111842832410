import React, {useState} from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import SearchEntity from '../../../components/SearchEntity'

import { handleError } from '../../../helpers'
import { attachCompanyContact } from '../services'

export default function AddContact({ companyId, show, setShow, reload }) {
   const [contactId, setContactId] = useState(null)
   const [loading, setLoading] = useState(false)

   const handleSubmit = () => {
      if(!contactId) message.warning('Seleccione un contacto')

      setLoading(true)

      attachCompanyContact(companyId, contactId)
         .then(() => {
            message.success('Contacto asociado exitosamente.')
            setShow(false)
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <Modal isOpen={show} toggle={() => setShow(false)}>
         <ModalHeader toggle={() => setShow(false)}>Añadir Contacto</ModalHeader>
         <ModalBody>
            <Form>
               <Form.Group>
                  <Form.Label>Buscar por nombre</Form.Label>
                  <SearchEntity 
                     style={{ width: '100%' }}
                     resourceType="contacts"
                     setResourceId={setContactId}
                  />
               </Form.Group>
               <Button color="primary" disabled={loading} onClick={() => handleSubmit()}>Agregar Contacto</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}