import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateStore } from '../services'
import { handleError } from '../../../../helpers'

export default function EditStoreModal({ visible, onClose, reload, store }) {
	const { handleSubmit, register, errors } = useForm()

	if(!store) return null

	const onSubmit = values => {
		if(store.code === values.code) delete values.code
		updateStore(store.id, values)
			.then(()=>{
				message.success("Tienda actualizada exitosamente")
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Editar Tienda</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>				
					<Row>
						<Form.Group as={Col} md="4">
							<Form.Label>Código Único<span className="text-danger">*</span></Form.Label>
							<Form.Control
								name="code"
								defaultValue={store.code}
								placeholder="Código"
								ref={register({ required: true })}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group as={Col} md="8">
							<Form.Label>Nombre de Tienda <span className="text-danger">*</span></Form.Label>
							<Form.Control
								name="name"
								defaultValue={store.name}
								placeholder="Escribe aquí el nombre"
								ref={register({ required: true })}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group as={Col} md="6">
							<Form.Label>Celular de Tienda</Form.Label>
							<Form.Control
								name="mobile"
								defaultValue={store.mobile}
								placeholder="Escriba aquí el celular"
								ref={register({})}
							/>
						</Form.Group>
						<Form.Group as={Col} md="6">
							<Form.Label>Teléfono de Tienda</Form.Label>
							<Form.Control
								name="phone"
								defaultValue={store.phone}
								placeholder="Escribe aquí el teléfono"
								ref={register({})}
							/>
						</Form.Group>
					</Row>
					<Form.Group>
						<Form.Label>Correo Electrónico de Tienda</Form.Label>
						<Form.Control
							name="email"
							defaultValue={store.email}
							type="email"
							placeholder="Escriba aquí el correo"
							ref={register({})}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Dirección de Tienda</Form.Label>
						<Form.Control
							name="address"
							defaultValue={store.address}
							placeholder="Escriba aquí la dirección"
							ref={register({})}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Horarios</Form.Label>
						<Form.Control
							name="schedule"
							as="textarea"
							defaultValue={store.schedule}
							placeholder="Escriba aquí el horario"
							ref={register({})}
						/>
					</Form.Group>					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit">Crear Tienda</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}