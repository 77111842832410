import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Table, Spinner, Button } from 'reactstrap'
import { Empty, message } from 'antd'

import AddContact from './AddContact'
import { detachCompanyContact } from '../services'
import { handleError } from '../../../helpers'

export default function CompanyContacts({ contacts, companyId, reload }) {
   const [showModal, setShowModal] = useState(false)

   const handleDetach = (contactId) => {
      detachCompanyContact(companyId, contactId)
         .then(() => {
            message.success('Contacto desasociado exitosamente.')
            reload()
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle className="mb-3">Contactos</CardTitle>
               { contacts ? (
                  <div className="">
                     { contacts.length > 0 ? (
                        <Table className="table table-centered table-nowrap mb-0">
                           <tbody>
                              { contacts.map(contact => (
                                 <tr key={contact.id}>
                                    <td style={{ width: "30px" }} className="px-0">
                                       <div className="avatar-xs">
                                          <span className="avatar-title rounded-circle bg-soft-info text-info font-size-16">
                                             {contact.fullname ? contact.fullname.substring(0, 2).toUpperCase() : 'SN'}
                                          </span>
                                       </div>
                                    </td>
                                    <td>
                                       <Link to={`/contacts/${contact.id}`} className="text-dark">
                                          <h5 className="font-size-14 m-0">
                                             {contact.fullname ? contact.fullname : <em className="text-muted">Sin nombre</em>}
                                             <br />
                                             {contact.email}
                                          </h5>
                                       </Link>
                                    </td>
                                    <td className="text-right">
                                       <Button size="sm" color="warning" outline onClick={() => handleDetach(contact.id)}>
                                          Quitar
                                       </Button>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </Table>
                     ) : <Empty description="No tiene contactos" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  </div>
               ) : <Spinner size="sm" />}
               <div className="text-center mt-2">
                  <Button color="primary" size="sm" outline onClick={() => setShowModal(true)}>Asociar Contacto</Button>
               </div>
            </CardBody>
         </Card>
         <AddContact show={showModal} setShow={setShowModal} companyId={companyId} reload={reload} />
      </React.Fragment>
   )
}