import s4salesapi from '../../services/s4salesapi'

export const getBookings = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/bookings', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getBooking = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/bookings/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeBooking = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/bookings', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateBooking = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/bookings/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteBooking = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/bookings/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)