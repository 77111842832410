import React from 'react'
import { Card, CardBody, CardTitle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function TemplateCard({ template }) {
	return (
		<Card>
			<CardBody className="p-3">
				<CardTitle className="mb-0">{template.name}</CardTitle>
				<small>Actualizado el: {moment(template.updated_at).format('LLL')}</small>
				<div className="mt-2 pt-2 border-top text-right">
					<Link to={`/templates/${template.id}`}>
						<Button color="outline-dark" size="sm" className="mr-2">Editar Plantilla</Button>
					</Link>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="dark" size="sm">
							<i className="fa fa-caret-down" />
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>Vista Previa</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Eliminar</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			</CardBody>
		</Card>
	)
}