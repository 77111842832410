import React, {useState} from 'react'
import Editable from "react-bootstrap-editable"
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Descriptions, message, Switch, Spin } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import CustomValuesDetails from '../../../components/CustomValuesDetails'

import { updateCompany, createCompanyCustomValue, updateCompanyCustomValue } from '../services'
import { handleError } from '../../../helpers'

const { Item } = Descriptions

export default function CompanyDetails({ company, reload }) {
   const [bordered, setBordered] = useState(false)

   const onSave = (prop, value) => {
      updateCompany(company.id, { [prop]: value })
         .then(() => {
            message.success('Actualizado con éxito')
            reload()
         })
         .catch(error => {
            handleError(error)
            reload()
         })
   }

   const format = value => {
      return (value) ? value.toString() : ''
   }


   const EditValue = ({ text, slug, type = 'textfield' }) => (
      <Editable 
         initialValue={format(text)} 
         onSubmit={val => onSave(slug, val)}
         editText={text ? '' : '-'}
         isValueClickable={text ? true : false}
         type={type}
      />
   )

   return (      
   <React.Fragment>
      <Card>
         <CardBody>
            <CardTitle className="mb-2">Datos de la Empresa</CardTitle>
               <Spin spinning={!company}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Nombre">
                        { company && <EditValue text={company.name} slug="name" /> }
                     </Item>
                     <Item label="Documento">
                        { company && <EditValue text={company.document} slug="document" /> }
                     </Item>
                     <Item label="Correo Principal">
                        { company && <EditValue text={company.main_email} slug="main_email" /> }
                     </Item>
                     <Item label="Celular Principal">
                        { company && <EditValue text={company.main_mobile} slug="main_mobile" /> }
                     </Item>
                     <Item label="Teléfono Principal">
                        { company && <EditValue text={company.main_phone} slug="main_phone" /> }
                     </Item>
                     <Item label="Teléfono Secundario">
                        { company && <EditValue text={company.secundary_phone} slug="secundary_phone" /> }
                     </Item>
                     <Item label="Sitio Web">
                        { company && <EditValue text={company.website} slug="website" /> }
                     </Item>
                     <Item label="Industría">
                        { company && <EditValue text={company.industry} slug="industry" /> }
                     </Item>
                     <Item label="Descripción">
                        { company && <EditValue text={company.description} slug="description" type="textarea" /> }                        
                     </Item>
                     <Item label="Fecha de Creación">{company && moment(company.created_at).format('LLL')}</Item>
                     <Item label="Última Actualización">{company && moment(company.updated_at).format('LLL')}</Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CardTitle className="mb-2">Datos de Facturación</CardTitle>
               <Spin spinning={!company}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Razón Social">
                        { company && <EditValue text={company.business_name} slug="business_name" /> }
                     </Item>
                     <Item label="Dirección Completa">
                        { company && <EditValue text={company.billing_address} slug="billing_address" /> }
                     </Item>
                     <Item label="Ciudad">
                        { company && <EditValue text={company.billing_address_city} slug="billing_address_city" /> }
                     </Item>
                     <Item label="Departamento">
                        { company && <EditValue text={company.billing_address_state} slug="billing_address_state" /> }
                     </Item>
                     <Item label="País">
                        { company && <EditValue text={company.billing_address_country} slug="billing_address_country" /> }
                     </Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CustomValuesDetails 
                  resourceType="companies" 
                  resource={company} 
                  bordered={bordered}
                  createService={createCompanyCustomValue}
                  updateService={updateCompanyCustomValue}
                  reload={reload}
               />
            </CardBody>
         </Card>
         <div className="hide-sm">
            <Switch size="small" checked={!bordered} onChange={() => setBordered(!bordered)} />{" "}
            Modo Compacto
         </div>
      </React.Fragment>
   )
}