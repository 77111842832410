import React, {useState} from 'react'
import { Spinner, Modal, ModalHeader, Row, Col, ModalBody } from 'reactstrap'
import moment from 'moment'

import { getContact } from '../services'
import { handleError } from '../../../helpers'
import StatCard from '../../../components/StatCard'

export default function ContactStats({ show, close, contactId }) {
	const [stats, setStats] = useState(null)

	!stats && contactId && getContact(contactId, { 
		'fields[contacts]': 'id,fullname',
		append: 'average_sale_value,average_sale_items,first_sale_date,last_sale_date,total_sales_value,total_sales_count,products_buyed_names'
	}).then(res => setStats(res.data.data))
		.catch(error => handleError(error))

	const handleClose = () => {
		setStats(null)
		close()
	}

	return (
		<React.Fragment>
			<Modal toggle={handleClose} isOpen={show} size="lg">
				<ModalHeader toggle={handleClose}>Estadísticas { stats ? stats.fullname : 'del Contacto'}</ModalHeader>
				<ModalBody>
					{stats ? (
						<React.Fragment>
							<Row>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-cash-register" 
										text={stats.total_sales_count} 
										title="Ventas Registradas" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-money-bill-alt" 
										text={`$ ${stats.total_sales_value ? stats.total_sales_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}`} 
										title="Total en Ventas" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-calendar-alt" 
										text={stats.first_sale_date ? moment(stats.first_sale_date).format('Y-MM-DD HH:mm') : 'Sin Ventas'} 
										title="Fecha de Primera Venta" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-calendar-alt" 
										text={stats.last_sale_date ? moment(stats.last_sale_date).format('Y-MM-DD HH:mm') : 'Sin Ventas'} 
										title="Fecha de Última Venta" 
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-hand-holding-usd" 
										text={`$ ${stats.average_sale_value ? round(stats.average_sale_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}`}
										title="Promedio de Compra"
									/>
								</Col>
								<Col sm="6">
									<StatCard 
										iconClass="fa fa-box" 
										text={stats.average_sale_items ? stats.average_sale_items : 0}
										title="Promedio de Items por Venta"
									/>
								</Col>
								<Col sm="12">
									<StatCard 
										iconClass="fa fa-boxes" 
										text={stats.products_buyed_names ? stats.products_buyed_names : 'Sin Ventas'}
										title="Productos Comprados"
									/>
								</Col>
							</Row>
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}

const round = number => {
	return Math.round((number + Number.EPSILON) * 1) / 1
}