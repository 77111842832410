import React, { useEffect, useState } from 'react'
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Descriptions, Popconfirm, message } from 'antd'
import moment from 'moment'

import { getBooking, updateBooking } from '../services'
import { handleError } from '../../../helpers'
import { Link } from 'react-router-dom'

const { Item } = Descriptions

export default function BookingProfileModal({ visible, onClose, bookingId, reload }) {
    const [booking, setBooking] = useState(null)
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        !booking && getBooking(bookingId, {
            include: 'deal.contact.user,calendar.product'
        })
            .then(res => setBooking(res.data.data))
            .catch(error => handleError(error))
    }, [ bookingId, booking ])

    const handleUpdate = values => {
        updateBooking(bookingId, values)
            .then(res => {
                message.success(res.data.message)
                setHasChanged(true)
                setBooking(null)
            })
            .catch(error => handleError(error))
    }

    const handleClose = () => {
        if(hasChanged)
            reload()

        onClose()
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={handleClose} size='lg'>
                <ModalHeader toggle={handleClose}>
                    { booking && <Badge color={booking.status.color}>{booking.status.label}</Badge> }
                    {" "}Reserva #{bookingId}
                </ModalHeader>
                <ModalBody>
                    { booking ? (
                        <React.Fragment>                            
                            <Descriptions bordered column={2} size="small">
                                <Item label="Calendario" span={2}>{booking.calendar.name}</Item>
                                <Item label="Producto Asociado" span={2}>{booking.calendar.product.name}</Item>
                                <Item label="Referencia del Negocio" span={2}>
                                    {booking.deal.reference}{" "}
                                    <Link to={`/deals/${booking.deal.id}`}>
                                        <small className='text-link'>Ver perfil del negocio</small>
                                    </Link>
                                </Item>
                                <Item label="Nombre del Contacto" span={2}>
                                    {booking.deal.contact.fullname}{" "}
                                    <Link to={`/contacts/${booking.deal.contact.id}`}>
                                        <small className='text-link'>Ver perfil del contacto</small>
                                    </Link>
                                </Item>
                            </Descriptions>
                            <Descriptions column={2} size="small" className='mt-3 mb-3'>
                                <Item label="Reservado desde">{booking.start_at}</Item>
                                <Item label="Reservado hasta">{booking.end_at}</Item>
                                <Item label="Ingreso">{booking.entry_at} ({moment(booking.entry_at).fromNow()})</Item>
                                <Item label="Salida">{booking.exit_at} ({moment(booking.exit_at).fromNow()})</Item>
                            </Descriptions>
                            <Descriptions bordered column={2} size="small">
                                <Item label="Observaciones" span={2}>
                                    {booking.observations ? booking.observations : <em>Sin observaciones</em>}
                                </Item>
                            </Descriptions>
                            <div className='mt-3 text-right'>
                                { booking.status.key !== 'voided' ? (
                                    <Popconfirm
                                        title="¿Seguro desea cancelar esta reserva?"
                                        okText="Cancelar"
                                        cancelText="Volver atrás"
                                        okButtonProps={{ danger:true }}
                                        onConfirm={() => handleUpdate({ voided_at: moment().format('YYYY-MM-DD HH:mm:ss') })}
                                    >
                                        <Button color='danger' className='ml-2'>Cancelar Reserva</Button>
                                    </Popconfirm>
                                ) :  (
                                    <Popconfirm
                                        title="¿Seguro desea reanudar esta reserva?"
                                        okText="Reanudar"
                                        cancelText="Cancelar"
                                        okButtonProps={{ danger:true }}
                                        onConfirm={() => handleUpdate({ voided_at: '' })}
                                    >
                                        <Button color='info' className='ml-2'>Reanudar Reserva</Button>
                                    </Popconfirm>
                                )}
                                { (!booking.approved_at && booking.status.key !== 'voided') && (
                                    <Popconfirm
                                        title="¿Seguro desea confirmar esta reserva?"
                                        okText="Confirmar"
                                        cancelText="Cancelar"
                                        onConfirm={() => handleUpdate({ approved_at: moment().format('YYYY-MM-DD HH:mm:ss') })}
                                    >
                                        <Button color='success' className='ml-2'>Confirmar Reserva</Button>
                                    </Popconfirm>
                                )}
                            </div>
                        </React.Fragment>
                    ) : (
                        <Spinner animation='border' />
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}