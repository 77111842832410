import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Badge } from 'reactstrap'
import { Popconfirm, message } from 'antd'

import { deleteDeal, updateDeal } from '../services'
import { handleError } from '../../../helpers'

const ProfileTopBar = ({ title, dealId, history, state, reload }) => {

   const handleDelete = () => {
      deleteDeal(dealId)
         .then(() => {
            message.success('Negocio eliminado exitosamente.')
            history.push('/deals')
         })
         .catch(error => handleError(error))
   }

   const handleUpdate = data => {
      updateDeal(dealId, data)
         .then(() => {
            message.success('Negocio actualizado exitosamente')
            reload()
         })
         .catch(error => {
            handleError(error)
            reload()
         })
   }

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               <span className="hide-sm">Perfil del Negocio</span>{" "}
               {title && ` - ${title}`}{" "}               
               <br />
               { state && (
                  <React.Fragment>
                     { state === 'draft' && <Badge color="dark">Negocio Sugerido</Badge> }
                     { state === 'active' && <Badge color="info">Negocio Activo</Badge> }
                     { state === 'won' && <Badge color="success">Negocio Ganado</Badge> }
                     { state === 'lost' && <Badge color="danger">Negocio Perdido</Badge> }
                  </React.Fragment>
               )}
            </h4>
            <div className="page-title-right">
               <Popconfirm
                  title="¿Esta seguro de eliminar?"
                  onConfirm={()=> handleDelete()}
                  okText="Eliminar"
                  cancelText="Cancelar"
                  placement="bottomRight"
               >
                  <Button color="danger" outline>
                     <i className="fa fa-trash mr-2"></i>
                     Eliminar
                  </Button>
               </Popconfirm>
               { state && (
                  <React.Fragment>
                     { state === 'active' && (
                        <Popconfirm
                           title="Marcar como Perdido"
                           onConfirm={()=> handleUpdate({ state: 'lost' })}
                           okText="Confirmar"
                           cancelText="Cancelar"
                           placement="bottomRight"
                        >
                           <Button color="danger" className="ml-2">
                              Negocio Perdido
                           </Button>
                        </Popconfirm>
                     )}
                     { state === 'active' && (
                        <Popconfirm
                           title="Marcar como Ganado"
                           onConfirm={()=> handleUpdate({ state: 'won' })}
                           okText="Confirmar"
                           cancelText="Cancelar"
                           placement="bottomRight"
                        >
                           <Button color="primary" className="ml-2">
                              Negocio Ganado
                           </Button>
                        </Popconfirm>
                     )}
                     { state !== 'active' && (
                        <Popconfirm
                           title="Marcar como Activo"
                           onConfirm={()=> handleUpdate({ state: 'active' })}
                           okText="Reanudar"
                           cancelText="Cancelar"
                           placement="bottomRight"
                        >
                           <Button color="info" className="ml-2">
                              Reanudar Negocio
                           </Button>
                        </Popconfirm>
                     )}
                  </React.Fragment>
               )}
                                          
            </div>
         </div>
      </React.Fragment>
   )
}

export default withRouter(ProfileTopBar)