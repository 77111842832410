import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import ActivitiesTable from './partials/ActivitiesTable'
import ActivitiesCalendar from './partials/ActivitiesCalendar'
import ActivitiesFilters from './partials/ActivitiesFilters'
import NewActivity from './partials/NewActivity'
import EditActivity from './partials/EditActivity'
import ListTopBar from './partials/ListTopBar'

import StatCard from '../../components/StatCard'

import { getActivities } from './services'
import { updatePagination } from './actions'
import { handleError, parseQueryParams, parseResponse } from '../../helpers'

export default function ActivitiesList() {
   const [activities, setActivities] = useState(null)
   const stateProps = useSelector(state => state.activities_props)

   useEffect(() => {
      !activities && getActivities( parseQueryParams(stateProps) )
         .then(res => {
            setActivities(res.data.data)
            parseResponse(res, stateProps, updatePagination)
         })
         .catch(error => handleError(error))
   }, [activities, stateProps])

   const handleReload = () => setActivities(null)

   return (
      <React.Fragment>
         <ListTopBar currentView={stateProps.listViewType} reload={handleReload} />
         <Row>
            <Col lg="3" md="4" className="hide-md">
               <ActivitiesFilters reload={handleReload} />
               <StatCard
                  title="Actividades encontradas"
                  text={stateProps.pagination.total}
                  iconClass="bx bx-buildings"
               />
            </Col>
            <Col lg="9" md="8">
               { stateProps.listViewType === 'table' && (
                  <ActivitiesTable
                     activities={activities}
                     stateProps={stateProps}
                     reload={handleReload}
                  />
               )}
               { stateProps.listViewType === 'calendar' && (
                  <ActivitiesCalendar 
                     activities={activities}
                     stateProps={stateProps}
                     reload={handleReload}
                  />
               )}
            </Col>
         </Row>
         <NewActivity reload={handleReload} />
         <EditActivity reload={handleReload} />
      </React.Fragment>
   )
}