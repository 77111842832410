import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import StatCard from '../../../components/StatCard'

export default function ListVerification() {
	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<StatCard title="Correos Verificados" text="1,201" iconClass="fa fa-envelope" />
				</Col>
				<Col md="3">
					<StatCard title="Correos Entregables" text="1,201" iconClass="fa fa-check" />
				</Col>
				<Col md="3">
					<StatCard title="Correos No Entregables" text="1,201" iconClass="fa fa-times" />
				</Col>
				<Col md="3">
					<StatCard title="Pendientes de Verificar" text="1,201" iconClass="fa fa-hourglass" />
				</Col>
			</Row>
			<h5 className="mt-4 mb-1">Lista de verificacion</h5>
			<p>Para verificar su lista, elija un servicio de verificación a continuación y haga clic en el botón Verificar</p>
			<Button color="primary">Iniciar proceso de verificación</Button>
		</React.Fragment>
	)
}