import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateSegment } from '../services'
import { handleError } from '../../../helpers'

export default function EditSegmentModal({ visible, onClose, segment }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		updateSegment(segment.id, values)
			.then(() => {
				message.success('Segmento actualizado exitosamente')
				onClose(true)
			})
			.catch(error => handleError(error))
	}

	if(!segment) return null

	return (
		<React.Fragment>
			<Modal toggle={()=>onClose(false)} isOpen={visible}>
				<ModalHeader toggle={()=>onClose(false)}>Actualizar Segmento</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Nombre del Segmento</Form.Label>
							<Form.Control 
								name="name"
								defaultValue={segment.name}
								ref={register({ required: true })}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Contexto del Segmento</Form.Label>
							<Form.Control
								as="select"
								name="scope"
								defaultValue={segment.scope}
								ref={register({})}
							>
								<option value="">Todos los Contactos</option>
								<option value="clients">Todos los Clientes</option>
								<option value="prospects">Todos los Prospectos</option>
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Label>Descripción Corta</Form.Label>
							<Form.Control
								as="textarea"
								name="description"
								defaultValue={segment.description}
								ref={register({ required: true })}
							/>
							{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<div className="text-right mt-2">
							<Button color="primary">Actualizar Segmento</Button>
						</div>						
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}