import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function ListTopBar({ title, ctaText, ctaLink }) {

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               {title}
            </h4>
            <div className="page-title-right">
               <Link to={ctaLink}>
                  <Button color="primary" className="topbar-btn">
                     {ctaText}
                  </Button>
               </Link>
            </div>
         </div>
      </React.Fragment>
   )
}