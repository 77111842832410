import React, { useState } from 'react'
import { Empty, Divider, Popconfirm, message} from 'antd'
import { Spinner, Table, Button } from 'reactstrap'

import AttachProductModal from './AttachProductModal'

import { detachDealProduct } from '../services'
import { handleError } from '../../../helpers'

export default function DealProducts({ products, dealId, reload }) {
   const urlParams = new URLSearchParams(window.location.search)
   const [showProductsModal, setShowProductsModal] = useState(urlParams.get('showProductsModal') === 'true')

   const handleDetach = id => {
      detachDealProduct(dealId, id)
         .then(() => {
            message.success('Producto eliminado')
            reload()
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         { products ? (
            <div className="">
               { products.length > 0 ? (
                  <Table className="table table-centered table-nowrap mb-0">
                     <tbody>
                        { products.map(product => (
                           <tr key={product.id}>
                              <td style={{ width: "30px" }} className="px-0">
                                 <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-soft-info text-info font-size-16">
                                       {product.name ? product.name.substring(0, 2).toUpperCase() : 'SN'}
                                    </span>
                                 </div>
                              </td>
                              <td>
                                 <h5 className="font-size-14 m-0">
                                    <span className="text-dark">{product.name}</span>
                                    <br />
                                    <span className="text-muted">
                                       Cantidad: <span className="text-dark">{product.pivot.quantity}</span>
                                       <Divider type="vertical" />
                                       Valor: <span className="text-dark">$ {product.pivot.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </span>
                                 </h5>
                              </td>
                              <td className="text-right">
                                 <Popconfirm
                                    title="Quitar producto del negocio"
                                    onConfirm={()=> handleDetach(product.id)}
                                    okText="Eliminar"
                                    cancelText="Cancelar"
                                 >
                                    <Button color="" outline size="sm">
                                       <i className="fa fa-trash"></i>
                                    </Button>
                                 </Popconfirm>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               ) : <Empty description="No tiene productos" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
               <div className="text-right mt-2">
                  <Button color="primary" outline size="sm" onClick={() => setShowProductsModal(true)}>
                     Agregar o Editar Producto
                  </Button>                  
               </div>
            </div>
         ) : <Spinner size="sm" />}
         
         { showProductsModal && (
            <AttachProductModal 
               visible
               onClose={() => setShowProductsModal(false)}
               dealId={dealId} 
               reload={reload}
            />
         )}
      </React.Fragment>
   )
}