import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import ContactsTable from './partials/ContactsTable'
import ContactsFilters from './partials/ContactsFilters'
import NewContact from './partials/NewContact'
import ListTopBar from './partials/ListTopBar'

import StatCard from '../../components/StatCard'
import ExportList from '../../components/ExportList'

import { getContacts } from './services'
import { handleError, parseQueryParams, parseResponse } from '../../helpers'
import { updatePagination } from './actions'

export default function ContactsList() {
   const stateProps = useSelector(state => state.contacts_props)
   const [contacts, setContacts] = useState(null)
   const [total, setTotal] = useState(0)

   useEffect(() => {
      !contacts && getContacts( parseQueryParams(stateProps) )
         .then(res => {
            setContacts(res.data.data)
            setTotal(res.data.meta.total)
            parseResponse(res, stateProps, updatePagination)
         })
         .catch(error => handleError(error))
   }, [ contacts, stateProps ])

   return (
      <React.Fragment>
         <ListTopBar currentView={stateProps.listViewType} reload={() => setContacts(null)} />
            { stateProps.listViewType === 'table' && (
               <Row>
                  <Col lg="3" md="4" className="hide-md">
                     <ContactsFilters reload={() => setContacts(null)} />
                     <StatCard
                        title="Contactos encontrados"
                        text={total}
                        iconClass="bx bx-buildings"
                     />
                  </Col>
                  <Col lg="9" md="8">
                     <ContactsTable
                        contacts={contacts}
                        stateProps={stateProps}
                        reload={() => setContacts(null)}
                     />
                     <ExportList type="contacts" params={stateProps} />
                  </Col>
               </Row>
            )}
         <NewContact />
      </React.Fragment>
   )
}