import React from 'react'
import { Table } from 'antd'

export default function SmsTable() {

	const columns = [
		{
			title: 'SMS'
		},
		{
			title: 'Destinatario'
		},
		{
			title: 'Estado'
		},
		{
			title: 'Acciones'
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={[]}
				columns={columns}
			/>
		</React.Fragment>
	)
}