import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Empty } from 'antd'

import { getDeals } from '../../Deals/services'
import { handleError } from '../../../helpers'
import ResultCard from './ResultCard'

export default function DealsResults({ term }) {
	const [deals, setDeals] = useState(null)

	useEffect(()=>{
		!deals && getDeals({ "filter[reference]": term })
			.then(res => setDeals(res.data.data))
			.catch(error => handleError(error))
	}, [deals, term])

	if(!deals) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ deals.length > 0 ? (
				<React.Fragment>
					{ deals.map(deal => (
						<ResultCard
							key={deal.id}
							title={deal.reference}
							link={`/deals/${deal.id}`}
						/>
					)) }
				</React.Fragment>
			) : <Empty description="No se encontraron negocios" />}
		</React.Fragment>
	)
}