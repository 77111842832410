import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import SegmentsList from './SegmentsList'
import SegmentsMarket from './SegmentsMarket'
import SegmentProfile from './SegmentProfile'
import SegmentWizard from './SegmentWizard'

export default function Segments() {
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/segments" component={SegmentsList} />
				<Route exact path="/segments/wizard" component={SegmentWizard} />
				<Route exact path="/segments/market" component={SegmentsMarket} />
				<Route exact path="/segments/:id" component={SegmentProfile} />				

				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}