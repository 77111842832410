import React from 'react'
import { Card, CardBody, Spinner, CardTitle, Button } from 'reactstrap'
import { Timeline, Empty, Popover } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

const { Item } = Timeline

export default function LogFeed({ logs }) {

   const getLogDetails = log => {
      let message = ''
      for (let [key, value] of Object.entries(log.properties.attributes)) {
         if(key !== 'updated_at'){
            message += `El campo [${key}] cambio de (${log.properties.old[key]}) a (${value})`
         }
      }
      return message
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Historial</CardTitle>
            <ScrollContainer>
               { logs ? (
                  <Timeline>
                     { logs.length > 0 ? (
                        <React.Fragment>
                           { logs.map(log => (
                              <Item key={log.id} color="gray">
                                 <span className="text-muted">
                                    [ {moment(log.created_at).format('DD MMM YYYY hh:mm A')} ]
                                 </span>{" "}
                                 {log.description}{" "}
                                 {log.causer && <em className="text-muted small">{log.causer.name}</em>}
                                 {log.properties.old && (
                                    <Popover content={getLogDetails(log)}>
                                       <Button size="sm" color="soft-primary">+</Button>
                                    </Popover>
                                 )}
                              </Item>
                           ))} 
                        </React.Fragment>
                     ) :  <Empty description="No tienes registros de actividad" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  </Timeline>
               ): <Spinner size="sm" />}
            </ScrollContainer>
         </CardBody>
      </Card>
   )
}

const ScrollContainer = styled.div`
   max-height: 300px;
   overflow: hidden;
   overflow-y: scroll;
   padding: 15px 0px;
`