import React, {useState} from 'react'
import { Select } from 'antd';

import {getProducts} from '../../Products/services'
import { handleError, parseQueryParams } from '../../../helpers';

export default function SearchProduct({ setProduct, setPvalue, qty }) {
   const [data, setData] = useState([])
   const [value, setValue] = useState('')

   React.useEffect(() => {
      setData([])
      setValue('')
    }, []);

   const search = term => {
      let request;
      let productsParams = parseQueryParams({ page: 1, per_page: 10, query: { sort: 'name', filters: { name: term }, fields: { products: 'name,id,default_value' } } })
      request = getProducts(productsParams)

      if(request) request.then(response => setData(response.data.data)).catch(error => handleError(error))
      else setData([])
   }

   return (
      <Select
         showSearch
         value={value}
         style={{ width: '100%' }}
         defaultActiveFirstOption={false}
         placeholder="Buscar por nombre..."
         showArrow={false}
         filterOption={false}
         onSearch={value => value ? search(value) : setData([])}
         onChange={value => { 
            setValue(value)
            let product = data.filter(pd => pd.id === value)
            setPvalue(product[0].default_value * qty)
            setProduct(product[0])
         }}
         notFoundContent="Sin resultados"
      >
         {data.map(d => (
            <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
         ))}
      </Select>
   );
}