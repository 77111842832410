import s4salesapi from '../../services/s4salesapi'

export const generalReport = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/reports/general', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const usersReport = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/reports/users', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)