import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../../components/ServerSideSelect'

import { storeCalendar } from '../services'
import { searchProducts } from '../../../Products/services'
import { handleError } from '../../../../helpers'

export default function NewCalendarForm({ reload }) {
    const [loading, setLoading] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState([])
    const { handleSubmit, register, errors, reset, setValue } = useForm()
   
    const onSubmit = values => {
        setLoading(true)
        storeCalendar(values)
            .then(() => {
                reload()
                setSelectedProduct([])
                reset()
            })
            .catch(error => handleError(error))
            .finally(() => setLoading(false))
    }

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Producto Asociado <span className="text-danger">*</span></Form.Label>
                  <ServerSideSelect
                    value={selectedProduct}
                    placeholder="Buscado por nombre"
                    fetchOptions={searchProducts}
                    className="full-width"
                    onChange={value => {
                        setSelectedProduct(value)
                        setValue('product_id', value.value)
                    }}
                />
                  <Form.Control name="product_id" ref={register({ required: true })} style={{ display:'none' }} />
                  { errors.product_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Nombre del Calendario <span className="text-danger">*</span></Form.Label>
                  <Form.Control 
                     name="name"
                     placeholder="Escribe aquí el nombre"
                     ref={register({ required: true })}
                  />
                  { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Descripción <small>(opcional)</small></Form.Label>
                  <Form.Control 
                     name="description"
                     placeholder="Escribe aquí una descripción"
                     ref={register({ required: false })}
                     as="textarea"
                     style={{ height: '100px' }}
                  />
               </Form.Group>
               <Button color="primary" type="submit" disabled={loading}>Crear Calendario</Button> 
            </Form>
        </React.Fragment>
    )
}