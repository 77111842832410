import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import BookingsList from './BookingsList'

export default function Bookings() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/bookings" component={BookingsList} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}