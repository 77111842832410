import React, { useState } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Table, Descriptions, message } from 'antd'
import TableActions from '../../../../components/TableActions'
import { deleteStore } from '../services'
import { handleError } from '../../../../helpers'
import EditStoreModal from './EditStoreModal'

export default function StoresTable({ stores, reload }) {
	const [editStore, setEditStore] = useState(null)

	const columns = [
		{
			title: 'Código',
			dataIndex: 'code'
		},
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Télefono',
			dataIndex: 'mobile'
		},
		{
			title: 'Télefono secundario',
			dataIndex: 'phone'
		},
		{
			title: 'Acciones',
			dataIndex: 'actions',
			render: (t,record) => (
				<TableActions
					handleEdit={()=>setEditStore(record)}
					handleDelete={() => handleDelete(record.id)}
				/>
			)
		}
	]

	const handleDelete = id => {
		deleteStore(id)
			.then(()=>{
				message.success("Sede eliminada exitosamente")
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle className="mb-0">Sedes</CardTitle>
               <p className="text-muted mb-4">Listado completo de sedes</p>
               <div className="table-responsive">
						<Table
							dataSource={stores}
							rowKey={record => record.id}
							columns={columns}
							expandable={{
								expandedRowRender: record => (
									<Descriptions size="small" column={2}>
										<Descriptions.Item label="Correo de Tienda">{record.email}</Descriptions.Item>
										<Descriptions.Item label="Dirección">{record.address}</Descriptions.Item>
										<Descriptions.Item label="Horario de Atención">{record.schedule}</Descriptions.Item>
									</Descriptions>	
								),
							 }}
						/>
					</div>
				</CardBody>
			</Card>

			<EditStoreModal 
				visible={editStore ? true : false}
				onClose={()=>setEditStore(null)}
				reload={()=>{ setEditStore(null); reload() }}
				store={editStore}
			/>
		</React.Fragment>
	)
}