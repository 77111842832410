import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

const localizer = momentLocalizer(moment)

function BookingsCalendar({ bookings, setBookingModal, onRangeChange }) {
	return (
		<React.Fragment>
			<Card className='no-box-shadow p-0'>
				<CardBody className='p-0'>
					<Calendar
						localizer={localizer}
						events={formatEvents(bookings)}
						startAccessor="start"
						endAccessor="end"
						style={{ height: 600 }}
						messages={calendarMessages}
						views={['month', 'week', 'day']}
						onSelectEvent={event => setBookingModal(event.id)}
						onRangeChange={onRangeChange}
						eventPropGetter={eventStyleGetter}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const calendarMessages = {
	"today": "Hoy", 
	"previous": "Anterior", 
	"next": "Siguiente",
	"month": "Mes",
	"week": "Semana",
	"year": "Año",
	"day": "Día",
}

const formatEvents = bookings => {
	if(!bookings) 
		return []

	const events = bookings.map(booking => {
		return {
			title: `${booking.calendar.name} (${booking.deal.contact.fullname})`,
			start: moment(booking.start_at).toDate(),
			end: moment(booking.end_at).toDate(),
			id: booking.id,
			backgroundColor: booking.status.hex,
		}
	})

	return events
}

const eventStyleGetter = (event) => {
	var style = {
		backgroundColor: event.backgroundColor,
        borderRadius: '2px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
	}
	
	return { style }
}

export default withRouter(BookingsCalendar)