import React, {useState} from 'react'
import { Row, Col } from 'reactstrap'

import LogFeed from '../../components/LogFeed'
import ResourceActivities from '../../components/ResourceActivities'
import ResourceTags from '../../components/ResourceTags'
import ProfileTopBar from './partials/ProfileTopBar'
import DealDetails from './partials/DealDetails'
import DealStages from './partials/DealStages'
import DealStore from './partials/DealStore'
import DealQuote from './partials/DealQuote'

import { getDeal, getDealTags, attachDealTag, detachDealTag } from './services'
import { handleError } from '../../helpers'
import DealBooking from './partials/DealBooking'

export default function DealProfile(props) {
   const [deal, setDeal] = useState(null)
   const dealId = props.match.params.id

   !deal && getDeal(dealId, { include: 'customValues,contact,stage,stage.pipeline,stage.pipeline.stages,logs,logs.causer,products', append: 'total_items,total_value' })
      .then(response => setDeal(response.data.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <ProfileTopBar 
            title={deal && deal.reference} 
            dealId={dealId} 
            state={deal && deal.state}
            reload={() => setDeal(null)}
         />
         <DealStages deal={deal} reload={() => setDeal(null)} />
         <Row>
            <Col md="4">
               <DealDetails dealId={dealId} deal={deal} reload={() => setDeal(null)} />
            </Col>
            <Col md="8">               
               <ResourceActivities resourceType="deals" resourceId={dealId} />               
               <Row>
                  <Col md="8">
                     <LogFeed logs={deal && deal.logs} />
                  </Col>
                  <Col md="4">
                     <DealQuote dealId={dealId} />
                     <DealBooking dealId={dealId} />
                     <ResourceTags
                        resourceId={dealId} 
                        getService={getDealTags} 
                        attachService={attachDealTag} 
                        detachService={detachDealTag} 
                     />
                     <DealStore 
                        dealId={dealId}
                        storeId={deal && deal.store_id}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      </React.Fragment>
   )
}