import s4salesapi from '../../../services/s4salesapi'

export const getStores = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/stores', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getStore = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/stores/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createStore = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/stores', data)
            .then(response => resolve(response))
            .catch(error => reject(error))            
   })
)

export const updateStore = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/stores/${id}`, data) 
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteStore = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/stores/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)