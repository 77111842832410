import s4salesapi from '../../services/s4salesapi'

export const login = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/auth/login', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const forgot = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/auth/forgot', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const logout = () => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/auth/logout')
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const resetPing = token => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/auth/reset/${token}`)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const reset = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/auth/reset', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)