import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { setQuery } from '../actions'

export default function ProductsFilters({ reload, categories }) {   
   const { handleSubmit, register, reset } = useForm()
   const filters = useSelector(state => state.products_props.query.filters)
   const dispatch = useDispatch()

   const setFilters = newFilters => {
      dispatch(setQuery({ filters: newFilters }))
      reload()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Búsqueda Avanzada</CardTitle>
            <p className="text-muted mb-4">Filtros Múltiples</p>
            <Form onSubmit={handleSubmit(values => setFilters(values))}>
               <Form.Group>
                  <Form.Control
                     name="name"
                     placeholder="Filtrar por nombre"
                     defaultValue={filters && filters.name}
                     ref={register({})}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     name="sku"
                     placeholder="Filtrar por SKU"
                     defaultValue={filters && filters.sku}
                     ref={register({})}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Producto Destacado</Form.Label>
                  <Form.Check inline label="Si" type="radio" name="is_featured" value="1" ref={register({})} />
                  <Form.Check inline label="No" type="radio" name="is_featured" value="0" ref={register({})} />
                  <Form.Check inline label="Sin Filtro" type="radio" name="is_featured" value="" ref={register({})} defaultChecked={true} />
               </Form.Group>
               <Form.Group>
                  <Form.Label className="block">Producto Recurrente</Form.Label>
                  <Form.Check inline label="Si" type="radio" name="is_subscription" value="1" ref={register({})} />
                  <Form.Check inline label="No" type="radio" name="is_subscription" value="0" ref={register({})} />
                  <Form.Check inline label="Sin Filtro" type="radio" name="is_subscription" value="" ref={register({})} defaultChecked={true} />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     name="category_id"
                     as="select"
                     defaultValue={filters && filters.category_id}
                     ref={register({})}
                  >
                     <option value="">:: Cualquier categoría ::</option>
                     { categories && categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                     ))}
                  </Form.Control>
               </Form.Group>
               <Button color="primary">Realizar Búsqueda</Button>
               <div className="mt-2">
                  <Button 
                     color="warning" 
                     outline 
                     size="sm" 
                     onClick={() => {
                        setFilters({})
                        reset()
                     }}
                  >
                     Borrar todos
                  </Button>
               </div>
            </Form>
         </CardBody>
      </Card>
   )
}