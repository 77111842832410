import axios from "axios";

/*
 * Instancia con el servicio principal del backend
 */
const s4adminapi = () => {
   // var url = window.location.host.split('.')
   // let domain = (url[1].includes('localhost')) ? 's4sales_adminapi.test' : 'adminapi.smart4sales.com'
   
   let domain = 'adminapi.smart4sales.com'

   return axios.create({
      baseURL: `https://${domain}/api`,
      timeout: 10000,
      headers: {
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default s4adminapi;
