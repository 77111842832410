import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { showNewModal } from '../actions'
import { createContact } from '../services'
import { handleError } from '../../../helpers'

function NewContact({ history }) {
   const show = useSelector(state => state.contacts_props.showNewModal)
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors } = useForm()
   const dispatch = useDispatch()

   const onSubmit = values => {
      setLoading(true)
      values.fullname === '' && delete values.fullname
      values.email === '' && delete values.email
      values.mobile === '' && delete values.mobile
      values.document === '' && delete values.document
      createContact(values)
         .then(response => {
            dispatch(showNewModal(false))
            history.push(`/contacts/${response.data.data.id}`)
         })
         .catch(error => { 
            handleError(error)
            setLoading(false)
         })
   }

   return (
      <Modal isOpen={show} toggle={()=>dispatch(showNewModal(false))}>
         <ModalHeader toggle={() => dispatch(showNewModal(false))}>Crear Contacto Nuevo</ModalHeader>
         <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre del Contacto <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="fullname"
                     ref={register({ required: true })}
                     placeholder="Escriba aquí el nombre"
                  />
                  { errors.fullname && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Correo Electrónico <small className="text-muted">(opcional)</small></Form.Label>
                  <Form.Control
                     name="email"
                     ref={register({})}
                     placeholder="Escriba aquí el correo"
                  />                  
               </Form.Group>
               <Form.Group>
                  <Form.Label>Celular <small className="text-muted">(opcional)</small></Form.Label>
                  <Form.Control
                     name="mobile"
                     ref={register({})}
                     placeholder="Escriba aquí el celular"
                  />                  
               </Form.Group>
               <Form.Group>
                  <Form.Label>Documento <small className="text-muted">(opcional)</small></Form.Label>
                  <Form.Control
                     name="document"
                     ref={register({})}
                     placeholder="Escriba aquí el documento"
                  />                  
               </Form.Group>
               <Button color="primary" disabled={loading}>Crear Contacto Nuevo</Button>
            </Form>
         </ModalBody>
      </Modal>
   )
}

export default withRouter(NewContact)