import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Spinner } from 'reactstrap'

import DealsTable from './partials/DealsTable'
import DealsPipeline from './partials/DealsPipeline/'
import DealsFilters from './partials/DealsFilters'
import NewDealModal from './partials/NewDealModal'
import ListTopBar from './partials/ListTopBar'

import StatCard from '../../components/StatCard'
import ExportList from '../../components/ExportList'

import { getDeals } from './services'
import { getPipelines } from '../Settings/Pipelines/services'
import { handleError, parseQueryParams, parseResponse } from '../../helpers'
import { updatePagination } from './actions'

export default function DealsList() {
   const stateProps = useSelector(state => state.deals_props)
   const [total, setTotal] = useState(0)
   const [deals, setDeals] = useState(null)
   const [pipelines, setPipelines] = useState(null)
   const [showFilters, setShowFilters] = useState(false)

   useEffect(() => {
      !pipelines && getPipelines({ include: 'stages' })
         .then(res => setPipelines(res.data.data))
         .catch(error => handleError(error))
   }, [ pipelines ])

   useEffect(() => {
      !deals && getDeals( parseQueryParams(stateProps) )
         .then(res => {
            setDeals(res.data.data)
            setTotal(res.data.meta.total)
            parseResponse(res, stateProps, updatePagination)
         })
         .catch(error => handleError(error))   
   }, [ deals, stateProps ])

   return (
      <React.Fragment>
         <ListTopBar 
            currentView={stateProps.listViewType} 
            reload={() => setDeals(null)} 
            setShowFilters={setShowFilters} 
         />
         <Row>
            { showFilters && (
               <Col lg="3" md="4" className="hide-md">
                  <DealsFilters reload={() => setDeals(null)} />
                  <StatCard
                     title="Negocios encontrados"
                     iconClass="bx bx-buildings"
                     text={total}
                  />
               </Col>
            )}
            <Col lg={showFilters ? 9 : 12} md={showFilters ? 8 : 12}>
               { stateProps.listViewType === 'table' && (
                  <DealsTable
                     deals={deals}
                     stateProps={stateProps}
                     reload={() => setDeals(null)}
                  />
               )}
               { stateProps.listViewType === 'funnel' && (
                  <React.Fragment>
                     { pipelines && deals ? (
                        <DealsPipeline 
                           pipelines={pipelines} 
                           deals={deals}
                           setDeals={setDeals}
                        />
                     ) : <Spinner size="sm" /> }
                  </React.Fragment>
               )}
               <ExportList type="deals" params={stateProps} />
            </Col>
         </Row>
         <NewDealModal pipelines={pipelines} />
      </React.Fragment>
   )
}