import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import DealsList from './DealsList'
import SalesList from './SalesList'
import DealProfile from './DealProfile'

export default function Deals() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/deals" component={DealsList} />
            <Route exact path="/deals/sales" component={SalesList} />
            <Route exact path="/deals/:id" component={DealProfile} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}