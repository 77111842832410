import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, Spinner } from 'reactstrap'
import { Select, message } from 'antd'

import { getStores } from '../../Settings/Stores/services'
import { handleError } from '../../../helpers'
import { updateDeal } from '../services'

const { Option } = Select

export default function DealStore({ storeId, dealId }) {
	const [stores, setStores] = useState(null)
	const [currentStoreId, setCurrentStoreId] = useState(null)

	useEffect(()=>{
		!stores && getStores()
			.then(res => setStores(res.data.data))
			.catch(error => handleError(error))
	}, [stores])

	useEffect(()=>{
		storeId && !currentStoreId && setCurrentStoreId(storeId)
	}, [storeId, currentStoreId])

	const onChange = newStoreId => {
		setCurrentStoreId(newStoreId)
		updateDeal(dealId, { store_id: newStoreId })
			.then(res => {
				message.success("Tienda actualizada exitosamente.")
			})
			.catch(error => {
				handleError(error)
				setCurrentStoreId(storeId)
			})
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Sede Asociada</CardTitle>
					{ stores ? (
						<Select
							showSearch
							style={{ width: '100%' }}
							optionFilterProp="children"
							value={currentStoreId}
							onChange={onChange}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							<Option value="" disabled>::Sin sede asociada::</Option>
							{ stores.map(store => (
								<Option key={store.id} value={store.id}>
									[{store.code}] {store.name}
								</Option>
							)) }
						</Select>
					) : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}