import React from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap"
import { Divider } from 'antd'

import ListTopBar from './partials/ListTopBar'

const EmailListsList = (props) => {
    return (
		<React.Fragment>
			<ListTopBar />
			<Row>
				<Col lg="12">
					<div className=" pb-5">
						<div className="table-responsive pb-5">
							<Table className="project-list-table table-nowrap table-centered table-borderless pb-5">
								<thead>
									<tr>
										<th scope="col">Nombre de la Lista</th>
										<th scope="col">Suscriptores Activos</th>
										<th scope="col">Estado</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="text-truncate font-size-14">
												<Link to={`/email_lists/1`} className="text-primary">Website Newsletter</Link>
											</h5>
											<p className="text-muted mb-0">noreply@midominio.com</p>
										</td>
										<td>154,403</td>
										<td><span className="badge badge-primary">Activa</span></td>
										<td>
											<UncontrolledDropdown>
												<Link to={`/email_lists/1`}>
													<Button color="dark" size="sm" className="mr-3" outline>Ver Lista</Button>
												</Link>
												<DropdownToggle href="#" className="card-drop" tag="i">
													<i className="mdi mdi-dots-horizontal font-size-18"></i>
												</DropdownToggle>
												<DropdownMenu right >
														<DropdownItem href="#">Suscriptores</DropdownItem>
														<DropdownItem href="#">Verificación de Correos</DropdownItem>
														<DropdownItem href="#">Formular Incrustado</DropdownItem>
														<Divider className="my-2" />
														<DropdownItem href="#">Editar Lista</DropdownItem>
														<DropdownItem href="#">Eliminar</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
}
        
export default EmailListsList;