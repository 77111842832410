import React, { useState } from 'react'
import { TabContent, TabPane, NavLink, NavItem, Nav, Row, Col } from "reactstrap"
import classnames from "classnames"

import EmailOpensTable from './tabs/EmailOpensTable'
import EmailClicksTable from './tabs/EmailClicksTable'
import EmailUnsusbcribesTable from './tabs/EmailUnsusbcribesTable'

export default function EmailsLogs(props) {
	const [activeTab, setactiveTab] = useState("1")

	function toggle(tab) {
		if (activeTab !== tab) setactiveTab(tab)
	}

	return (
		<React.Fragment>
			<Nav tabs className="nav-tabs-custom">
				<NavItem>
					<NavLink
						style={{ cursor: "pointer" }}
						className={classnames({active: activeTab === "1"})}
						onClick={() => toggle("1")}
					>
						<span className="d-none d-sm-block">
							<i className="fa fa-envelope-open-text mr-2" />
							Aperturas
						</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						style={{ cursor: "pointer" }}
						className={classnames({active: activeTab === "2"})}
						onClick={() => toggle("2")}
					>
						<span className="d-none d-sm-block">
							<i className="fa fa-mouse-pointer mr-2" />
							Clics
						</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						style={{ cursor: "pointer" }}
						className={classnames({active: activeTab === "3"})}
						onClick={() => toggle("3")}
					>
						<span className="d-none d-sm-block">
							<i className="fa fa-user-slash mr-2" />
							Desuscritos
						</span>
					</NavLink>
				</NavItem>
			</Nav>

			<TabContent activeTab={activeTab}>
				<TabPane tabId="1" className="p-3">
					<Row>
						<Col sm="12">
							<EmailOpensTable />
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="2" className="p-3">
					<Row>
						<Col sm="12">
							<EmailClicksTable />
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="3" className="p-3">
					<Row>
						<Col sm="12">
							<EmailUnsusbcribesTable />
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</React.Fragment>
	)
}