import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Empty, Pagination } from 'antd'
import moment from 'moment'
import { Card, CardBody } from 'reactstrap'

export default function SegmentTable({ contacts, pagination, setPagination, reload, setSortBy }) {

	const onChange = page => {
		setPagination({ ...pagination, page })
		reload()
   }
   
   const handleTableChange = (pagination, filters, sorter) => {
      let newSort = sorter.order === 'ascend' ? '' : '-'
      newSort += sorter.field
      setSortBy(newSort)
      reload()
   };

   const columns = [
      {
         title: 'Nombre',
         dataIndex: 'fullname',
         // sorter: true,
         render: (text, record) => (
            <Link to={`/contacts/${record.id}`}>{text ? text : <em className="small">Sin Nombre</em>}</Link>
         ),
         width: 190,
         fixed: 'left',
      },
      {
         title: 'Email',
         dataIndex: 'email',
         width: 230,
         render: text => text ? text : '-'
      },
      {
         title: 'Celular',
         dataIndex: 'mobile',
         width: 115,
         render: text => text ? text : '-'
      },
      {
         title: 'Documento',
         dataIndex: 'document',
         width: 120,
         render: text => text ? text : '-'
      },
      {
         title: 'No. de Ventas',
         dataIndex: 'sales_count',
         width: 150,
         // sorter: true,
         render: (text,record) => record.total_sales_count+' ventas'
      },
      {
         title: 'Total Comprado',
         dataIndex: 'total_sales_value',
         width: 150,
         // sorter: true,
         render: text => `$${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      },
      {
         title: 'Primera Compra',
         dataIndex: 'first_sale_date',
         width: 180,
         render: text => text ? moment(text).format('Y-MM-DD HH:mm') : <span className="text-muted small"><em>Sin Compras</em></span>
      },
      {
         title: 'Última Compra',
         dataIndex: 'last_sale_date',
         width: 180,
         render: text => text ? moment(text).format('Y-MM-DD HH:mm') : <span className="text-muted small"><em>Sin Compras</em></span>
      },
      {
         title: 'Creado el',
         dataIndex: 'created_at',
         width: 160,
         render: text => moment(text).format('Y-MM-DD HH:mm')
      }
   ]

	return (
		<React.Fragment>
         <Card>
            <CardBody>
               <div className="table-responsive">
                  <Table
                     columns={columns}
                     dataSource={contacts}
                     loading={!contacts && true}
                     rowKey={record => record.id}
                     pagination={false}
                     showSorterTooltip={false}
                     onChange={handleTableChange}
                     scroll={{ x: 'fixed', scrollToFirstRowOnChange: true }}
                     locale={{ emptyText: <Empty 
                        description="No se encontraron contactos para este segmento" 
                        className="mt-4" 
                     /> }}
                  />
               </div>
               <div className="text-right mt-3">
                  <Pagination 
                     defaultCurrent={pagination.page}
                     pageSize={pagination.per_page}
                     total={pagination.total}
                     onChange={onChange}
                  />
               </div>
            </CardBody>
         </Card>
		</React.Fragment>
	)
}