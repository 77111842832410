import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardTitle, Spinner } from 'reactstrap'
import { Empty } from 'antd'
import moment from 'moment'

import NewBookingModal from '../../Bookings/partials/NewBookingModal'
import BookingProfileModal from '../../Bookings/partials/BookingProfileModal'

import { getBookings } from '../../Bookings/services'
import { handleError } from '../../../helpers'

export default function DealBooking({ dealId }) {
    const [bookings, setBookings] = useState(null)
    const [bookingModal, setBookingModal] = useState(null)
    const [showNewBookingModal, setShowNewBookingModal] = useState(false)

    useEffect(() => {
        !bookings && getBookings({ 
            'filter[deal_id]': dealId, 
            include: 'calendar'
        })
            .then(res => setBookings(res.data.data))
            .catch(error => handleError(error))
    }, [ bookings, dealId ])

    return (
        <React.Fragment>
            <Card>
				<CardBody>
					<CardTitle>Reservas Asociadas</CardTitle>
                    { bookings ? (
                        <React.Fragment>
                            { bookings.length > 0 ? (
                                <React.Fragment>
                                    { bookings.map(booking => (
                                        <div key={booking.id} className='text-link mb-2' onClick={() => setBookingModal(booking.id)}>
                                            <div className='bg-light p-2'>
                                                <div className='text-primary mb-0'>
                                                    <Badge className='mr-2' color={booking.status.color}>{booking.status.label}</Badge>
                                                    Reservación #{booking.id}
                                                </div>
                                                <p className='mb-0'>{booking.calendar.name}</p>
                                                <p className='mb-0'><small className='text-muted'>Reservado desde: {moment(booking.start_at).format('YYYY-MMM-DD hh:mm A')}</small></p>
                                                <p className='mb-0'><small className='text-muted'>Entrada: {moment(booking.entry_at).format('YYYY-MMM-DD hh:mm A')}</small></p>
                                                <p className='mb-0'><small className='text-muted'>Salida: {moment(booking.exit_at).format('YYYY-MMM-DD hh:mm A')}</small></p>
                                                <p className='mb-0'><small className='text-muted'>Reservado hasta: {moment(booking.end_at).format('YYYY-MMM-DD hh:mm A')}</small></p>
                                            </div>
                                        </div>
                                    )) }
                                </React.Fragment>
                            ) : (
                                <Empty 
                                    description="No tiene reservación asociada aún"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    className='mb-0'
                                />
                            )}
                            <Button 
                                outline 
                                color='primary' 
                                size="sm" 
                                className='mt-3'
                                onClick={() => setShowNewBookingModal(true)}
                            >
                                Crear Nueva Reservación
                            </Button>
                        </React.Fragment>
                    ) : (
                        <Spinner size="sm" />
                    )}
                </CardBody>
            </Card>
            { showNewBookingModal && (
                <NewBookingModal 
                    visible
                    onClose={() => setShowNewBookingModal(false)}
                    reload={() => setBookings(null)}
                    dealId={dealId}
                />
            )}
            { bookingModal && (
                <BookingProfileModal 
                    visible
                    onClose={() => setBookingModal(null)}
                    bookingId={bookingModal}
                    reload={() => setBookings(null)}
                />
            )}
        </React.Fragment>
    )
}