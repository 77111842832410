import React, {useState} from 'react'

export default function SearchBar() {
   const [term, setTerm] = useState('')

	const handleSearch = () => {
		if(term) window.location.href = `/search/${encodeURI(term.toLowerCase())}`
   }
   
   return (
      <div className="app-search d-none d-lg-block">
         <div className="position-relative">
            <input
               type="text"
               className="form-control"
               placeholder="Busqueda rápida..."
               value={term}
               onChange={e => setTerm(e.target.value)}
               onKeyDown={e => e.key === 'Enter' && handleSearch()}
            />
            <span tag="a" className="bx bx-search-alt" onClick={() => handleSearch()}></span>
         </div>
      </div> 
   )
}