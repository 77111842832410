import React from 'react'
import { Switch, Divider, Tooltip } from 'antd';
import moment from 'moment';

export default function ReportTopBar({ title, from, to, showFilters, setShowFilters, reload  }) {
	return (
		<React.Fragment>
			<h4 className="mb-4 font-size-18">
				{title}
				<span className="text-muted ml-2 hide-sm">
					<small>Resultados del {moment(from).format('DD MMM YYYY')} al {moment(to).format('DD MMM YYYY')}</small>
				</span>
				<span className="float-right">
					<Switch 
						checked={showFilters} 
						onChange={()=>setShowFilters(!showFilters)} 
						size="small"
						className="mr-2"
					/>
					<small>Mostrar Filtros</small>
					<Divider type="vertical" />
					<Tooltip title="Refrescar">
						<i className="fa fa-sync text-primary text-link" onClick={reload}  />
					</Tooltip>
				</span>
			</h4>
		</React.Fragment>
	)
}