import React from 'react'
import { Table } from 'antd'

export default function EmailClicksTable(props) {

	const columns = [
		{
			title: 'Link'
		},
		{
			title: 'Clics únicos'
		},
		{
			title: 'Total de clics'
		}
	]

	return (
		<React.Fragment>
			<Table
				dataSource={[]}
				columns={columns}
				size="middle"
			/>
		</React.Fragment>
	)
}