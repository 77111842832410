import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import GeneralReport from './GeneralReport'
import UsersReport from './UsersReport'

export default function Reports() {
	return (
		<MainLayout>
			<Switch>
				<Redirect exact path="/reports" to="/reports/general" />

				<Route exact path="/reports/general" component={GeneralReport} />
				<Route exact path="/reports/users" component={UsersReport} />

				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}