import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Select } from 'antd';

import {createCustomField} from './services'
import { handleError } from '../../../helpers'

export default function NewCustomField({ model, reload }) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors, reset, watch, setValue } = useForm()

   const onSubmit = values => {
      setLoading(true)
      values.model = model
      values.slug = values.label

      createCustomField(values)
         .then(() => {
            reload()
            reset()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   let type = watch('type')

   return (
      <Card>
         <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre del Campo Personalizado <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="label"
                     placeholder="Escriba aquí el nombre"
                     ref={register({ required: true })}
                  />
                  { errors.label && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Descripción <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="description"
                     as="textarea"
                     placeholder="Escriba una descripción"
                     ref={register({ required: true })}
                  />
                  { errors.description && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               <Form.Group>
                  <Form.Label>Tipo de Propiedad <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                     name="type"
                     as="select"
                     ref={register({ required: true })}
                  >
                     <option value="">:: Elige una opción ::</option>
                     <option value="integer">Númerico</option>
                     <option value="string">Texto</option>
                     <option value="date">Fecha</option>
                     <option value="json">Opción Multiple</option>
                  </Form.Control>
                  { errors.type && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
               </Form.Group>
               { type === 'json' && (
                  <Form.Group>
                     <Form.Label>Opciones</Form.Label>
                     <input type="hidden" name="options" ref={register({ required: true })} />
                     <Select mode="tags" style={{ width: '100%' }} placeholder="Escriba las opciones" onChange={values => setValue('options', JSON.stringify(values))} />
                     { errors.options && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
                  </Form.Group>
               )}
               <Button color="primary" disabled={loading}>Crear Campo Personalizado</Button>
            </Form>
         </CardBody>
      </Card>
   )
}