import s4salesapi, { getDomain } from '../../services/s4salesapi'

export const getProducts = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/products', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getProduct = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/products/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createProduct = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/products', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateProduct = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/products/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteProduct = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/products/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const searchProducts = async (term, token) => (
	fetch( getDomain()+`/products?filter[name]=${term}&per_page=100`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(record => ({
				label: record.name,
				value: record.id,
			})),
		)
)