import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import EmailListProfile from './EmailListProfile'
import EmailListsList from './EmailListsList'

export default function EmailLists() {
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/email_lists" component={EmailListsList} />
				<Route exact path="/email_lists/:id" component={EmailListProfile} />

				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}