import s4salesapi, { getDomain } from '../../../services/s4salesapi'

export const getCalendars = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/calendars', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getCalendar = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/calendars/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeCalendar = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/calendars', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateCalendar = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/calendars/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteCalendar = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/calendars/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const searchCalendars = async (term, token) => (
	fetch( getDomain()+`/calendars?filter[name]=${term}&per_page=100`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(record => ({
				label: record.name,
				value: record.id,
			})),
		)
)