import s4salesapi from '../../../services/s4salesapi'

export const getCustomFields = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/custom_fields', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createCustomField = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/custom_fields', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateCustomField = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/custom_fields/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteCustomField = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/custom_fields/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)