import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Empty } from 'antd'

import { getCompanies } from '../../Companies/services'
import { handleError } from '../../../helpers'
import ResultCard from './ResultCard'

export default function CompaniesResults({ term }) {
	const [companies, setCompanies] = useState(null)

	useEffect(()=>{
		!companies && getCompanies({ "filter[name]": term })
			.then(res => setCompanies(res.data.data))
			.catch(error => handleError(error))
	}, [companies, term])

	if(!companies) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ companies.length > 0 ? (
				<React.Fragment>
					{ companies.map(company => (
						<ResultCard
							key={company.id}
							title={company.name}
							link={`/contacts/${company.id}`}
						/>
					)) }
				</React.Fragment>
			) : <Empty description="No se encontraron empresas" />}
		</React.Fragment>
	)
}