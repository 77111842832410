import React from 'react'
import { Form } from 'react-bootstrap'
import { Row, Col, Button } from 'reactstrap'

export default function ListSettings() {
	return (
		<React.Fragment>
			<Form>
				<Row>
					<Form.Group as={Col} md="4">
						<Form.Label>Nombre de la Lista</Form.Label>
						<Form.Control />
					</Form.Group>
					<Form.Group as={Col} md="4">
						<Form.Label>Correo del Remitente</Form.Label>
						<Form.Control />
					</Form.Group>
					<Form.Group as={Col} md="4">
						<Form.Label>Nombre del Remitente</Form.Label>
						<Form.Control />
					</Form.Group>
				</Row>
				<Button color="primary">Guardar Ajustes</Button>	
			</Form>
		</React.Fragment>
	)
}