import React from 'react'
import { Table } from 'antd'

export default function CallsUsersReport() {

	const columns = [
		{
			title: 'Asesor'
		},
		{
			title: 'Asignadas'
		},
		{
			title: 'Completadas'
		},
		{
			title: 'Canceladas'
		},
		{
			title: 'Activas'
		}
	]

	return (
		<React.Fragment>
			<Table
				dataSource={[]}
				columns={columns}
			/>
		</React.Fragment>
	)
}