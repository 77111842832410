import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { setQuery } from '../actions'

export default function CompaniesFilters({ reload }) {
   const filters = useSelector(state => state.companies_props.query.filters)
   const dispatch = useDispatch()
   const initialFilters = { name: '', document: '' }
   const [form, setForm] = useState({ ...initialFilters, ...filters })

   const setFilters = newFilters => {
      dispatch(setQuery({ filters: newFilters }))
      reload()
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Búsqueda Avanzada</CardTitle>
            <p className="text-muted mb-4">Filtros Múltiples</p>
            <Form>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por nombre"
                     value={form.name}
                     onChange={e => setForm({ ...form, name: e.target.value })}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Control
                     placeholder="Filtrar por documento"
                     value={form.document}
                     onChange={e => setForm({ ...form, document: e.target.value })}
                  />
               </Form.Group>          
               <Button color="primary" onClick={() => setFilters(form)}>Realizar Búsqueda</Button>
               <div className="mt-2">
                  <Button 
                     color="warning" 
                     outline 
                     size="sm" 
                     onClick={() => {
                        setFilters({})
                        setForm(initialFilters)
                     }}
                  >
                     Borrar todos
                  </Button>
               </div>
            </Form>
         </CardBody>
      </Card>
   )
}