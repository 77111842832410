import s4salesapi from '../../services/s4salesapi'

export const getActivities = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/activities', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getActivity = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/activities/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createActivity = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/activities', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateActivity = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/activities/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteActivity = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/activities/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)