import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'


export default function QuoteForm({ register, errors, onlyEditable }) {
    return (
        <React.Fragment>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Consecutivo <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="sku"
                        type="number"
                        ref={register({ required: true })}
                        placeholder="Escriba uno único"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Fecha de Vencimiento <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        name="expired_at"
                        type="date"
                        ref={register({ required: true })}
                    />
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Título Personalizado <small>(opcional)</small></Form.Label>
                <Form.Control 
                    name="custom_title"
                    ref={register({ })}
                    placeholder="Escriba aquí..."
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Observaciones <small>(opcional)</small></Form.Label>
                <Form.Control 
                    name="observations"
                    ref={register({ })}
                    placeholder="Escriba aquí..."
                    as="textarea"
                    style={{ height: '100px' }}
                />
            </Form.Group>
            <Form.Group as={Col} md={6} className='p-0'>
                <Form.Label>Tarifa IVA <span className='text-danger'>*</span></Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>IVA</InputGroup.Text>
                     </InputGroup.Prepend>
                     <Form.Control 
                        name="iva_rate"
                        ref={register({required: true, max:100})}
                        type="number"
                     />
                     <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                     </InputGroup.Append>
                  </InputGroup>
            </Form.Group>
        </React.Fragment>
    )
}