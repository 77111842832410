import React, {useState} from 'react'
import Editable from "react-bootstrap-editable"
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Descriptions, message, Switch, Spin } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import CustomValuesDetails from '../../../components/CustomValuesDetails'

import { updateContact, createContactCustomValue, updateContactCustomValue } from '../services'
import { handleError } from '../../../helpers'
import ContactCompany from './ContactCompany'
import ContactOwner from './ContactOwner'

const { Item } = Descriptions

export default function ContactDetails({ contact, reload }) {
   const [bordered, setBordered] = useState(false)

   const onSave = (prop, value) => {
      updateContact(contact.id, { [prop]: value })
      .then(() => {
         message.success('Actualizado con éxito')
         reload()
      })
      .catch(error => {
         handleError(error)
         reload()
      })
   }

   const format = value => {
      return value ? value.toString() : ''
   }

   const EditValue = ({ text, slug }) => (
      <Editable 
         initialValue={format(text)} 
         onSubmit={val => onSave(slug, val)}
         editText={text ? '' : '-'}
         isValueClickable={text ? true : false}
      />
   )

   return (      
   <React.Fragment>
      <ContactOwner contact={contact} onSave={onSave} />
      <Card>
         <CardBody>
            <CardTitle className="mb-2">Datos del Contacto</CardTitle>
               <Spin spinning={!contact}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Nombre">
                        { contact && <EditValue text={contact.fullname} slug="fullname" /> }
                     </Item>
                     <Item label="Correo Electrónico">
                        { contact && <EditValue text={contact.email} slug="email" /> }
                     </Item>
                     <Item label="Documento">
                        { contact && <EditValue text={contact.document} slug="document" /> }
                     </Item>
                     <Item label="Teléfono Celular">
                        { contact && <EditValue text={contact.mobile} slug="mobile" /> }
                     </Item>
                     <Item label="Teléfono Secundario">
                        { contact && <EditValue text={contact.phone} slug="phone" /> }
                     </Item>
                     <Item label="Fecha de Cumpleaños">
                        { contact && <EditValue text={contact.birth_day} slug="birth_day" /> }
                     </Item>
                     <Item label="Año de Nacimiento">
                        { contact && <EditValue text={contact.birth_year} slug="birth_year" /> }
                     </Item>
                     <Item label="Genero">
                        { contact && <EditValue text={contact.gender} slug="gender" /> }
                     </Item>                     
                     <Item label="Fecha de Creación">{contact && moment(contact.created_at).format('LLL')}</Item>
                     <Item label="Última Actualización">{contact && moment(contact.updated_at).format('LLL')}</Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <ContactCompany contact={contact} reload={reload} />
         <Card>
            <CardBody>
               <CardTitle className="mb-2">Dirección Asociada</CardTitle>
               <Spin spinning={!contact}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Dirección Completa">
                        { contact && <EditValue text={contact.address} slug="address" /> }
                     </Item>
                     <Item label="Ciudad">
                        { contact && <EditValue text={contact.address_city} slug="address_city" /> }
                     </Item>
                     <Item label="Departamento">
                        { contact && <EditValue text={contact.address_state} slug="address_state" /> }
                     </Item>
                     <Item label="País">
                        { contact && <EditValue text={contact.address_country} slug="address_country" /> }
                     </Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CardTitle className="mb-2">Preferencias</CardTitle>
               <Spin spinning={!contact}>
                  <Descriptions size="small" column={1} bordered={bordered}>
                     <Item label="Canal Preferido">
                        { contact && (
                           <Editable 
                              initialValue={format(contact.prefered_channel)} 
                              onSubmit={val => onSave('prefered_channel', val)} 
                              editText={contact.prefered_channel ? '' : '-'}
                              isValueClickable={contact.prefered_channel ? true : false}
                              type="select"
                              options={['email','mobile','sms','phone']}
                           />
                        )}
                     </Item>
                     <Item label="Contactable por Email">
                        { contact && <EditValue text={contact.emailable_at && moment(contact.emailable_at).format('YYYY-MM-DD HH:mm:ss')} slug="emailable_at" /> }
                     </Item>
                     <Item label="Contactable por SMS">
                        { contact && <EditValue text={contact.smsable_at && moment(contact.smsable_at).format('YYYY-MM-DD HH:mm:ss')} slug="smsable_at" /> }
                     </Item>
                     <Item label="Contactable por Llamada">
                        { contact && <EditValue text={contact.phonable_at && moment(contact.phonable_at).format('YYYY-MM-DD HH:mm:ss')} slug="phonable_at" /> }
                     </Item>
                  </Descriptions>
               </Spin>
            </CardBody>
         </Card>
         <Card>
            <CardBody>
               <CustomValuesDetails 
                  resourceType="contacts" 
                  resource={contact} 
                  bordered={bordered}
                  createService={createContactCustomValue}
                  updateService={updateContactCustomValue}
                  reload={reload}
               />
            </CardBody>
         </Card>
         <div className="hide-sm">
            <Switch size="small" checked={!bordered} onChange={() => setBordered(!bordered)} />{" "}
            Modo Compacto
         </div>
      </React.Fragment>
   )
}