import React from 'react'
import { useDispatch } from 'react-redux'
import { Pagination as AntdPagination } from 'antd'

export default function Pagination({ stateProps, reload, updatePagination }) {
   const dispatch = useDispatch()

   const handleChange = (page, pageSize ) => {
      dispatch(updatePagination({ current_page: page }))
      reload()
   }

   const handleSizeChange = (current, size) => {
      dispatch(updatePagination({ current_page: current, per_page: size }))
      reload()
   }

   return (
      <AntdPagination
         className="mt-3 mb-3 text-right"
         total={stateProps.pagination.total}
         showSizeChanger
         showLessItems={true}
         pageSize={stateProps.pagination.per_page}
         current={stateProps.pagination.current_page}
         pageSizeOptions={['15','20','50','100']}
         onChange={handleChange}
         onShowSizeChange={handleSizeChange}
         disabled={stateProps.pagination.total === 0}
      />
   )
}