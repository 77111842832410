import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import QuoteForm from './QuoteForm'

import { storeQuote } from '../services'
import { handleError } from '../../../helpers'

export default function NewQuoteModal({ visible, onClose, reload, dealId }) {
    const [loading, setLoading] = useState(false)
	const { handleSubmit, register, errors } = useForm({
		defaultValues: {
            iva_rate: 19,
            expired_at: moment().add(1, 'month').format('YYYY-MM-DD')
		}
	})

    const onSubmit = values => {
        setLoading(true)

        storeQuote({
			...values, 
			deal_id: dealId,
			expired_at: values.expired_at+' 23:59:59',
		})
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Crear Cotización Nueva</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<QuoteForm 
							register={register}
							errors={errors}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{loading && <Spinner animation='border' size="sm" className='mr-5' />}
							Crear Cotización
						</Button>
					</Form>
				</ModalBody>
			</Modal>
        </React.Fragment>
    )
}