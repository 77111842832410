import { message } from 'antd'
import { store } from './store'
import _ from 'lodash'

export const handleError = (error, setLoading = () => {}) => {
   let msg = error.message
   let rsp = error.response

   setLoading(false)

   if (!rsp) return message.error('Error Fatal. Favor comunicarse con soporte@smart4.com.co. ' + msg)

   if (rsp.status === 401) window.location.href = '/auth/logout'

   // Default messages
   if (rsp.status === 404) msg = 'No se encontraron resultados (404).'
   if (rsp.status === 500) msg = 'Oops error en el servidor. Escriba a soporte@smart4.com.co'

   // If api response has error message set it as default
   if (rsp.data.message) msg = rsp.data.message

   /* Force messages */

   // If is unauthorize redirect to login
   if (rsp.status === 403 && rsp.config.url !== '/auth/login')
      msg = 'No posee permisos para esta acción.'

   // Non valid data 
   if (rsp.status === 422) {
      msg = ''
      for (let [key, value] of Object.entries(rsp.data.errors)) {
         msg += `${value.join(', ')} [${key}].`
      }
   }

   // Show antd error message
   return message.error(`${msg}`)
}


export const parseQueryParams = props => {
   let query = {}

   if (props.pagination) {
      query.page = props.pagination.current_page
      query.per_page = props.pagination.per_page
   }

   if (props.query) {
      if (props.query.sort)
         query.sort = props.query.sort

      if (props.query.include)
         query.include = props.query.include

      if (props.query.filters)
         for (let [key, value] of Object.entries(props.query.filters))
            query[`filter[${key}]`] = value

      if (props.query.fields)
         for (let [key, value] of Object.entries(props.query.fields))
            query[`fields[${key}]`] = value

      if(props.query.append)
         query['append'] = props.query.append
   }

   return query;
}

export const parseExportParams = params => {
   let query = '?q'

   if (params.query) {
      if (params.query.sort)
         query += `&sort=${params.query.sort}`

      if (params.query.filters)
         for (let [key, value] of Object.entries(params.query.filters))
            query += `&filter[${key}]=${value}`
   } else {
      for (let [key, value] of Object.entries(params))
         query += `&${key}=${value}`
   }

   return query;
}

export const parsePagination = response => {
   let meta = response.data.meta

   return { 
      current_page: meta.current_page, 
      per_page: parseInt(meta.per_page), 
      total: meta.total 
   }
}

export const round = num => (
	num ? Math.round((num + Number.EPSILON) * 100) / 100 : 0
)

export const number = num => (
	num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
)

export const random = () => (
   Date.now() + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
)

export const parseResponse = (response, stateProps, updatePagination) => {
   let responsePagination = { 
      current_page: response.data.meta.current_page, 
      per_page: parseInt(response.data.meta.per_page), 
      total: response.data.meta.total
   }

   !_.isEqual(stateProps.pagination, responsePagination) && 
      store.dispatch(updatePagination(responsePagination))
}