import s4salesapi from '../../services/s4salesapi'
import s4adminapi from '../../services/s4adminapi'

export const getSegments = () => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/segments')
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getSegment = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.get(`/segments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getSegmentContacts = query => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.get(`/contacts${query}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createSegment = data => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.post('/segments', data)
				.then(response => resolve(response))
            .catch(error => reject(error))
	})
)

export const updateSegment = (id, data) => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.put(`/segments/${id}`, data)	
				.then(response => resolve(response))
            .catch(error => reject(error))
	})
)

export const deleteSegment = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.delete(`/segments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getSegmentFilters = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/segments/${id}/filters`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getSegmentFilter = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.get(`/segment_filters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createSegmentFilter = data => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.post('/segment_filters', data)
				.then(response => resolve(response))
            .catch(error => reject(error))
	})
)

export const updateSegmentFilter = (id, data) => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.put(`/segment_filters/${id}`, data)	
				.then(response => resolve(response))
            .catch(error => reject(error))
	})
)

export const deleteSegmentFilter = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.delete(`/segment_filters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getSysFilters = () => (
	new Promise((resolve, reject) => {
		s4adminapi()
			.get('/public/filters')
				.then(response => resolve(response))
				.catch(error => reject(error))
	})	
)