import React from 'react'
import { Tabs } from 'antd'
import styled from 'styled-components'

import ActivityForm from './ActivityForm'

const { TabPane } = Tabs;

export default function ActivitiesWizard({ resourceId, resourceType, reload }) {

   return (
      <Tabs type="card">
         <TabPane tab={(<p className="mb-0"><i className="fa fa-phone mr-2"></i> <span className="hide-sm">Crear</span> Llamada</p>)} key="1">
            <TabContent>               
               <ActivityForm type="call" resourceId={resourceId} resourceType={resourceType} reload={reload} />
            </TabContent>
         </TabPane>
         <TabPane tab={(<p className="mb-0"><i className="fa fa-calendar-day mr-2"></i> <span className="hide-sm">Crear</span> Cita</p>)} key="2">
            <TabContent>
               <ActivityForm type="meeting" resourceId={resourceId} resourceType={resourceType} reload={reload} />
            </TabContent>
         </TabPane>
         <TabPane tab={(<p className="mb-0"><i className="fa fa-check-square mr-2"></i> <span className="hide-sm">Crear</span> Tarea</p>)} key="3">
            <TabContent>
               <ActivityForm type="task" resourceId={resourceId} resourceType={resourceType} reload={reload} />
            </TabContent>
         </TabPane>
         <TabPane tab={(<p className="mb-0"><i className="fa fa-bell mr-2"></i> <span className="hide-sm">Crear</span> Recordatorio</p>)} key="4">
            <TabContent>
               <ActivityForm type="reminder" resourceId={resourceId} resourceType={resourceType} reload={reload} />
            </TabContent>
         </TabPane>
      </Tabs>      
   )
}

const TabContent = styled.div`
   margin-top: -17px;
   padding: 15px 20px;
   background-color: #fff;
   margin-bottom: 20px;
   border-left: 1px solid #f0f0f0;
   border-right: 1px solid #f0f0f0;
   border-bottom: 1px solid #f0f0f0;
`