import React from 'react'
import { Spinner, Table, Card, CardBody, CardTitle, Badge } from 'reactstrap'
import { Divider, Empty } from 'antd'
import { Link } from 'react-router-dom'

export default function ContactDeals({ deals }) {
   return (
      <Card>
         <CardBody>
            <CardTitle className="mb-3">Negocios</CardTitle>
            { deals ? (
               <div className="">
                  { deals.length > 0 ? (
                     <Table className="table table-centered table-nowrap mb-0">
                        <tbody>
                           { deals.map(deal => (
                              <tr key={deal.id}>
                                 <td style={{ width: "30px" }} className="px-0">
                                    <div className="avatar-xs">
                                       <span className="avatar-title rounded-circle bg-soft-info text-info font-size-16">
                                          {deal.reference.substring(0, 2).toUpperCase()}
                                       </span>
                                    </div>
                                 </td>
                                 <td>
                                    <h5 className="font-size-14 m-0">
                                       <Link to={`/deals/${deal.id}`}>
                                          <span className="text-dark">{deal.reference}</span>
                                       </Link>
                                       <br />
                                       <span className="text-muted">                                          
                                          { deal.state === 'draft' && <Badge color="dark">Negocio Sugerido</Badge> }
                                          { deal.state === 'active' && <Badge color="info">Negocio Activo</Badge> }
                                          { deal.state === 'won' && <Badge color="success">Negocio Ganado</Badge> }
                                          { deal.state === 'lost' && <Badge color="danger">Negocio Perdido</Badge> }
                                          <Divider type="vertical" />
                                          Etapa: <span className="text-dark">{deal.stage.name}</span>
                                       </span>
                                    </h5>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </Table>               
                  ) : <Empty description="No tiene productos" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
               </div>
            ) : <Spinner size="sm" />}
         </CardBody>
      </Card>
   )
}