import s4salesapi from '../../services/s4salesapi'

export const getCompanies = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/companies', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getCompany = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/companies/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createCompany = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/companies', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateCompany = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/companies/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteCompany = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/companies/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createCompanyCustomValue = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/companies/${id}/custom_values`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateCompanyCustomValue = (id, cId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/companies/${id}/custom_values/${cId}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getCompanyTags = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/companies/${id}/tags`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const attachCompanyTag = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/companies/${id}/tags`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const detachCompanyTag = (id, tagId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/companies/${id}/tags/${tagId}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const attachCompanyContact = (id, contactId) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/companies/${id}/contacts/${contactId}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const detachCompanyContact = (id, contactId) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/companies/${id}/contacts/${contactId}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)