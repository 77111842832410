import React, { useState } from 'react'
import { TabContent, TabPane, NavLink, NavItem, Nav, Card, Row, Col, CardBody } from "reactstrap";
import classnames from "classnames";

import ListSubscribers from './partials/ListSubscribers';
import ListTags from './partials/ListTags';
import ListSegments from './partials/ListSegments';
import ListVerification from './partials/ListVerification';
import ListSettings from './partials/ListSettings';
import ProfileTopBar from './partials/ProfileTopBar';

export default function EmailListProfile(props) {
	const [activeTab, setactiveTab] = useState("1")

	function toggle(tab) {
		if (activeTab !== tab) setactiveTab(tab)
	}

	return (
		<React.Fragment>
			<ProfileTopBar />
			<Card>
				<CardBody className="px-4 pt-3">
					<Nav tabs className="nav-tabs-custom">
						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								className={classnames({active: activeTab === "1"})}
								onClick={() => toggle("1")}
							>
								<span className="d-none d-sm-block">
									<span className="badge badge-primary mr-2">201</span>
									Suscriptores
								</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								className={classnames({active: activeTab === "2"})}
								onClick={() => toggle("2")}
							>
								<span className="d-none d-sm-block">
									<i className="fa fa-tags mr-2" />
									Etiquetas
								</span>
							</NavLink>
						</NavItem>
						{/* <NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								className={classnames({active: activeTab === "3"})}
								onClick={() => toggle("3")}
							>
								<span className="d-none d-sm-block">
									<i className="fa fa-network-wired mr-2" />
									Segmentos de Lista
								</span>
							</NavLink>
						</NavItem> */}
						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								className={classnames({active: activeTab === "4"})}
								onClick={() => toggle("4")}
							>
								<span className="d-none d-sm-block">
									<i className="fa fa-envelope mr-2" />
									Verficación de Emails
								</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								className={classnames({active: activeTab === "5"})}
								onClick={() => toggle("5")}
							>
								<span className="d-none d-sm-block">
									<i className="fa fa-cog mr-2" />
									Ajustes
								</span>
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={activeTab} className="pt-2">
						<TabPane tabId="1" className="p-3">
							<Row>
								<Col sm="12">
									<ListSubscribers />
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="2" className="p-3">
							<Row>
								<Col sm="12">
									<ListTags />
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="3" className="p-3">
							<Row>
								<Col sm="12">
									<ListSegments />
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="4" className="p-3">
							<Row>
								<Col sm="12">
									<ListVerification />
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="5" className="p-3">
							<Row>
								<Col sm="12">
									<ListSettings />
								</Col>
							</Row>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}