import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

import ProfileTopBar from './partials/ProfileTopBar'
import StatCard from '../../components/StatCard'
import { Divider } from 'antd'
import CallsTable from './partials/CallsTable'
import CallsUsersReport from './partials/CallsUsersReport'

export default function CallProfile() {
	return (
		<React.Fragment>
			<ProfileTopBar 
				backLink="/campaigns/calls" 
				title="Campaña de Llamadas: WH-2020OCT21EMAIL01" 
			/>
			<Row>
				<Col md="3">
					<Card>
						<CardBody>
							<CardTitle>Detalles Generales</CardTitle>
							<Divider className="mt-2 mb-3" />
							<p className="text-muted mb-0 small">Nombre de Campaña</p>
							<h6>WH-2020OCT21EMAIL01</h6>
							<p className="text-muted mb-0 small">Fecha de Ejecución</p>
							<h6>2020 Oct 10 21:30</h6>
							<p className="text-muted mb-0 small">Fecha de Creación</p>
							<h6>2020 Oct 1 21:30</h6>
							<p className="text-muted mb-0 small">Referencia de Llamada</p>
							<h6>Seguimiento prospectos nuevos</h6>
							<p className="text-muted mb-0 small">Asesores Involucrados</p>
							<h6>Usario Demo1, Felipe Demo2, Pruebas Demo 3</h6>
							<p className="text-muted mb-0 small">Volumen de llamadas</p>
							<h6>Asignar 5 llamadas diarias</h6>
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Row>
						<Col md="3">
							<StatCard title="Agendadas" text="1,020" iconClass="fa fa-phone" />
						</Col>
						<Col md="3">
							<StatCard title="Activas" text="730" iconClass="fa fa-toggle-on" />
						</Col>
						<Col md="3">
							<StatCard title="Completadas" text="220" iconClass="fa fa-check" />
						</Col>
						<Col md="3">
							<StatCard title="Canceladas" text="113" iconClass="fa fa-phone-slash" />
						</Col>
					</Row>
					<Card>
						<CardBody>
							<CardTitle>Registro de llamadas</CardTitle>
							<Divider className="mt-2 mb-3" />
							<CallsTable />
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<CardTitle>Reporte por asesor</CardTitle>
							<Divider className="mt-2 mb-3" />
							<CallsUsersReport />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}