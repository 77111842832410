import React, {useState} from 'react';
import Board from '@lourenci/react-kanban'
import { CardTitle } from 'reactstrap'
import { Divider, message } from 'antd'

import DealCard from './DealCard'
import PipelineBar from './PipelineBar'

import EmptyCard from '../../../../components/EmptyCard'
import { updateDeal } from '../../services';
import { handleError } from '../../../../helpers';

export default function DealsPipeline({pipelines, deals, setDeals}) {
   const [currentPipeline, setCurrentPipeline] = useState(0)

   if(pipelines.length === 0) 
      return <EmptyCard description="No tiene embudos de venta creados" buttonLink="/settings/pipelines" buttonText="Crear Embudo de Ventas" />
   
   if(pipelines[ currentPipeline ].stages.length === 0) 
      return <EmptyCard description="No tiene etapas creadas para este embudo" buttonLink="/settings/pipelines" buttonText="Agregar Etapas" />

   const handleNewStage = (deal, newStageId) => {   
      setDeals([
         ...deals.filter(d => d.id !== deal.id),
         { ...deal, stage_id: newStageId }
      ])  
      updateDeal(deal.id, { stage_id: newStageId })
         .then(() => {    
            message.success('Etapa actualizada')
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>         
         <Board
            disableColumnDrag
            renderColumnHeader={({ title }) => <CardTitle className="mb-3 text-dark">{title}</CardTitle>}
            renderCard={( content ) => <DealCard deal={content} />}
            onCardDragEnd={(card, source, destination) => handleNewStage(card, destination.toColumnId)}
         >
            {{ columns: pipelines[ currentPipeline ].stages.map(stage => ({
               id: stage.id,
               title: stage.name,
               cards: deals.filter(deal => deal.stage_id === stage.id)
            }))}}
         </Board>
         <Divider className="mb-3" />
         <PipelineBar { ...{currentPipeline, setCurrentPipeline, pipelines} } />
      </React.Fragment>
   );
}