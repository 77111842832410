import React, {useState} from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Table, Empty } from 'antd'

import {deleteCategory} from './services'
import { handleError } from '../../../helpers'
import EditCategory from './EditCategory'
import TableActions from '../../../components/TableActions'

export default function CategoriesList({ categories, reloadCategories }) {
   const [editCategory, setEditCategory] = useState(null)

   const handleDelete = id => {
      deleteCategory(id)
         .then(()=>reloadCategories())
         .catch(error => handleError(error))
   }

   const columns = [
      {
         title: 'Nombre de Categoría',
         dataIndex: 'name',
         sorter: (a, b) => a.name.length - b.name.length,
      },
      {
         title: 'Productos Asociados',
         dataIndex: 'products_count',
         sorter: true,
         render: text => `${text} productos`
      },
      {
         title: 'Acciones',
         render: (txt, record) => (
            <TableActions
               handleEdit={()=>setEditCategory(record)}
               handleDelete={() => handleDelete(record.id)}
            />
         )
      }
   ]

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>Categorías de Productos</CardTitle>
               <p className="text-muted mb-4">Listado completo de categorías de productos</p>
               <Table 
                  columns={columns}
                  dataSource={categories}
                  loading={!categories && true}
                  showSorterTooltip={false}
                  rowKey={record => record.id}
                  locale={{ emptyText: <Empty description="No se encontraron categorías" className="mt-3" /> }}
               />
            </CardBody>
         </Card>
         <EditCategory category={editCategory} setShow={setEditCategory} reload={()=>reloadCategories()} />
      </React.Fragment>
   )
}