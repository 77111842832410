import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import CompaniesList from './CompaniesList'
import CompanyProfile from './CompanyProfile'

export default function Companies() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/companies" component={CompaniesList} />
            <Route exact path="/companies/:id" component={CompanyProfile} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}