import s4salesapi from '../../services/s4salesapi'

export const getDeals = params => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/deals', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDeal = (id, params) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/deals/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createDeal = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/deals', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateDeal = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/deals/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteDeal = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/deals/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createDealCustomValue = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/deals/${id}/custom_values`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateDealCustomValue = (id, cId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/deals/${id}/custom_values/${cId}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDealTags = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/deals/${id}/tags`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const attachDealTag = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/deals/${id}/tags`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const detachDealTag = (id, tagId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/deals/${id}/tags/${tagId}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDealProducts = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/deals/${id}/products`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const attachDealProduct = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/deals/${id}/products`, data)   
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const detachDealProduct = (id, productId) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/deals/${id}/products/${productId}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)