import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import CompaniesTable from './partials/CompaniesTable'
import CompaniesFilters from './partials/CompaniesFilters'
import NewCompany from './partials/NewCompany'
import ListTopBar from './partials/ListTopBar'
import StatCard from '../../components/StatCard'
import ExportList from '../../components/ExportList'

import { getCompanies } from './services'
import { handleError, parseQueryParams, parseResponse } from '../../helpers'
import { updatePagination } from './actions'

export default function CompaniesList() {
   const [companies, setCompanies] = useState(null)
   const stateProps = useSelector(state => state.companies_props)

   useEffect(() => {
      !companies && getCompanies(parseQueryParams(stateProps))
         .then(res => {
            setCompanies(res.data.data)
            parseResponse(res, stateProps, updatePagination)
         })
         .catch(error => handleError(error))
   }, [ companies, stateProps ])

   return (
      <React.Fragment>
         <ListTopBar currentView={stateProps.listViewType} reload={() => setCompanies(null)} />
         <Row>
            <Col lg="3" md="4" className="hide-md">
               <CompaniesFilters reload={() => setCompanies(null)} />
               <StatCard
                  title="Empresas encontradas"
                  text={stateProps.pagination.total}
                  iconClass="bx bx-buildings"
               />
            </Col>
            <Col lg="9" md="8">
               { stateProps.listViewType === 'table' && (
                  <CompaniesTable
                     companies={companies}
                     stateProps={stateProps}
                     reload={() => setCompanies(null)}
                  />
               )}
               <ExportList type="companies" params={stateProps} />
            </Col>
         </Row>
         <NewCompany />
      </React.Fragment>
   )
}