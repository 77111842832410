import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Divider } from "antd";

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let pathname = this.props.location.pathname
		let directory = pathname.split('/')[1]
		let subdirectory = pathname.split('/')[2]

		return (
			<div className="topnav">
				<div className="container-fluid">
					<nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
						<Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
							<ul className="navbar-nav">
								<li className="nav-item dropdown">
									<Link to="/contacts" className={`nav-link dropdown-toggle arrow-none ${directory === 'contacts' && 'active'} ${directory === 'companies' && 'active'}`}>
										<i className="bx bx-user mr-2"></i>Contactos <div className="arrow-down"></div>
									</Link>
									<div className="dropdown-menu">
										<Link to="/contacts" className={`dropdown-item ${directory === 'contacts' && 'active'}`}>Personas</Link>
										<Link to="/companies" className={`dropdown-item ${directory === 'companies' && 'active'}`}>Empresas</Link>
									</div>
								</li>
								<li className="nav-item dropdown">
									<Link to="/deals" className={`nav-link dropdown-toggle arrow-none ${directory === 'deals' && 'active'} ${directory === 'products' && 'active'}`}>
										<i className="bx bx-dollar-circle mr-2"></i>Negocios <div className="arrow-down"></div>
									</Link>
									<div className="dropdown-menu">
										<Link to="/deals" className={`dropdown-item ${directory === 'deals' && subdirectory !== 'sales' && 'active'}`}>Oportunidades</Link>
										<Link to="/quotes" className={`dropdown-item ${subdirectory === 'quotes' && 'active'}`}>Cotizaciones</Link>
										<Link to="/deals/sales" className={`dropdown-item ${subdirectory === 'sales' && 'active'}`}>Ventas</Link>
										<Divider className="my-2" />
										<Link to="/products" className={`dropdown-item ${directory === 'products' && 'active'}`}>Productos y Servicios</Link>
									</div>
								</li>
								<li className="nav-item">
									<Link to="/activities" className={`nav-link ${directory === 'activities' && 'active'}`}>
										<i className="bx bx-task mr-2"></i>
										Actividades
									</Link>
								</li>
								<li className="nav-item">
									<Link to="/bookings" className={`nav-link ${directory === 'bookings' && 'active'}`}>
										<i className="bx bx-calendar mr-2"></i>
										Reservas
									</Link>
								</li>
								<li className="nav-item">
									<Link to="/segments" className={`nav-link ${directory === 'segments' && 'active'}`}>
										<i className="bx bx-network-chart mr-2"></i>
										Segmentos
									</Link>
								</li>
								<li className="nav-item dropdown hide">
									<Link to="/campaigns" className={`nav-link dropdown-toggle arrow-none ${ ['campaigns','email_lists','templates'].includes(directory) && 'active'}`}>
										<i className="bx bx-send mr-2"></i>Campañas <div className="arrow-down"></div>
									</Link>
									<div className="dropdown-menu">
										<Link 
											to="/campaigns/emails" 
											className={`dropdown-item ${directory === 'campaigns' && subdirectory === 'emails' && 'active'}`}
										>
											Email Marketing
										</Link>
										<Link 
											to="/campaigns/sms" 
											className={`dropdown-item ${directory === 'campaigns' && subdirectory === 'sms' && 'active'}`}
										>
											Mensajes SMS
										</Link>
										<Link 
											to="/campaigns/ads" 
											className={`dropdown-item ${directory === 'campaigns' && subdirectory === 'ads' && 'active'}`}
										>
											Facebook Ads
										</Link>
										<Link 
											to="/campaigns/calls" 
											className={`dropdown-item ${directory === 'campaigns' && subdirectory === 'calls' && 'active'}`}
										>
											Llamadas
										</Link>
										<Divider className="my-2" />
										<Link to="/email_lists" className={`dropdown-item ${directory === 'email_lists' && 'active'}`}>
											Listas de Suscriptores
										</Link>
										<Link to="/templates" className={`dropdown-item ${directory === 'templates' && 'active'}`}>
											Plantillas de Correo
										</Link>
									</div>
								</li>
								<li className="nav-item dropdown">
									<Link to="/reports" className={`nav-link dropdown-toggle arrow-none ${directory === 'reports' && 'active'}`}>
										<i className="bx bx-bar-chart-alt-2 mr-2"></i>
										Reportes{" "}
										<div className="arrow-down"></div>
									</Link>
									<div className="dropdown-menu">
										<Link to="/reports/general" className={`dropdown-item ${directory === 'reports' && subdirectory === 'general' && 'active'}`}>Reporte General</Link>
										<Link to="/reports/users" className={`dropdown-item ${directory === 'reports' && subdirectory === 'users' && 'active'}`}>Reporte de Asesores</Link>
									</div>
								</li>
								<li className="nav-item dropdown">
									<Link to="/settings" className={`nav-link dropdown-toggle arrow-none ${directory === 'settings' && 'active'}`}>
										<i className="bx bx-cog mr-2"></i>Ajustes <div className="arrow-down"></div>
									</Link>
									<div className="dropdown-menu">
										<Link to="/settings/users" className={`dropdown-item ${subdirectory === 'users' && 'active'}`}>
											Manejo de Usuarios
										</Link>
										<Link to="/settings/stores" className={`dropdown-item ${subdirectory === 'stores' && 'active'}`}>
											Manejo de Sedes / Tiendas
										</Link>
										<Divider className="my-2" />
										<Link to="/settings/custom_fields" className={`dropdown-item ${subdirectory==='custom_fields' && 'active'}`}>
											Campos Personalizados
										</Link>
										<Link to="/settings/categories" className={`dropdown-item ${subdirectory === 'categories' && 'active'}`}>
											Categorías de Productos
										</Link>
										<Link to="/settings/pipelines" className={`dropdown-item ${subdirectory === 'pipelines' && 'active'}`}>
											Embudos de Negocios
										</Link>
										<Link to="/settings/calendars" className={`dropdown-item ${subdirectory === 'calendars' && 'active'}`}>
											Calendarios para Reservas
										</Link>
									</div>
								</li>
							</ul>
						</Collapse>
					</nav>
				</div>
			</div>
		);
	}
}

export default withRouter(Navbar);
