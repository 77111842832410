import React from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, Row, Col, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { createStore } from '../services'
import { handleError } from '../../../../helpers'

export default function NewStoreCard({ reload }) {
	const { handleSubmit, register, errors, reset } = useForm()

	const onSubmit = values => {
		createStore(values)
			.then(() => {
				message.success('Tienda creada exitosamente')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col} md="4">
								<Form.Label>Código Único<span className="text-danger">*</span></Form.Label>
								<Form.Control
									name="code"
									placeholder="Código"
									ref={register({ required: true })}
								/>
								{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col} md="8">
								<Form.Label>Nombre de Sede<span className="text-danger">*</span></Form.Label>
								<Form.Control
									name="name"
									placeholder="Escribe aquí el nombre"
									ref={register({ required: true })}
								/>
								{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col} md="6">
								<Form.Label>Celular de Sede</Form.Label>
								<Form.Control
									name="mobile"
									placeholder="Escriba aquí el celular"
									ref={register({})}
								/>
							</Form.Group>
							<Form.Group as={Col} md="6">
								<Form.Label>Teléfono de Sede</Form.Label>
								<Form.Control
									name="phone"
									placeholder="Escribe aquí el teléfono"
									ref={register({})}
								/>
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Correo Electrónico</Form.Label>
							<Form.Control
								name="email"
								type="email"
								placeholder="Escriba aquí el correo"
								ref={register({})}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Dirección de Sede</Form.Label>
							<Form.Control
								name="address"
								placeholder="Escriba aquí la dirección"
								ref={register({})}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Horarios</Form.Label>
							<Form.Control
								name="schedule"
								as="textarea"
								placeholder="Escriba aquí el horario"
								ref={register({})}
							/>
						</Form.Group>
						<Button color="primary">Crear Sede</Button>
					</Form>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}