import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Divider } from "antd";

export default function Footer(){
    return (
        <footer className="footer">
            <Container fluid={true}>
                <Row>
                    <Col md={6}>
                        {new Date().getFullYear()} © Smart4 Solutions.
                    </Col>
                    <Col md={6}>
                        <div className="text-sm-right d-none d-sm-block">
                            <span>{process.env.REACT_APP_VERSION}</span>
                            <Divider type="vertical" />
                            Hecho con <i className="mdi mdi-heart-pulse text-danger"></i> <i className="mdi mdi-brain text-danger"></i><i className="mdi mdi-power-plug text-danger"></i> en Colombia
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};