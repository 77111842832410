import React from 'react'
import Editable from "react-bootstrap-editable"
import { message } from 'antd'

import StatCard from '../../../components/StatCard'

import { updateCompany } from '../services'
import { handleError } from '../../../helpers'
import { Spinner } from 'reactstrap'

export default function CompanyStats({ company }) {

   const onSave = (prop, value) => {
      updateCompany(company.id, { [prop]: value })
         .then(() => message.success('Actualizado con éxito'))
         .catch(error => handleError(error))
   }

   if(!company) return <Spinner size="sm" />

   return (
      <React.Fragment>
         <StatCard 
            title="Total de Empleados"
            iconClass="fa fa-users"
            text={(
               <Editable 
                  initialValue={company.total_employees && company.total_employees.toString()}
                  onSubmit={val => onSave('total_employees', val)} 
                  editText={company.total_employees ? '' : '-'}
                  isValueClickable={company.total_employees ? true : false}
               />
            )}
         />
         <StatCard 
            title="Facturación Anual"
            iconClass="fa fa-dollar-sign"
            text={(
               <Editable 
                  initialValue={company.annual_revenue && company.annual_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onSubmit={val => onSave('annual_revenue', val)}
                  editText={company.annual_revenue ? '' : '-'}
                  isValueClickable={company.annual_revenue ? true : false}
               />
            )}
         />
      </React.Fragment>
   )
}