import React, { useState } from 'react'
import { CardBody, TabContent, TabPane, Row, Col } from 'reactstrap'
import { Steps, Card } from 'antd'

import WizardTopBar from './partials/WizardTopBar'

const { Step } = Steps

export default function AdsCampaignWizard(props) {
	const [current, setCurrent] = useState(0)
	return (
		<React.Fragment>
			<WizardTopBar backLink="/campaigns/ads" title="Editor de Audiencia para Facebook Ads" />
			<Steps 
				current={current} 
				onChange={current => setCurrent(current)}
				type="navigation"
				size="small"
				className="site-navigation-steps mb-3 bg-white border-radius-none"
			>
				<Step title="Destinatarios" />
				<Step title="Configuración" />
				<Step title="Exportación" />
			</Steps>
			<Card>
				<CardBody>
					<TabContent activeTab={current}>
						<TabPane tabId="0" className="p-3">
							<Row>
								<Col sm="12">

								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="1" className="p-3">
							<Row>
								<Col sm="12">

								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="2" className="p-3">
							<Row>
								<Col sm="12">

								</Col>
							</Row>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}