import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Descriptions } from 'antd'

import { number, round } from '../../../helpers'

const { Item } = Descriptions

export default function QuoteDocument({ quote }) {
	const logo = useSelector(state => state.auth.company_logo)

	const getSubtotal = () => {
		let total = 0

		quote.deal.products.map(product => {
			return total += product.pivot.value * product.pivot.quantity
		})

		return total
	}

	const getTaxableSubtotal = () => {
		let total = 0

		quote.deal.products.map(product => {
			if(!product.is_taxable) return total;

			return total += product.pivot.value * product.pivot.quantity
		})

		return total
	}

    if(!quote)
        return <Spinner animation='border' size='sm' className='mb-3' />

    return (
        <Document>
            {/* Logo */}
			{ logo && <img src={logo} alt="Logo" width="200px" height="auto" className='mb-3' />}
            {/* Datos Generales */}
            <Descriptions bordered column={3}>
                <Item label="COTIZACIÓN:">{quote.sku}</Item>
                <Item label="REFERENCIA:" span={2}>{quote.custom_title ? quote.custom_title : `Propuesta Comercial #${quote.sku}`}</Item>
                <Item label="CLIENTE:" span={2}>{quote.deal.contact.fullname}</Item>
                <Item label="TELEFONO:">{quote.deal.contact.mobile} {quote.deal.contact.phone}</Item>
            </Descriptions>
			{ quote.deal.bookings && (
				<React.Fragment>
					{ quote.deal.bookings.map(booking => (
						<Descriptions bordered column={2} className='my-3' key={booking.id}>
							<Item label="RESERVA">#{booking.id} (Estado: {booking.status.label})</Item>
							<Item label="MONTAJE">{booking.start_at}</Item>
							<Item label="INICIO">{booking.entry_at}</Item>
							<Item label="SALIDA">{booking.exit_at}</Item>
						</Descriptions>
					)) }
				</React.Fragment>
			)}
            {/* Servicios */}
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th colSpan={4} className='text-center'>DESCRIPCIÓN DEL SERVICIO</th>
                    </tr>
                    <tr className='bg-light-gray text-center'>
                        <th>SERVICIO</th>
                        <th width="100px">CANTIDAD</th>
                        <th width="180px">VALOR UNITARIO</th>
                        <th width="180px">VALOR TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    { quote.deal.products.map(product => (
                        <tr key={product.id}>
                            <td>
                                {product.name}
								<span style={{ whiteSpace: 'pre-line' }}>
                                	<small>{product.description}</small>{" "}
									{ !product.is_taxable && <em>(No aplica IVA)</em> }
								</span>
                            </td>
                            <td>{product.pivot.quantity} UND</td>
                            <td>${number(round(product.pivot.value/product.pivot.quantity))} COP</td>
                            <td>${number(product.pivot.value)} COP</td>
                        </tr>
                    )) }
                    <tr className='text-right'>
                        <td colSpan={3}>
                            <strong>SUBTOTAL</strong>
                        </td>
                        <td>${number(getSubtotal())} COP</td>
                    </tr>
                    <tr className='text-right'>
                        <td colSpan={3}>
                            <strong>IVA ({quote.iva_rate}%)</strong>
                        </td>
                        <td>
							${number(getTaxableSubtotal()*(quote.iva_rate/100))} COP
						</td>
                    </tr>
                    <tr className='text-right'>
                        <td colSpan={3}>
                            <strong>TOTAL</strong>
                        </td>
                        <td>
							${number(getSubtotal()+(getTaxableSubtotal()*(quote.iva_rate/100)))} COP
						</td>
                    </tr>
                    <tr className='text-center'>
                        <td colSpan={4} className='bg-light-gray bold'>
							<span style={{ whiteSpace: 'pre-line' }}>
                            	{quote.observations}
							</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* Observaciones */}
        </Document>
    )
}

const Document = styled.div`
	width: 210mm;
	min-height: 330mm;
	background-color: #fff;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 16px;
	}
	h2 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 13px;
		height: 15px;
        padding: 10px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.logoDiv {
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -15px;
  	}
	.titleDiv {
		margin: auto;
		text-align: center;
	}
	.detailsDiv {
		border: 1px solid #888;
		border-radius: 10px;
		padding: 7px 10px;
		margin-bottom: 5px;
	}
	.detailRow {
		margin-bottom: 10px;		
	}
	.detailLabel {
		float: left;
		min-width: 70px;
		margin-right: 10px;
		font-size: 15px;
	}
	.detailInfo {
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
		text-align: left;
		font-weight: bold;
		font-size: 15px;
		min-height: 20px;
	}
	.detailInfoMultiline {
		width: 100%;
		text-align: left;
		font-weight: 400;
		font-size: 15px;
		min-height: 18px;
		background-color: #fcfcfc;
		padding: 5px;
	}
	.footer {
		width: 176mm;
		bottom: 70px;
		margin-bottom: 20px;
	}
    .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 10px;
    }
`