import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import QuotesList from './QuotesList'
import QuotePreview from './QuotePreview'

export default function Quotes() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/quotes" component={QuotesList} />
            <Route exact path="/quotes/:id" component={QuotePreview} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}