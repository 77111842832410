import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import QuotePublicPreview from '../Quotes/QuotePublicPreview'

export default function Public() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/p/cotizaciones/:id" render={props => <QuotePublicPreview {...props} />} />

                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}