import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { Card, CardTitle, CardBody, Button, Table, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { message } from 'antd'

import SearchEntity from '../../../components/SearchEntity'
import { attachCompanyContact, detachCompanyContact } from '../../Companies/services'
import { handleError } from '../../../helpers'

export default function ContactCompany({ contact, reload }) {
   const [show, setShow] = useState(false)
   const [newCompanyId, setNewCompanyId] = useState(null)

   const handleAttach = () => {      
      if(newCompanyId){
         attachCompanyContact(newCompanyId, contact.id)
            .then(() => { 
               message.success('Empresa asociada exitosamente')
               setShow(false)
               reload()
            })
            .catch(error => handleError(error))         
      } else {
         message.warning('Debe elegir una empresa primero.')
      }
   }

   const handleDetach = (companyId) => {
      detachCompanyContact(companyId, contact.id)
         .then(() => {
            message.success('Empresa desasociada exitosamente')
            reload()
         })
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <Card>
            <CardBody className="pb-2">
               <CardTitle className="mb-2">Empresas Asociadas</CardTitle>
               { contact ? (
                  <React.Fragment>
                     { contact.companies.length > 0 ? (
                        <React.Fragment>
                           <Table className="table table-centered table-nowrap mb-0">
                              <tbody>
                                 { contact.companies.map(company => (
                                    <tr key={company.id}>
                                       <td style={{ width: "30px" }} className="px-0">
                                          <div className="avatar-xs">
                                             <span className="avatar-title rounded-circle bg-soft-info text-info font-size-16">
                                                <i className="fa fa-building"></i>
                                             </span>
                                          </div>
                                       </td>
                                       <td>
                                          <h5 className="font-size-14 m-0">
                                             <Link to={`/companies/${company.id}`}>
                                                <span className="text-dark">{company.name}</span>
                                             </Link>
                                          </h5>
                                       </td>
                                       <td className="text-right">
                                          <Button size="sm" color="warning" outline onClick={() => handleDetach(company.id)}>
                                             Quitar
                                          </Button>
                                       </td>
                                    </tr>
                                 ))}                   
                              </tbody>
                           </Table>
                           <div className="text-center mt-2 mb-2">
                              <Button color="primary" outline size="sm" onClick={() => setShow(true)}>Asociar Empresa</Button>
                           </div>
                        </React.Fragment>
                     ) : (
                        <div className="text-center">
                           <p className="mt-3"><em>Sin empresas asociadas</em></p>
                           <Button color="primary" outline size="sm" onClick={() => setShow(true)}>Asociar Empresa</Button>
                        </div>
                     )}
                  </React.Fragment>
               ) : <Spinner className="my-3" size="sm" />}            
            </CardBody>
         </Card>
         <Modal isOpen={show} toggle={()=>setShow(false)}>
            <ModalHeader toggle={()=>setShow(false)}>Asociar Empresa</ModalHeader>
            <ModalBody>
               <SearchEntity 
                  resourceType="companies"
                  setResourceId={setNewCompanyId}
                  style={{width: '100%'}}
               />
               <Button color="primary" className="mt-3" onClick={handleAttach}>Asociar Empresa</Button>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}