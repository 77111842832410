import React from 'react'
import {useDispatch} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Popconfirm, message } from 'antd'

import { deleteContact } from '../services'
import { handleError } from '../../../helpers'
import { showNewModal } from '../../Deals/actions'

const ProfileTopBar = ({ title, contactId, history }) => {
   const dispatch = useDispatch()

   const handleDelete = () => {
      deleteContact(contactId)
         .then(() => {
            message.success('Contacto eliminada exitosamente.')
            history.push('/contacts')
         })
         .catch(error => handleError(error))
   }   

   return (
      <React.Fragment>
         <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
               <span className="hide-sm">Perfil del Contacto</span>{" "}
               {title && ` - ${title}`}
            </h4>
            <div className="page-title-right">
               <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => dispatch(showNewModal(true))}
               >
                  Crear Negocio
               </Button>
               <Popconfirm
                  title="¿Esta seguro de eliminar?"
                  onConfirm={()=> handleDelete()}
                  okText="Eliminar"
                  cancelText="Cancelar"
                  placement="bottomRight"
               >
                  <Button color="danger" outline>
                     <i className="fa fa-trash mr-2"></i>
                     Eliminar
                  </Button>
               </Popconfirm>
            </div>
         </div>
      </React.Fragment>
   )
}

export default withRouter(ProfileTopBar)