import React, {useState} from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Table } from 'antd'

import NewStage from './partials/NewStage'
import EditStage from './partials/EditStage'
import TableActions from '../../../components/TableActions'

import { handleError } from '../../../helpers'
import { getStages, deleteStage } from "./services"

export default function StagesList({ pipelineSelected, stages, setStages }) {
   const [editStage, setEditStage] = useState(null)

   !stages && getStages({ 
      'filter[pipeline_id]': pipelineSelected,
      include: 'dealsCount'
   })
      .then(response => setStages(response.data.data))
      .catch(error => handleError(error))

   const handleDelete = id => {
      deleteStage(id)
         .then(() => setStages(null))
         .catch(error => handleError(error))
   }

   const columns = [
      {
         title: 'Orden',
         dataIndex: 'order',
         sorter: true,
         defaultSortOrder: 'ascend',
         width: '80px',
      },
      {
         title: 'Etapa',
         dataIndex: 'name',
      },
      {
         title: 'Acciones',
         width: '200px',
         render: (txt, record) => (
            <TableActions 
               handleEdit={()=>setEditStage(record)} 
               handleDelete={ record.deals_count === 0 ? ()=>handleDelete(record.id) : null}
            />
         )      
      }
   ]

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>Etapas del Embudo</CardTitle>
               <p className="text-muted mb-4">Administra las etapas de un embudo</p>
               { pipelineSelected ? (
                  <React.Fragment>
                     <Table 
                        columns={columns}
                        dataSource={stages}
                        loading={!stages && true}
                        rowKey={record => record.id}
                        size="small"
                     />
                     <NewStage reload={() => setStages(null)} pipelineSelected={pipelineSelected} />
                  </React.Fragment>
               ) : (
                  <em>Selecciona un embudo</em>
               )}            
            </CardBody>
         </Card>
         <EditStage stage={editStage} setShow={setEditStage} reload={()=>setStages(null)} />
      </React.Fragment>
   )
}