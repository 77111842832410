import React from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { Switch, Tabs, message } from 'antd'

import { attachPermission, detachPermission } from '../services'
import { handleError } from '../../../../helpers'

const { TabPane } = Tabs

export default function UserPermissions({ user, setShow }) {

   const permissions = [
      {
         tab: 'Empresas',
         data: [
            { label: 'Ver Empresas', key: 'companies.viewAny' },
            { label: 'Ver Perfil de Empresa', key: 'companies.view' },
            { label: 'Crear Empresas', key: 'companies.create' },
            { label: 'Actualizar Empresas', key: 'companies.update' },
            { label: 'Eliminar Empresas', key: 'companies.delete' },
         ]
      },
      {
         tab: 'Contactos',
         data: [
            { label: 'Ver Contactos', key: 'contacts.viewAny' },
            { label: 'Ver Perfil de Contacto', key: 'contacts.view' },
            { label: 'Crear Contactos', key: 'contacts.create' },
            { label: 'Actualizar Contactos', key: 'contacts.update' },
            { label: 'Eliminar Contactos', key: 'contacts.delete' },
         ]
      },
      {
         tab: 'Negocios',
         data: [
            { label: 'Ver Negocios', key: 'deals.viewAny' },
            { label: 'Ver Perfil de Negocio', key: 'deals.view' },
            { label: 'Crear Negocios', key: 'deals.create' },
            { label: 'Actualizar Negocios', key: 'deals.update' },
            { label: 'Eliminar Negocios', key: 'deals.delete' },
            { label: 'Ver Cotizaciones', key: 'quotes.viewAny' },
            { label: 'Ver Perfil de Cotización', key: 'quotes.view' },
            { label: 'Crear Cotizaciones', key: 'quotes.create' },
            { label: 'Actualizar Cotizaciones', key: 'quotes.update' },
            { label: 'Eliminar Cotizaciones', key: 'quotes.delete' },
         ]
      },
      {
         tab: 'Actividades',
         data: [
            { label: 'Ver Actividades', key: 'activities.viewAny' },
            { label: 'Crear Actividades', key: 'activities.create' },
            { label: 'Actualizar Actividades', key: 'activities.update' },
            { label: 'Eliminar Actividades', key: 'activities.delete' },
            { label: 'Ver Reservas', key: 'bookings.viewAny' },
            { label: 'Ver Perfil de Reserva', key: 'bookings.view' },
            { label: 'Crear Reservas', key: 'bookings.create' },
            { label: 'Actualizar Reservas', key: 'bookings.update' },
            { label: 'Eliminar Reservas', key: 'bookings.delete' },
         ]
      },
      {
         tab: 'Productos',
         data: [
            { label: 'Ver Productos', key: 'products.viewAny' },
            { label: 'Crear Productos', key: 'products.create' },
            { label: 'Actualizar Productos', key: 'products.update' },
            { label: 'Eliminar Productos', key: 'products.delete' },
         ]
      },
      {
         tab: 'Ajustes',
         data: [
            { label: 'Ver Usuarios', key: 'users.viewAny' },
            { label: 'Crear Usuarios', key: 'users.create' },
            { label: 'Actualizar Usuarios', key: 'users.update' },
            { label: 'Eliminar Usuarios', key: 'users.delete' },
            { label: 'Ver Tiendas', key: 'stores.viewAny' },
            { label: 'Crear Tiendas', key: 'stores.create' },
            { label: 'Actualizar Tiendas', key: 'stores.update' },
            { label: 'Eliminar Tiendas', key: 'stores.delete' },
            { label: 'Ver Campos Personalizados', key: 'custom_fields.viewAny' },
            { label: 'Crear Campos Personalizados', key: 'custom_fields.create' },
            { label: 'Actualizar Campos Personalizados', key: 'custom_fields.update' },
            { label: 'Eliminar Campos Personalizados', key: 'custom_fields.delete' },
            { label: 'Ver Categorías', key: 'categories.viewAny' },
            { label: 'Crear Categorías', key: 'categories.create' },
            { label: 'Actualizar Categorías', key: 'categories.update' },
            { label: 'Eliminar Categorías', key: 'categories.delete' },
            { label: 'Ver Embudos', key: 'pipelines.viewAny' },
            { label: 'Crear Embudos', key: 'pipelines.create' },
            { label: 'Actualizar Embudos', key: 'pipelines.update' },
            { label: 'Eliminar Embudos', key: 'pipelines.delete' },
         ]
      }
   ]

   const handleSwitch = (checked, permission) => {
      if(checked){
         attachPermission(user.id, { name: permission })
            .then(() => message.success('Permiso asignado exitosamente.'))
            .catch(error => handleError(error))
      } else {
         detachPermission(user.id, permission)
            .then(() => message.success('Permiso revocado exitosamente.'))
            .catch(error => handleError(error))
      }
   }

   const isChecked = permission => {
      let isSet = false
      if(!user) return false

      isSet = user.permissions.filter(current => permission === current.name)      

      return isSet.length > 0 ? true : false
   }

   return (
      <Modal isOpen={user && true} toggle={()=>setShow(null)} size="lg">
         <ModalHeader toggle={()=>setShow(null)}>Permisos de {user && user.name}</ModalHeader>
         <ModalBody>
            <Tabs type="card">
               { permissions.map((group, index) => (
                  <TabPane tab={group.tab} key={index}>
                     <Row className="pt-2">
                        { group.data.map((permission, key) => (
                           <Col key={key} md="6" lg="4" className="mb-3">
                              <Switch defaultChecked={isChecked(permission.key)} onChange={val => handleSwitch(val, permission.key)} />{" "}
                              {permission.label}
                           </Col>
                        )) }                     
                     </Row>
                  </TabPane>
               )) }               
            </Tabs>
         </ModalBody>
      </Modal>
   )
}